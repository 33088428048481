import { first, orderBy } from "lodash";
import withErrorHandling from "../HOC/withErrorHandler";

export const tryParse = (jsonString, defaultValue) => {
  let jsonStr = "";
  if (typeof jsonString !== "string") {
    jsonStr = JSON.stringify(jsonString);
  } else {
    jsonStr = jsonString;
  }

  try {
    return {
      data: JSON.parse(jsonStr),
      success: true,
    };
  } catch (err) {
    return {
      data: defaultValue || {},
      success: false,
    };
  }
};

export const tryParseInt = (numbericStr, defaultValue) => {
  if (typeof numbericStr === "number") return numbericStr;

  const data = parseInt(numbericStr);
  return {
    data: isNaN(data) ? defaultValue || 0 : data,
    succeed: !isNaN(data),
  };
};

export const formatDate = (d) => {
  if (!d) return "";
  const date = new Date(d);
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  }).format(date);

  return formattedDate;
};

export const getExpiredTime = () => {
  const now = new Date(); // get the current time
  return new Date(now.getTime() + 58 * 60 * 1000); // add 59 minutes to the current time
};

export const checkIsExpired = (time) => {
  if (!time) return true;
  const now = new Date(); // get the current time
  const expiredTime = new Date(time); // set the expiration time
  const countTime = Math.floor((expiredTime.getTime() - now.getTime()) / 60000);
  if (countTime <= 1) {
    console.log("The expiration time has not passed yet.");
    return true;
  } else {
    console.log("The expiration time has passed.");
    return false;
  }
};

export const flattenArray = (arr, childKey) => {
  return arr.flatMap((item) => {
    if (childKey && Array.isArray(item[childKey])) {
      return flattenArray(item[childKey]);
    }
    if (Array.isArray(item)) {
      return flattenArray(item);
    }
    return item;
  });
};

export const checkResponseIsFile = (response) => {
  const contentType = response.headers["content-type"];
  return (
    contentType &&
    (contentType.startsWith("application/") ||
      contentType.startsWith("image/") ||
      contentType.startsWith("audio/") ||
      contentType.startsWith("video/") ||
      contentType.startsWith("text/"))
  );
};

export const checkResponseIsFileThenDownload = withErrorHandling((response) => {
  const fileType = "text/csv;charset=utf-8";

  if (checkResponseIsFile(response)) {
    const blob = new Blob([response.data || ""], { type: fileType });
    const filename = ["csv", new Date().getTime()].join("-") + ".csv";
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);

    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }
});
export const generateUUID = () => {
  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp
  let d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};
export const randomColor = () => {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
};

export const shortName = (name) => {
  if (!name) return "NA";
  name = name.replace(/\s\s+/g, " ").trim();
  var arr = name.split(" ");
  var out;
  if (arr.length === 1) {
    out = arr[0].length <= 2 ? arr[0] : arr[0].substring(0, 2);
  } else {
    out = arr[0].substring(0, 1) + arr[1].substring(0, 1);
  }
  return out.toUpperCase();
};

export const sleep = (ms) =>
  new Promise((r) => {
    setTimeout(() => {
      r();
    }, ms);
  });
export const buildDateRange = (key) => {
  switch (key){
    case '1M': {
      const currentDate = new Date();
      const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      const result = {
          from: firstDayOfMonth.toISOString().split('T')[0],
          to: lastDayOfMonth.toISOString().split('T')[0]
      };

      return result;
    }
    case '3M': {
      const currentDate = new Date();
      const firstDayOfPreviousThreeMonths = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1);
      const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      const result = {
        from: firstDayOfPreviousThreeMonths.toISOString().split('T')[0],
        to: lastDayOfMonth.toISOString().split('T')[0]
      };

      return result;
    }
    case '6M': {
      const currentDate = new Date();
      const firstDayOfPreviousThreeMonths = new Date(currentDate.getFullYear(), currentDate.getMonth() - 5, 1);
      const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      const result = {
        from: firstDayOfPreviousThreeMonths.toISOString().split('T')[0],
        to: lastDayOfMonth.toISOString().split('T')[0]
      };

      return result;
    }
  }
}
export const getPermission = (roles) => {
  if(!roles) return {};
  const isPreniumOrBusinessUser = roles.some(x => x === "Premium" || x === "Business");
  const isAdminUser = roles.some(x => x === "Admin");
  const isAssistantUser = roles.some(x => x === "Assistant");
  console.log('isPreniumOrBusinessUser:', isPreniumOrBusinessUser);
  console.log('isAdmin:', isAdminUser);
  return {
    isAdmin: isPreniumOrBusinessUser,
    isPremiumOrBusiness: isAdminUser,
    isAssistant: isAssistantUser
  }
}

export const findCurrentSubscription = (plans, roles) =>{
  const plansSubscribed = plans.filter((p) => {
    return roles.some(r => r === p.groupName);
  });
  return first(orderBy(plansSubscribed, ['monthlyCost'], ['desc']));
}