import { Button, Form, Input, Modal, message } from "antd";
import React, { useState } from "react";
import { postChangePassword } from "../../services";
import { logout } from "../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { loginAWSCognito, newPassword } from "../../services/aws";
const ChangePassword = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { payload } = useSelector((state) => state.auth.login);

  const onFinish = async (values) => {
    const response = await loginAWSCognito({
      username: payload.username,
      password: values.currentPassword,
      newPassword: values.newPassword,
    });

    form.resetFields();
    message.success("Password changed successfully");
    dispatch(logout());
  };

  return (
    <Form
      form={form}
      name="change-password"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={payload}
    >
      <Form.Item
        label="Current Password"
        name="currentPassword"
        rules={[
          {
            required: true,
            message: "Please input your current password!",
          },
        ]}
      >
        <Input.Password readOnly />
      </Form.Item>

      <Form.Item
        label="New Password"
        name="newPassword"
        rules={[
          {
            required: true,
            message: "Please input your new password!",
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label="Confirm New Password"
        name="confirmNewPassword"
        dependencies={["newPassword"]}
        rules={[
          {
            required: true,
            message: "Please confirm your new password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("newPassword") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("New passwords do not match!"));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Change Password
        </Button>
      </Form.Item>
    </Form>
  );
};
export default ChangePassword;
