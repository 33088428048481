import React, { useState, useEffect } from "react";
import { Card, Col, Collapse, Form, Row, Space, Typography } from "antd";
import { clone, cloneDeep, isEmpty, uniqueId } from "lodash";

import { FORM_COMPONENTS, MIMETYPES_SUPPORT } from "../formBuilderConfig";
import "../index.css";
import withErrorHandling from "../../../../HOC/withErrorHandler";
import FormDynamicRender from "../Render/FormDynamicRender";
import { generateUUID } from "../../../../Utilities";
import { FormDynamicBuilderContext } from "../../../../context/FormDynamicContext";
import JsonPrettyViewer from "../../JsonPrettyViewer";
import SettingModal from "./Settings/SettingModal";

const { Panel } = Collapse;

const FormBuilder = ({ formAction, formConfig, onChange }) => {
  const [draggedItem, setDraggedItem] = useState(null);
  const [actionMetaData, setActionMetaData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [componentSetting, setComponentSetting] = useState({});
  const [isOpenSetting, setIsOpenSetting] = useState(false);

  useEffect(() => {
    if (isEmpty(formConfig)) return;
    setActionMetaData(JSON.parse(formConfig));
  }, [formConfig]);

  const handleDragStart = (event, component) => {
    setDraggedItem(component);
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData(
      MIMETYPES_SUPPORT.TEXT_PLAIN,
      JSON.stringify(component)
    );
  };
  const handleDragEnd = (event) => {
    setDraggedItem(null);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDragEnter = (event) => {
    event.preventDefault();
  };
  const handleDragLeave = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();

    const dataTransfer = JSON.parse(
      event.dataTransfer.getData(MIMETYPES_SUPPORT.TEXT_PLAIN)
    );
    const dropColumnIndex =
      event.currentTarget.getAttribute("drop-column-index");
    const dropRowIndex = event.currentTarget.getAttribute("drop-row-index");
    addComponent(dropRowIndex, dropColumnIndex, dataTransfer, true);
  };
  const addComponent = withErrorHandling(
    (row, column, component, isOpenSettingModal) => {
      if (!component || (isEmpty(row) && component.type !== "row")) return;

      setIsLoading(true);
      let actionMetaDataCloned = cloneDeep(actionMetaData);

      if (component.type == "row" && isEmpty(row)) {
        actionMetaDataCloned = actionMetaDataCloned || [];
        actionMetaDataCloned.push({
          type: "row",
          order: isEmpty(actionMetaDataCloned)
            ? 0
            : actionMetaDataCloned.length,
          columns: [
            {
              id: generateUUID(),
              type: "column",
              column: 12,
              order: 0,
              components: [],
            },
          ],
        });

        waitingForRenderUI(actionMetaDataCloned);
        return;
      }

      let componentAdd = Object.assign({}, component);
      componentAdd.id = generateUUID();
      componentAdd.order =
        actionMetaDataCloned[row].columns[column].components.length;

      // add new
      if (!component.id) {
        // insert component into column
        if (isEmpty(actionMetaDataCloned[row].columns[column].components)) {
          actionMetaDataCloned[row].columns[column].components.push(
            componentAdd
          );
        } else {
          // add new column then insert component into them
          actionMetaDataCloned[row].columns.push({
            id: generateUUID(),
            type: "column",
            column: 12,
            order: actionMetaDataCloned[row].columns.length,
            components: [componentAdd],
          });
        }
      }
      waitingForRenderUI(actionMetaDataCloned);
    }
  );
  const waitingForRenderUI = (actionMetaDataCloned) => {
    setActionMetaData(actionMetaDataCloned);
    onChange instanceof Function && onChange(actionMetaDataCloned)

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const handleDeleteComponent = withErrorHandling(
    (rowIndex, colIndex, component, index) => {
      setIsOpenSetting(false);
      let actionMetaDataCloned = clone(actionMetaData);
      if (component.type !== "row")
        actionMetaDataCloned[rowIndex].columns[colIndex].components.splice(
          index,
          1
        );
      else {
        actionMetaDataCloned.splice(rowIndex, 1);

        if (isEmpty(actionMetaDataCloned)) {
          actionMetaDataCloned.push({
            type: "row",
            order: isEmpty(actionMetaDataCloned)
              ? 0
              : actionMetaDataCloned.length,
            columns: [
              {
                id: generateUUID(),
                type: "column",
                column: 12,
                order: 0,
                components: [],
              },
            ],
          });
        }
      }
      waitingForRenderUI(actionMetaDataCloned);
    }
  );
  const handleOpenSetting = (rowIndex, colIndex, component, index) => {
    setComponentSetting({
      rowIndex,
      colIndex,
      component,
      index,
      actionMetaData,
    });
    setIsOpenSetting(true);
  };
  const handleCancel = () => {
    setIsOpenSetting(false);
  };
  const handleSaveSetting = (rowIndex, colIndex, component, index, values) => {
    setIsOpenSetting(false);
    setIsLoading(true);
    let actionMetaDataCloned = cloneDeep(actionMetaData);

    if (component.type === "row") {
      actionMetaDataCloned[rowIndex] = {...clone(actionMetaDataCloned[rowIndex]), ...values};
    } else {
      actionMetaDataCloned[rowIndex].columns[colIndex].components[index] = {
        ...clone(
          actionMetaDataCloned[rowIndex].columns[colIndex].components[index]
        ),
        ...values,
      };
      actionMetaDataCloned[rowIndex].columns[colIndex].components[
        index
      ].rowIndex = rowIndex;
      actionMetaDataCloned[rowIndex].columns[colIndex].components[
        index
      ].columnIndex = colIndex;
      actionMetaDataCloned[rowIndex].columns[colIndex].components[
        index
      ].cIndex = index;
    }
    waitingForRenderUI(actionMetaDataCloned);
  };

  return (
    <FormDynamicBuilderContext.Provider
      value={{
        editable: true,
        onOpenSetting: handleOpenSetting,
        onDeleteComponent: handleDeleteComponent,
        actionMetaData,
        setActionMetaData,
        componentSetting,
        setComponentSetting,
        open: isOpenSetting,
        onCancel: handleCancel,
        onSave: handleSaveSetting,
        onDelete: handleDeleteComponent,
      }}
    >
      <Row gutter={16}>
        <Col span={6}>
          <Collapse defaultActiveKey={["basic"]}>
            {FORM_COMPONENTS.map((component) => (
              <Panel header={component.name} key={component.type}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{
                    display: "flex",
                  }}
                >
                  {component.components.map((item) => (
                    <Card.Grid
                      style={{
                        width: "100%",
                        padding: 5,
                        cursor: "pointer",
                        backgroundColor: "#2780e3",
                        color: "#fff",
                        borderRadius: 5,
                      }}
                      key={item.type}
                      draggable
                      onDragStart={(event) => handleDragStart(event, item)}
                      onDragEnd={handleDragEnd}
                      className={draggedItem === item ? "dragging" : ""}
                    >
                      {item.name}
                    </Card.Grid>
                  ))}
                </Space>
              </Panel>
            ))}
          </Collapse>
        </Col>
        <Col span={4}></Col>
        <Col span={14}>
          <Card
            bordered={false}
            className="dropable"
            title={
              <Typography.Title
                level={2}
                style={{
                  textAlign: "center",
                }}
              >
                Design and preview
              </Typography.Title>
            }
          >
            <Form layout="vertical">
              <FormDynamicRender
                actionData={actionMetaData}
                isLoading={isLoading}
                editable
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              />
              {/* <Typography.Text>
                Form config:
                <JsonPrettyViewer
                  json={actionMetaData}
                  loading={isLoading}
                  name={false}
                />
              </Typography.Text> */}
            </Form>
          </Card>
        </Col>
      </Row>
      <SettingModal
        open={isOpenSetting}
        onCancel={handleCancel}
        onSave={handleSaveSetting}
        onDelete={handleDeleteComponent}
      />
    </FormDynamicBuilderContext.Provider>
  );
};
export default FormBuilder;
