import React, { useEffect } from "react";
import { Layout, Space, Spin, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./styles.css";
import Menu from "../HomePage/Menu";
import Iframe from "./Iframe";
import SwitchMode from "./SwitchMode";
import Properties from "./Properties";
import getExtractionHtml from "../slices/getHtml";
import InputUrl from "./InputUrl";
import { validate } from "../utils";
import { setPreview } from "../slices";

const { Header, Content, Footer } = Layout;

const Extraction = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const states = useSelector((states) => states.extraction);

  useEffect(() => {
    if (
      (states.initial.url && states.initial.type === "url") ||
      (states.initial.file && states.initial.type === "file")
    ) {
      dispatch(getExtractionHtml());
    } else {
      navigate("/");
    }
  }, [states.initial]);

  const handleDone = () => {
    const validPages = validate(states.pages);
    if (!validPages.length) {
      notification.error({
        message: "Error",
        description: `Please add add property and make sure all property have the name and values.`,
        duration: 3,
      });
      return;
    }
    dispatch(
      setPreview({
        pages: validPages,
        type: "data",
      })
    );
    navigate("/extraction/preview");
  };

  return (
    <Layout id="extraction">
      {states.status === "loading" ? (
        <div className="loading">
          <Spin size={"large"} />
        </div>
      ) : null}
      <Header>
        <Space className="url">
          <InputUrl url={states.initial.url} />
        </Space>
        <Properties />
        <Space className="done" onClick={handleDone}>
          Done
        </Space>
        <Space className="right">
          <Space className="slider">
            <SwitchMode />
          </Space>
          <Space className="menu">
            <Menu />
          </Space>
        </Space>
      </Header>
      <Content>
        <Iframe />
      </Content>
      <Footer>© COPYRIGHT 2015</Footer>
    </Layout>
  );
};

export default Extraction;
