import React, {
  useEffect,
  useState,
} from "react";
import { Form, Input, Button, Col, Row, Select } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { tryParse } from "../../../../../Utilities";
import SettingButton from "../../Builder/SettingButton";
import { isEmpty } from "lodash";

const RenameMultipleColumns = (props) => {
  const { component, onChange, rowIndex, colIndex, index } = props;
  const { getDatasetSourceFrom } = component;
  const [form] = Form.useForm();
  const [formItems, setFormItems] = useState([]);

  useEffect(() => {
    setFormItems(component[component.apiProperty]);
  }, [component]);

  const handleSelectDatasource = (value) => {
    if (value == "combine") {
      const sources = getDatasetSourceFrom.reduce((prev, curr) => {
        let values = curr.data.selectedOutputColumns;
        return prev.concat(values);
      }, []);

      buildRenameColumns(sources);
      return;
    }

    const selectedSource = getDatasetSourceFrom.find(
      (x) => x.idRender == value
    );

    if (selectedSource) {
      const sources = selectedSource.data?.selectedOutputColumns || [];

      buildRenameColumns(sources);
      return;
    }
  };

  const buildRenameColumns = (columns) => {
    const apiComponentData = tryParse(
      component[component.apiProperty],
      []
    ).data;
    const renameObj = columns.map((x) => {
      const mapped = apiComponentData?.find((m) => m.source == x);
      return {
        source: x,
        target: mapped ? mapped.target : x,
        isNew: !mapped,
      };
    });

    setFormItems(renameObj);
    handleOnChange(null, {
      type: component.type,
      columns: renameObj.map((x) => x.target),
    });
  };

  const handleOnChange = (e, option) => {
    if (typeof onChange === "function") {
      onChange(component.id, option);
    }
  };

  return (
    <>
      <Row>
        <Col xs={24} md={12}>
          Before Rename
        </Col>
        <Col xs={24} md={12}>
          After Rename
        </Col>

        {getDatasetSourceFrom && (
          <Col xs={24} md={24}>
            <Form.Item
              name={`__${component.apiProperty}_dataSourceSelected`}
              initialValue={
                component[`__${component.apiProperty}_dataSourceSelected`] ||
                component.dataSourceSelected
              }
            >
              <Select
                placeholder="Select Column Datasource"
                onChange={handleSelectDatasource}
              >
                <Select.Option value="combine">
                  Combine All Previous Pipe
                </Select.Option>
                {getDatasetSourceFrom && (getDatasetSourceFrom || []).map((source) => (
                  <Select.Option value={source.idRender} key={source.idRender}>
                    {source.data.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>

      {formItems.map((item, index) => (
        <Row key={item.source}>
          <Col xs={24} md={10}>
            <Form.Item
              name={[component.apiProperty, index, "source"]}
              initialValue={item.source}
              style={{ marginBlock: 0 }}
            >
              <p>{item.source}</p>
            </Form.Item>
          </Col>
          <Col xs={12} md={2}>
            <p>
              <ArrowRightOutlined />
            </p>
          </Col>
          <Col xs={24} md={10}>
            <Form.Item
              name={[component.apiProperty, index, "target"]}
              initialValue={item.target}
              rules={[{ required: true, message: "Target value is required" }]}
              style={{ marginBlock: "auto" }}
              onChange={handleOnChange}
            >
              <Input style={{ marginTop: 10 }} />
            </Form.Item>
          </Col>
        </Row>
      ))}
      <SettingButton
        direction="horizontal"
        position="topRight"
        rowIndex={rowIndex}
        colIndex={colIndex}
        component={component}
        index={index}
      />
    </>
  );
};

export default RenameMultipleColumns;
