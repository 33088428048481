import React, { memo, useEffect, useState } from "react";
import withErrorHandling from "../../../HOC/withErrorHandler";
import { Button, Drawer, Modal, Space, Table, Tooltip, Typography } from "antd";
import { ClockCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import Cron, { HEADER } from "react-cron-generator";
import CronText from "../CronText";
import {
  activePipelineSchedule,
  deletePipelineSchedule,
  getPipelineSchedules,
  inactivePipelineSchedule,
  savePipelineSchedule,
} from "../../../services";
import './SchedulerMagt.css';

const cronOptions = {
  headers: [HEADER.HOURLY, HEADER.DAILY, HEADER.WEEKLY, HEADER.MONTHLY],
};

const SchedulerMagt = ({ onChange, classn, visible, objectId }) => {
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Schedule",
      dataIndex: "crons",
      key: "crons",
      width: "50%",
      render: (_, record) => {
        return (
          <Typography.Text>
            <CronText cronString={_} />
          </Typography.Text>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "60%",
      render: (_, record) => {
        if (_ === 0)
          return <Typography.Text type="danger">Inactive</Typography.Text>;
        if (_ === 1)
          return <Typography.Text type="success">Active</Typography.Text>;
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "right",
      width: "30%",
      render: (_, record) => {
        if (record.status === 0) {
          return (
            <Space>
              <Button
                size="small"
                type="primary"
                onClick={() => handleActive(_)}
              >
                Active
              </Button>
              <Button
                size="small"
                type="default"
                danger
                onClick={() => handleDelete(_)}
              >
                Delete
              </Button>
            </Space>
          );
        } else {
          return (
            <Button
              size="small"
              type="default"
              danger
              onClick={() => handleInActive(_)}
            >
              Deactive
            </Button>
          );
        }
      },
    },
  ];

  const [open, setOpen] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [cronsValue, setCronValue] = useState("0 0 00 1/1 * ? *");
  const [datas, setDatas] = useState([]);

  const showModal = withErrorHandling(async (e) => {
    setOpen(true);
    getlist();
  });
  const getlist = async () => {
    const { data } = await getPipelineSchedules({ pipeline_id: objectId });
    setDatas(data);
  }
  const handleClose = () => {
    setCronValue("0 0 00 1/1 * ? *");
    setShowDetail(false);
    setOpen(false);
  };

  const handleSavePipeineSchedule = async () => {
    const payload = {
      startTime: null,
      endTime: null,
      crons: cronsValue,
      timeZone: null,
      type: null,
      pipelineId: objectId,
    };
    setCronValue("0 0 00 1/1 * ? *");
    setShowDetail(false);

    const response = await savePipelineSchedule(payload);
    getlist();
  };
  const handleActive = async (id) => {
    const response = await activePipelineSchedule({ schedule_id: id });
    getlist();
  };
  const handleInActive = async (id) => {
    const response = await inactivePipelineSchedule({ schedule_id: id });
    getlist();
  };
  const handleDelete = async (id) => {
    const response = await deletePipelineSchedule({ schedule_id: id });
    getlist();
  };
  if (!visible) {
    return <></>;
  }

  return (
    <div style={{ height: "100%" }}>
      <Button
        type="primary"
        icon={<ClockCircleOutlined />}
        onClick={showModal}
        className={classn}
      >
        Schedulers
      </Button>
      <Modal
        width={650}
        title="Pipeline Schedule"
        open={open}
        onCancel={handleClose}
        cancelText="Close"
        footer={[
          <Button key="back" onClick={handleClose} danger>
            Close
          </Button>,
        ]}
      >
        {showDetail && (
          <>
            <Cron
              options={cronOptions}
              value={cronsValue}
              onChange={(e) => {
                setCronValue(e);
              }}
              showResultText={true}
              showResultCron={false}
            />

            <div
              style={{
                width: "100%",
                marginTop: 10,
                marginBottom: 10,
                display: "inline-flex",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <Button type="primary" onClick={handleSavePipeineSchedule}>
                Save
              </Button>
              <Button danger onClick={() => setShowDetail(false)}>
                Cancel
              </Button>
            </div>
          </>
        )}

        <Table
          columns={columns}
          dataSource={datas}
          size="small"
          title={() => {
            if (!showDetail) {
              return (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setCronValue("0 0 00 1/1 * ? *");
                    setShowDetail(true);
                  }}
                >
                  Add Schedule
                </Button>
              );
            }
          }}
        />
      </Modal>
    </div>
  );
};
export default SchedulerMagt;
