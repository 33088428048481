import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Tooltip,
  notification,
} from "antd";
import { CopyFilled, EyeOutlined } from "@ant-design/icons";
import pick from "lodash/pick";

import JsonPrettyViewer from "../../../components/JsonPrettyViewer";
import withErrorHandling from "../../../../HOC/withErrorHandler";
import { getCrawlerList, previewData } from "../../../../store/crawlerSlice";
import { validateExtraction } from "../../../../services/validations/extractionFactory";
import { cloneCrawler } from "../../../../services";
import { isEmpty } from "lodash";

const CloneDrawler = ({ crawler }) => {
  const dispatch = useDispatch();

  const { payload } = useSelector((states) => states.crawler?.crawlersObj);

  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [isGetPreviewJson, setIsGetPreviewJson] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [dataPreview, setDataPreview] = useState({});

  const handleOpenCloneModal = () => {
    setInitialValues({
      name: crawler?.name,
      url: crawler?.page?.url,
    });
    setOpen(true);
  };

  const handleCancel = () => setOpen(false);

  const handleClone = () => {
    const formValues = form.getFieldsValue();
    if (formValues.name === crawler.name) {
      formValues.name = `${crawler.name} (copy)`;
    }
    const page = validateExtraction(crawler.page);
    let schedule = !isEmpty(crawler.schedules) ? crawler.schedules[0] : null;
    let clonePayload = {
      name: formValues.name,
      page: page,
    };

    if (formValues.cloneSchedule && !isEmpty(schedule)) {
      clonePayload.schedules = [{
        crons: schedule?.crons,
        status: schedule?.status
      }];
    }

    cloneCrawler(clonePayload).then((res) => {
      notification.success({
        message: `Clone crawler ${crawler.name} to ${formValues.name} successfully`,
      });
      dispatch(getCrawlerList(payload));
      handleCancel();
    });
  };

  const handlePreview = withErrorHandling(() => {
    setIsGetPreviewJson(true);
    const params = {
      page: {
        ...crawler.page,
        url: form.getFieldValue("url"),
      },
    };
    dispatch(previewData(params)).then(({ payload }) => {
      setDataPreview(payload);
      setIsGetPreviewJson(false);
    });
  });

  return (
    <>
      <Tooltip title="Clone">
        <Button
          type="primary"
          icon={<CopyFilled />}
          onClick={handleOpenCloneModal}
        ></Button>
      </Tooltip>
      <Modal
        width={900}
        title={`Clone crawler ${crawler?.name}`}
        open={open}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelText="Close"
        footer={[
          <Button type="default" key="back" onClick={handleCancel} danger>
            Cancel
          </Button>,
          <Button key="clone" onClick={() => form.submit()} type="primary">
            Clone
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={initialValues}
          onFinish={handleClone}
        >
          <Row gutter={[8, 8]}>
            <Col span={10}>
              <Form.Item
                key="trackingName"
                label="Tracking Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Required field",
                  },
                ]}
              >
                <Input placeholder="Enter tracking name" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                key="trackingUrl"
                label="Tracking URL"
                name="url"
                rules={[
                  {
                    required: true,
                    message: "Required field",
                  },
                ]}
              >
                <Input placeholder="Enter tracking url" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label=" ">
                <Button
                  type="primary"
                  icon={<EyeOutlined />}
                  onClick={handlePreview}
                  loading={isGetPreviewJson}
                >
                  Preview
                </Button>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item key="data" label="Data" name="Properties">
                <JsonPrettyViewer
                  json={dataPreview}
                  loading={isGetPreviewJson}
                  name={false}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Space direction="vertical">
                <Form.Item
                  key="cloneAlert"
                  label="Option"
                  name="cloneAlert"
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox>Clone Alert</Checkbox>
                </Form.Item>
                <Form.Item
                  key="cloneSchedule"
                  name="cloneSchedule"
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox>Clone Schedule</Checkbox>
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CloneDrawler;
