import React, { useEffect, useState } from "react";
import { Form, Input, Button, Alert, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import { login, changeTab, setPayloadLogin } from "../../store/authSlice";

const Login = () => {
  const dispatch = useDispatch();
  const authState = useSelector((states) => states.auth);

  const { error, status } = authState.login;
  const loading = status == "pending";

  const [payload, setPayload] = useState({});

  useEffect(() => {
    console.log("login-status:", status, error?.message);
    if (status == "error" && error?.message) {
      if (error?.message?.includes("NEW_PASSWORD_REQUIRED")) {
        dispatch(changeTab("newpassword"));
        dispatch(setPayloadLogin({
          ...payload,
          currentPassword: payload.password
        }));
      } else {
        notification.error({
          message: "Error", //NEW_PASSWORD_REQUIRED
          description: error.message,
          duration: 2.5,
        });
      }
    }
  }, [status]);

  const handleLogin = (values) => {
    setPayload(values);
    dispatch(login(values));
  };

  const forgotPassword = (e) => {
    e.preventDefault();
    dispatch(changeTab("forgot"));
  };

  return (
    <Form
      name="loginForm"
      initialValues={{
        remember: true,
      }}
      onFinish={handleLogin}
    >
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: "Please input your username.",
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your password.",
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item className="Login-footer">
        <a className="login-form-forgot" href="" onClick={forgotPassword}>
          Forgot password
        </a>

        <Button type="primary" htmlType="submit" loading={loading}>
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;
