import { Form } from "antd";
import React from "react";
import { TableEditableContext } from "../../../../context";


const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <TableEditableContext.Provider value={{
            form
        }}>
          <tr {...props} />
        </TableEditableContext.Provider>
      </Form>
    );
  };

  export default EditableRow;