import React from "react";
import { Checkbox, Form, Input, Radio, Space } from "antd";
import SettingButton from "../../Builder/SettingButton";

const RadioElement = (props) => {
  const { component, rowIndex, colIndex, index } = props;

  return (
    <>
      <Form.Item
        key={component.id}
        label={component.isHideLabel ? "" : component.label}
        tooltip={component.tooltip}
        name={component.apiProperty}
        initialValue={component[component.apiProperty]}
        rules={[
          {
            required: component.isRequired,
            message: component.requiredMessage || "Required field",
          },
        ]}
      >
        <Radio.Group>
          <Space direction={component.displayDirection || "vertical"}>
            {component.values?.map((option) => (
              <Radio value={option.value}>{option.label}</Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
      <SettingButton
        direction="horizontal"
        position="topRight"
        rowIndex={rowIndex}
        colIndex={colIndex}
        component={component}
        index={index}
      />
    </>
  );
};
export default RadioElement;
