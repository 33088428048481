import React from "react";
import { Modal, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { setLoginType, changeTab } from "../../store/authSlice";

import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";

import "./index.css";
import ChangePassword from "./ChangePassword";

const LoginModal = () => {
  const dispatch = useDispatch();
  const authState = useSelector((states) => states.auth);
  const { loginType, login, register, forgotPassword, tab } = authState;
  const loading = login.loading || register.loading || forgotPassword.loading;

  const onCancel = () => {
    if (loading) {
      return false;
    }
    dispatch(setLoginType(0));
  };

  const onTabChange = (key) => {
    dispatch(changeTab(key));
  };

  return (
    <Modal
      open={loginType === 1}
      onCancel={onCancel}
      footer={null}
      confirmLoading={loading}
      className="Auth-modal"
      keyboard={false}
    >
      <Tabs
        defaultActiveKey="login"
        activeKey={tab}
        onChange={onTabChange}
        size={"large"}
        items={[
          {
            label: "Login",
            key: "login",
            children: <Login />,
          },
          {
            label: "Register",
            key: "register",
            children: <Register />,
          },
          {
            label: "Forgot",
            key: "forgot",
            children: <ForgotPassword />,
            disabled: true,
          },
          {
            label: "Change Password",
            key: "newpassword",
            children: <ChangePassword />,
            disabled: true,
          },
        ]}
      ></Tabs>
    </Modal>
  );
};

export default LoginModal;
