import React, { useState, useEffect } from "react";
import { Checkbox, Form, Input, InputNumber, Tabs } from "antd";

const ValidationSetting = ({ component, children }) => {
  return (
    <>
      <Form.Item name="isRequired" valuePropName="checked" style={{ marginBottom: 5 }}>
        <Checkbox>Required</Checkbox>
      </Form.Item>
      <Form.Item label="Required Error Mesage" name="requiredMessage" style={{ marginBottom: 5 }}>
        <Input />
      </Form.Item>
      {children}
    </>
  );
};
export default ValidationSetting;
