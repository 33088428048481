import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { API_URL, createShare, getListPlan } from "../services";
import { orderBy } from "lodash";

export const share = createAsyncThunk(API_URL.SHARING, async (params) => {
  const response = await createShare(params);
  return response.data;
});
export const getSubscriptionPlans = createAsyncThunk(API_URL.GET_LIST_PLAN, async (params) => {
  const response = await getListPlan(params);
  return response.data;
});

const initialState = {
  isReLoad: false,
  plans: [],
  currentPlan: {}
};

export const sharedSlice = createSlice({
  name: "sharedSlice",
  initialState,
  reducers: {

  },
  extraReducers: {
    [getSubscriptionPlans.fulfilled]: (state, {payload}) => {
      state.plans = payload;
    }
  },
});

// export const { notLogin, autoLogin, pushReturnAction } = sharedSlice.actions;

export default sharedSlice.reducer;
