import { Alert, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Result, Button } from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom"; // Đảm bảo bạn đã cài đặt react-router-dom

import { getStripeCheckoutSessionStatus } from "../../services";
import Paragraph from "antd/es/skeleton/Paragraph";

const PaymentSuccess = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  console.log("PaymentSuccess", queryParams);

  const [loading, setLoading] = useState(false);
  const [checkoutStatus, setCheckoutStatus] = useState({});

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        setLoading(true);
        const response = await getStripeCheckoutSessionStatus(
          queryParams.get("session_id")
        );
        console.log(response);
        setCheckoutStatus({
          status: response.data,
        });
      } catch (error) {
        console.error("Error fetching invoice:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, []);

  if (loading) {
    return (
      <Spin
        tip="Checking transaction status ..."
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      ></Spin>
    );
  }

  if (checkoutStatus.status !== "complete") {
    return (
      <Result
        status="error"
        title="Submission Failed"
        subTitle="Please check and modify the following information before resubmitting."
        extra={[
          <Button type="primary" key="console">
            Go Billing
          </Button>,
          <Button key="buy">Topup Again</Button>,
        ]}
      >
        {/* <div className="desc">
          <Paragraph>
            <Typography.Text
              strong
              style={{
                fontSize: 16,
              }}
            >
              The content you submitted has the following error:
            </Typography.Text>
          </Paragraph>
          <Paragraph>
            <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
            account has been frozen. <a>Thaw immediately &gt;</a>
          </Paragraph>
          <Paragraph>
            <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
            account is not yet eligible to apply. <a>Apply Unlock &gt;</a>
          </Paragraph>
        </div> */}
      </Result>
    );
  } else if (checkoutStatus.status === "complete") {
    return (
      <Result
        icon={<CheckCircleOutlined />}
        title="Payment successful!"
        subTitle="Thank you for using our service."
        extra={[
          <Button type="primary" key="billing">
            <Link to="/setting">Back to Billing</Link>
          </Button>,
          // Các nút hoặc linh tinh khác có thể được thêm vào đây
        ]}
      />
    );
  }
};
export default PaymentSuccess;
