
import { createAsyncThunk } from "@reduxjs/toolkit";
import { exportCsv } from "../services";

export default createAsyncThunk("extranction/exportCsv", async (params) => {
  try {
    const r = await exportCsv(params);
    return r;
  } catch (e) {
    throw e;
  }
});
