import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Checkbox,
  Col,
  Input,
  Pagination,
  Row,
  Space,
  Table,
  notification,
} from "antd";
import {
  ReloadOutlined,
  PlusOutlined,
  RollbackOutlined,
} from "@ant-design/icons";

import TableHeader from "./TableHeader";
import {
  getPipelines,
  resetPipelineRender,
  run,
  setPayloadGetPipelineList,
} from "../../store/pipelineSlice";

import "./custom-ant-table.css";
import withErrorHandling from "../../HOC/withErrorHandler";

const PipelineList = (props) => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dataSource, setDataSource] = useState([]);
  const [isGetShared, setIsGetShared] = useState(false);
  const [keyword, setKeyword] = useState("");

  const { list: pipelines, total } = useSelector(
    (states) => states.pipeline?.pipelinesObj
  );

  useEffect(() => {
    dispatch(resetPipelineRender());
    handleGetPipelines();
  }, [dispatch, pageSize, currentPage, isGetShared, keyword]);

  useEffect(() => {
    setDataSource(pipelines);
  }, [pipelines]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleGetPipelines = useCallback(() => {
    setIsLoading(true);
    const payload = {
      isOwn: !isGetShared,
      page: currentPage - 1,
      page_size: pageSize,
      keyword: keyword,
    };
    dispatch(setPayloadGetPipelineList(payload));
    dispatch(getPipelines(payload)).then(() => {
      setIsLoading(false);
    });
  }, [currentPage, pageSize, isGetShared, keyword]);

  const handleGetShared = useCallback((e) => {
    setIsGetShared(e.target.checked);
  });

  const handleOnSearch = useCallback((e) => {
    setKeyword(e);
  });

  return (
    <>
      <Table
        className="custom-ant-table"
        bordered
        loading={isLoading}
        size="middle"
        dataSource={dataSource}
        columns={TableHeader({ handleGetPipelines })}
        pagination={false} // hide default pagination
        title={() => (
          <Row gutter={12}>
            <Col xs={24} md={18}>
              <Space
                size={"small"}
                align="center"
                justify="start"
                style={{ width: "100%" }}
              >
                <Input.Search placeholder="Keyword" onSearch={handleOnSearch} />
                <Checkbox onChange={handleGetShared}>Shared with me</Checkbox>
              </Space>
            </Col>
            <Col xs={24} md={6}>
              <Space
                size={"small"}
                align="center"
                justify="end"
                style={{ width: "100%" }}
              >
                <Button
                  type="text"
                  icon={<ReloadOutlined />}
                  onClick={handleGetPipelines}
                >
                  Reload
                </Button>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => navigator("/pipelines/new")}
                >
                  Create
                </Button>
              </Space>
            </Col>
          </Row>
        )}
        footer={() => (
          <Pagination
            total={total || 0} // total number of items
            current={currentPage || 1} // current page number
            pageSize={pageSize || 10} // number of items per page
            showSizeChanger // allow user to change page size
            showQuickJumper
            showTotal={(total) => `Total ${total} items`}
            onChange={handlePageChange} // handle page changes
          />
        )}
      />
    </>
  );
};
export default PipelineList;
