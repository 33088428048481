import { createAsyncThunk } from "@reduxjs/toolkit";

import { exportJson } from "../services";

export default createAsyncThunk("extranction/exportJson", async (params) => {
  try {
    const r = await exportJson(params);
    return r;
  } catch (e) {
    throw e;
  }
});
