import React, { useEffect } from "react";
import { Tabs } from "antd";
import BillingPage from "./BillingPage";
import AccountPage from "./AccountPage";
import TransactionsPage from "./TransactionsPage";
import CostManagementPage from "./CostManagementPage";
import { useSelector } from "react-redux";
import { getPermission } from "../../Utilities";

const SettingDashboard = () => {
  const { user } = useSelector((state) => state.auth?.user);
  let items = [
    {
      key: "account",
      label: "Account",
      children: <AccountPage />,
    },
  ];

  const permission = getPermission(user?.groups || []);

  if (
    permission.isAdmin ||
    (permission.isPremiumOrBusiness && !permission.isAssistant)
  ) {
    items = items.concat([
      {
        key: "billing",
        label: "Billing",
        children: <BillingPage />,
      },
      {
        key: "transactions",
        label: "Transactions",
        children: <TransactionsPage />,
      },
      {
        key: "costmanagement",
        label: "Cost Management",
        children: <CostManagementPage />,
      },
    ]);
  }

  return <Tabs defaultActiveKey="billing" items={items} />;
};
export default SettingDashboard;
