import React from "react";
import { Select, Form } from "antd";
import SettingButton from "../../Builder/SettingButton";
const TagsInputElement = (props) => {
  const { component, rowIndex, colIndex, index } = props;

  const handleOnChange = (e) => {
    console.log(e);
  };
  return (
    <>
      <Form.Item
        label={component.isHideLabel ? "" : component.label}
        name={component.apiProperty}
        tooltip={component.tooltip}
        rules={[
          {
            required: component.isRequired,
            message: component.requiredMessage || "Required field",
          },
        ]}
      >
        <Select
          placeholder={component.placeholder || "Select..."}
          onChange={handleOnChange}
          showSearch
          mode="tags"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={component.values}
        ></Select>
      </Form.Item>
      <SettingButton
        direction="horizontal"
        position="topRight"
        rowIndex={rowIndex}
        colIndex={colIndex}
        component={component}
        index={index}
      />
    </>
  );
};
export default TagsInputElement;
