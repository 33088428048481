import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Table,
  message,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ReloadOutlined,
  PlusOutlined,
  RollbackOutlined,
  DownOutlined,
  EditFilled,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  deleteThresholdUsaged,
  getListThreshold,
  getListThresholdUsage,
  saveThresholdUsaged,
} from "../../services";
const CostManagementPage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { user } = useSelector(state => state.auth);

  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dataSource, setDataSource] = useState([]);
  const [thresholdLst, setThresholdLst] = useState([]);

  useEffect(() => {
    _fnListThresholdUsage();
    getListThreshold().then((res) => {
      console.log("getListThreshold", res);
      const optionsThreshold = res?.data?.map((x) => ({
        label: x.name,
        value: x.id,
      }));
      setThresholdLst(optionsThreshold || []);
      setIsLoading(false);
    });
  }, [dispatch]);

  const _fnListThresholdUsage = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await getListThresholdUsage({
        page: currentPage - 1,
        page_size: pageSize,
      });

      setDataSource(resp?.data?.lstThresholdUsage || []);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error("ListThresholdUsage:", err);
    }
  }, [currentPage, pageSize]);

  const handleSubmit = async (values) => {
    console.log("handleSubmit", values);
    const payload = values.thresholds?.map(item => {
      if(!item.id){
        item.userId = user?.user.id
      }
      return item;
    });
    console.log('payload', payload)

    try{
      await saveThresholdUsaged(payload);
      message.success("Thresholds saved successfully");
    } catch (error) {
      message.error("Failed to save thresholds");
      return; // Exit if delete API call fails
    }
  };

  const handleRemove = async (remove, name, key) => {
    const item = form.getFieldValue(["thresholds", name]);
    console.log("handleRemove", item);
    if (item.id > 0) {
      try {
        await deleteThresholdUsaged({ thresHoldUsageId: item.id });
        message.success("Item deleted successfully");
      } catch (error) {
        message.error("Failed to delete item");
        return; // Exit if delete API call fails
      }
    }

    remove(name);
  };
  const validateDuplicateThreshold = (_, value) => {
    const allValues = form.getFieldValue("thresholds");
    const duplicates = allValues.filter((item) => item.thresholdId === value);
    if (duplicates.length > 1) {
      return Promise.reject(new Error("Duplicate thresholds"));
    }
    return Promise.resolve();
  };

  if (isLoading) return <Spin>Loading...</Spin>;

  return (
    <Form
      form={form}
      initialValues={{
        thresholds: dataSource,
      }}
      onFinish={handleSubmit}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item>
            <Form.List name="thresholds">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Space key={field.key} align="baseline">
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.area !== curValues.area ||
                          prevValues.sights !== curValues.sights
                        }
                      >
                        {() => (
                          <Form.Item
                            {...field}
                            label="Threshold"
                            name={[field.name, "thresholdId"]}
                            rules={[
                              {
                                required: true,
                                message: "Threshold key is required!",
                              },
                              { validator: validateDuplicateThreshold },
                            ]}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              options={thresholdLst}
                              style={{
                                width: 300,
                              }}
                            ></Select>
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label="Threshold value"
                        name={[field.name, "thresholdValue"]}
                        rules={[
                          {
                            required: true,
                            message: "Threshold value is required",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <MinusCircleOutlined
                        onClick={() =>
                          handleRemove(remove, field.name, field.key)
                        }
                      />
                    </Space>
                  ))}

                  <Form.Item>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          // style={{ width: "calc(100% - 8em)" }}
                        >
                          Add Threshold
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button type="primary"  htmlType="submit">
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default CostManagementPage;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    align: "left",
    width: "30%",
  },
  {
    title: "Data Type",
    dataIndex: "dataType",
    key: "dataType",
    align: "center",
    width: "30%",
  },
  {
    title: "Descrition",
    dataIndex: "desc",
    key: "desc",
    align: "left",
    width: "30%",
  },
];
