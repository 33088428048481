import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_URL,
  activeSchedule,
  clonePipeline,
  deletePipeline,
  deleteSchedule,
  getAllCrawlers,
  getAllDataset,
  getPipelineDetail,
  getPipelineList,
  inactiveSchedule,
  runPipeline,
  terminatePipelineRun,
} from "../services";
import { createPipeline } from "../services";

export const allCrawlers = createAsyncThunk(
  API_URL.ALL_CRAWLER,
  async (params) => {
    var res = await getAllCrawlers();
    let crawlerList = res.data?.map(function (x) {
      return {
        value: x.id,
        label: x.name,
        // columns: x.page.properties.map((prop) => prop.name),
        columns: x.columns,
        item: x,
      };
    });

    return crawlerList;
  }
);
export const allDataset = createAsyncThunk(
  API_URL.ALL_DATASET,
  async (params) => {
    var res = await getAllDataset();
    let datasetList = res.data.map(function (x) {
      return {
        value: x.id,
        label: x.name,
        columns: (x.columns || "").split(","),
        item: x,
      };
    });

    return datasetList;
  }
);
export const getPipelineDetailById = createAsyncThunk(
  API_URL.PIPELINE_DETAIL,
  async (params) => {
    const response = await getPipelineDetail(params);
    return response.data;
  }
);
export const getPipelines = createAsyncThunk(
  API_URL.PIPELINE_LIST,
  async (params) => {
    const response = await getPipelineList(params);
    return response.data;
  }
);
export const run = createAsyncThunk(
  API_URL.PIPELINE_RUN_PIPELINE,
  async (params) => {
    const response = await runPipeline(params);
    return response;
  }
);

export const clone = createAsyncThunk(
  API_URL.PIPELINE_CLONE_PIPELINE,
  async (params) => {
    try {
      const response = await clonePipeline(params);
      return response;
    } catch (e) {
      throw e;
    }
  }
);

export const terminate = createAsyncThunk(
  API_URL.PIPELINE_TERMINATE_PIPELINE,
  async (params) => {
    const response = await terminatePipelineRun(params);
    return response.data;
  }
);

export const activeScheduler = createAsyncThunk(
  API_URL.PIPELINE_ACTIVE_SCHEDULE,
  async (params) => {
    const response = await activeSchedule(params);
    return response.data;
  }
);

export const deactiveScheduler = createAsyncThunk(
  API_URL.PIPELINE_INACTIVE_SCHEDULE,
  async (params) => {
    const response = await inactiveSchedule(params);
    return response.data;
  }
);
export const deleteScheduler = createAsyncThunk(
  API_URL.PIPELINE_DELETE_SCHEDULE,
  async (params) => {
    const response = await deleteSchedule(params);
    return response.data;
  }
);

export const deletePipe = createAsyncThunk(
  API_URL.PIPELINE_DELETE_PIPELINE,
  async (params) => {
    const response = await deletePipeline(params);
    return response.data;
  }
);
export const saveCreatePipeline = createAsyncThunk(
  API_URL.PIPELINE_CREATE,
  async (params) => {
    const response = await createPipeline(params);
    return response.data;
  }
);

const initialState = {
  selectedPipe: null,
  selectedActionData: [],
  crawlers: [],
  datasets: [],
  datastores: [],
  pipelinesObj: {
    list: [],
    total: 0,
    payload: {
      isOwn: false,
      page: 0,
      page_size: 10,
      keyword: "",
    },
  },
  outputFromDataSource: [],
  outputColumns: {
    selectedOutputFrom: null,
    selectedOutputColumns: [],
  },
  pipelineRender: {},
};

export const pipelineSlice = createSlice({
  name: "pipelineSlice",
  initialState,
  reducers: {
    selectPipe: (state, { payload }) => {
      state.selectedPipe = payload;
    },
    selectActionData: (state, { payload }) => {
      return { ...state, selectedActionData: payload };
    },
    setOutputFromDataSource: (state, { payload }) => {
      return { ...state, outputFromDataSource: payload };
    },
    setOutputColumns: (state, { payload }) => {
      return { ...state, outputColumns: payload };
    },
    resetDynamicForm: (state) => {
      return {
        ...state,
        selectedPipe: null,
        selectedActionData: [],
        outputFromDataSource: [],
        outputColumns: {
          selectedOutputFrom: null,
          selectedOutputColumns: [],
        },
      };
    },
    setPayloadGetPipelineList: (state, { payload }) => ({
      ...state,
      pipelinesObj: {
        ...state.pipelinesObj,
        payload: payload,
      },
    }),
    resetPipelineRender: (state) => {
      state.pipelineRender = {};
    },
  },
  extraReducers: {
    [allCrawlers.fulfilled]: (state, { payload }) => {
      state.crawlers = payload;
    },
    [allDataset.fulfilled]: (state, { payload }) => {
      state.datasets = payload;
    },
    [getPipelineDetailById.fulfilled]: (state, { payload }) => {
      state.pipelineRender = payload;
    },
    [getPipelines.fulfilled]: (state, { payload }) => {
      state.pipelinesObj = {
        ...state.pipelinesObj,
        list: payload.pipelines,
        total: payload.total,
      };
    },
    [activeScheduler.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        pipelineRender: {
          ...state.pipelineRender,
          schedule: { ...state.pipelineRender.schedule, status: 1 },
        },
      };
    },
    [deactiveScheduler.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        pipelineRender: {
          ...state.pipelineRender,
          schedule: { ...state.pipelineRender.schedule, status: 0 },
        },
      };
    },
    [deleteScheduler.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        pipelineRender: {
          ...state.pipelineRender,
          schedule: null,
        },
      };
    },
    [saveCreatePipeline.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        pipelineRender: payload,
      };
    },
  },
});

export const {
  selectPipe,
  selectActionData,
  setOutputFromDataSource,
  setOutputColumns,
  resetDynamicForm,
  setPayloadGetPipelineList,
  resetPipelineRender,
} = pipelineSlice.actions;

export default pipelineSlice.reducer;
