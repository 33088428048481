import React from "react";
import { Checkbox, Form } from "antd";
import SettingButton from "../../Builder/SettingButton";

const FormCheckboxElement = (props) => {
  const { component, rowIndex, colIndex, index } = props;
  return (
    <>
      <Form.Item
        key={component.id}
        tooltip={component.tooltip}
        name={component.apiProperty}
        valuePropName="checked"
      >
        <Checkbox>{component.isHideLabel ? "" : component.label}</Checkbox>
      </Form.Item>
      <SettingButton
        direction="horizontal"
        position="topRight"
        rowIndex={rowIndex}
        colIndex={colIndex}
        component={component}
        index={index}
      />
    </>
  );
};
export default FormCheckboxElement;
