import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Space,
  Table,
  Tabs,
  Typography,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { SETTING_TAB } from "../../formBuilderConfig";
import { isEmpty } from "lodash";
import { generateUUID } from "../../../../../Utilities";

const DisplaySetting = ({ component }) => {
  return (
    <Form.List name="columns">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => (
            <Space.Compact block key={field.key} align="baseline">
              <Form.Item
                {...field}
                name={[field.name, "order"]}
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
                style={{ width: "40%" }}
              >
                <Input placeholder="Column" />
              </Form.Item>

              <Form.Item
                {...field}
                name={[field.name, "column"]}
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
                style={{ width: "40%" }}
              >
                <Input min={1} max={12} placeholder="Width" />
              </Form.Item>
              <Button type="ghost" icon={<MinusCircleOutlined />} onClick={() => remove(field.name)}></Button>
            </Space.Compact>
          ))}

          <Form.Item>
            <Button
              type="dashed"
              onClick={() =>
                add({
                  id: generateUUID(),
                  type: "column",
                  column: 12,
                  order: fields.length + 1,
                  components: [],
                })
              }
              block
              icon={<PlusOutlined />}
            >
              Add Column
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

const RowSetting = ({ component, formSetting }) => {
  const items = [
    {
      key: SETTING_TAB.DISPLAY,
      label: SETTING_TAB.DISPLAY_TEXT,
      children: <DisplaySetting component={component} />,
    },
  ];

  return <Tabs defaultActiveKey={SETTING_TAB.DISPLAY} items={items} />;
};
export default RowSetting;
