import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Space,
  Spin,
  theme,
  Tag,
  Switch,
} from "antd";
import { clone, isEmpty } from "lodash";

import { getActionDetail, saveAction } from "../../store/adminSlice";
import { ACTION_PERMISSION, APPLY_AT, APPLY_FOR } from "../../configs/constant";
import { actionsGroup } from "../../configs/constant";
import FormBuilder from "../components/FormBuilder/Builder/FormBuilder";
import { getCategoryList } from "../../services";

const { Content, Header } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

const ActionDetail = (props) => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { actionId } = useParams();
  const [form] = Form.useForm();
  const formBuilderRef = useRef(null);

  const {
    token: { colorBgContainer, colorFillAlter, borderRadiusLG },
  } = theme.useToken();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [actionPermissions, setActionPermissions] = useState([]);
  const [actionApplyFors, setActionApplyFors] = useState([]);
  const [actionApplyAts, setActionApplyAts] = useState([]);
  const [actionCategories, setActionCategories] = useState([]);
  const [actionMetadata, setActionMetadata] = useState("");
  const [outputColumns, setOutputColumns] = useState([]);
  const [tagVisible, setTagVisible] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const tagInputRef = useRef(null);

  const action = useSelector((states) => states.admin.actionDetail);

  /** outputColumns - tags */
  useEffect(() => {
    if (tagVisible && tagInputRef.current) {
      tagInputRef.current.focus();
    }
  }, [tagVisible]);

  const handleTagClose = (removedTag) => {
    const newColumns = outputColumns.filter((tag) => tag !== removedTag);
    setOutputColumns(newColumns);
  };

  const showTagInput = () => {
    setTagVisible(true);
  };
  const handleTagChange = (e) => {
    setTagValue(e.target.value);
  };
  const handleTagConfirm = () => {
    if (tagValue && outputColumns.indexOf(tagValue) === -1) {
      setOutputColumns([...outputColumns, tagValue]);
    }
    setTagVisible(false);
    setTagValue("");
  };
  /** outputColumns - tags */

  useEffect(() => {
    setIsLoading(true);
    dispatch(getActionDetail({ action_id: actionId })).then(() => {
      setIsLoading(false);
    });

    getCategoryList().then(({ data }) => {
      if (data) {
        const optionsCategory = data.map((x) => ({
          label: x.name,
          value: x.name,
        }));
        setActionCategories(optionsCategory);
      }
    });
  }, [actionId, dispatch]);

  useEffect(() => {
    if (!isEmpty(action)) {
      setInitialValues({
        ...clone(action),
        permission: action?.permission.split(","),
        applyAt: action?.applyAt.split(","),
        applyFor: action?.applyFor.split(","),
        configurations:
          action && action.configurations ? action.configurations : [],
      });
      setActionMetadata(action.actionMetadata);
      if (typeof action.outputColumns === "string") {
        setOutputColumns(action.outputColumns.split("||"));
      }
    }

    const optionsPermission = ACTION_PERMISSION.map((x) => ({
      label: x.name,
      value: x.id,
    }));
    const optionsApplyFor = APPLY_FOR.map((x) => ({ label: x, value: x }));
    const optionsApplyAt = APPLY_AT.map((x) => ({ label: x, value: x }));
    // const optionsCategory = actionsGroup.map((x) => ({
    //   label: x.label,
    //   value: x.key,
    // }));
    setActionPermissions(optionsPermission);
    setActionApplyFors(optionsApplyFor);
    setActionApplyAts(optionsApplyAt);
    // setActionCategories(optionsCategory);
  }, [action]);

  const handleChangeFormConfig = (actionBuilded) => {
    setActionMetadata(JSON.stringify(actionBuilded));
  };

  const handleSaveAction = (values) => {
    const actionSave = {
      ...clone(action),
      ...values,
      permission: values.permission.join(","),
      applyAt: values.applyAt.join(","),
      applyFor: values.applyFor.join(","),
      actionMetadata: clone(actionMetadata),
      outputColumns: outputColumns.length ? outputColumns.join("||") : "",
    };
    setIsSubmitting(true);
    dispatch(saveAction(actionSave)).then(() => {
      setIsSubmitting(false);
      navigator("/admin/actions");
    });
  };

  const handleGetCheckboxValue = (event) => {
    return event.target.checked ? 1 : 0;
  };

  return (
    <Layout>
      <Header
        style={{
          padding: 0,
          background: colorBgContainer,
        }}
      >
        <Space>
          <Button type="default" onClick={() => navigator("/admin/actions")}>
            Back to list
          </Button>
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
            loading={isSubmitting}
          >
            Save Action
          </Button>
        </Space>
      </Header>
      <Content
        style={{
          padding: 0,
          background: colorBgContainer,
        }}
      >
        <Card size="small" style={{ backgroundColor: "#f8f8f8" }}>
          {isLoading && <Spin>Loading...</Spin>}

          {!isLoading && (
            <Form
              form={form}
              onFinish={handleSaveAction}
              initialValues={initialValues}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="actionName"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input placeholder="Enter action's name here" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="actionDisplayName"
                    label="Display Name"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input placeholder="Enter action's display name here" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="actionCategory"
                    label="Category"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      options={actionCategories}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="applyFor"
                    label="Apply for"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      mode="tags"
                      options={actionApplyFors}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="classHandler"
                    label="Class handler"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input placeholder="Enter class handle here" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="applyAt"
                    label="Apply At"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      mode="tags"
                      options={actionApplyAts}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Space>
                    <Form.Item
                      name="price"
                      label="Price"
                      rules={[
                        {
                          required: true,
                          message: "Required",
                        },
                      ]}
                    >
                      <Input placeholder="Enter price here" />
                    </Form.Item>
                    <Form.Item
                      name="currency"
                      label="Currency"
                      rules={[
                        {
                          required: true,
                          message: "Required",
                        },
                      ]}
                    >
                      <Input placeholder="Enter currency here" />
                    </Form.Item>
                    <Form.Item
                      name="unit"
                      label="Unit"
                      rules={[
                        {
                          required: true,
                          message: "Required",
                        },
                      ]}
                    >
                      <Input placeholder="Enter unit here" />
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col>
                  <Form.Item
                    name="isPublic"
                    label="Is Public"
                    valuePropName="checked"
                    getValueFromEvent={(event) => (event ? 1 : 0)}
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Visible To Users" name="visibleToUsers">
                    <Select mode="tags"></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="permission"
                    label="Permission"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      mode="tags"
                      options={actionPermissions}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Space>
                    <Form.Item
                      name="isWaitForAction"
                      valuePropName="checked"
                      getValueFromEvent={handleGetCheckboxValue}
                    >
                      <Checkbox>Wait for action</Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="isApproveRequired"
                      valuePropName="checked"
                      getValueFromEvent={handleGetCheckboxValue}
                    >
                      <Checkbox>Is Approve Required</Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="isResponseFile"
                      valuePropName="checked"
                      getValueFromEvent={handleGetCheckboxValue}
                    >
                      <Checkbox>Response is a file</Checkbox>
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={12}>
                  <Form.Item label="Configurations">
                    <Form.List name="configurations">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field) => (
                            <Space key={field.key} align="baseline">
                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) =>
                                  prevValues.area !== curValues.area ||
                                  prevValues.sights !== curValues.sights
                                }
                              >
                                {() => (
                                  <Form.Item
                                    {...field}
                                    label="Action key"
                                    name={[field.name, "actionKey"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Action key is req",
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                )}
                              </Form.Item>
                              <Form.Item
                                {...field}
                                label="Action value"
                                name={[field.name, "actionValue"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Action value is required",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>

                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </Space>
                          ))}

                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                              // style={{ width: "calc(100% - 8em)" }}
                            >
                              Add configuration
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Output columns">
                    <Space size={[0, 8]} wrap>
                      <Space size={[0, 8]} wrap>
                        {outputColumns.map((tag) => (
                          <Tag
                            key={tag}
                            style={{
                              userSelect: "none",
                            }}
                            closable={true}
                            onClose={() => handleTagClose(tag)}
                          >
                            <span title={tag}>
                              {tag.length > 20 ? `${tag.slice(0, 20)}...` : tag}
                            </span>
                          </Tag>
                        ))}
                        {tagVisible ? (
                          <Input
                            ref={tagInputRef}
                            type="text"
                            size="small"
                            style={{
                              width: 64,
                              height: 24,
                              marginInlineEnd: 8,
                              verticalAlign: "top",
                            }}
                            value={tagValue}
                            onChange={handleTagChange}
                            onBlur={handleTagConfirm}
                            onPressEnter={handleTagConfirm}
                          />
                        ) : (
                          <Tag
                            style={{
                              height: 24,
                              background: "#fff",
                              borderStyle: "dashed",
                            }}
                            onClick={showTagInput}
                          >
                            <PlusOutlined /> Add Column
                          </Tag>
                        )}
                      </Space>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Card>

        <Card
          size="small"
          style={{ marginTop: 10, backgroundColor: "#f8f8f8" }}
        >
          <FormBuilder
            refs="formBuilderRef"
            formAction={form}
            formConfig={action.actionMetadata}
            onChange={handleChangeFormConfig}
          />
        </Card>
      </Content>
    </Layout>
  );
};
export default ActionDetail;
