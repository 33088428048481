import React, { useState, useEffect } from "react";
import { Checkbox, Form, Input, Tabs } from "antd";

const DataSetting = ({ component, children }) => {
  return (
    <>
    <Form.Item
      label="Default value"
      name="defaultValue"
      style={{marginBottom: 5}}
    >
      <Input />
    </Form.Item>
    {children}
    </>
  )
}
export default DataSetting;