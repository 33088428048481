import $ from "jquery";
import * as _ from "lodash";
import { v4 } from "uuid";

export const toCssSelector = function (node) {
  var css = "";
  if (
    node.parentNode &&
    node.parentNode.nodeName.toLowerCase() !== "html" &&
    /^([A-Za-z0-9]+)$/.test(node.parentNode.nodeName)
  ) {
    css = toCssSelector(node.parentNode) + " > ";
  }
  var index = $(node).index() + 1;
  return css + node.nodeName.toLowerCase() + ":nth-child(" + index + ")";
};

export const getInnerText = (e) => {
  var texts = [];
  if (e.childNodes.length) {
    e.childNodes.forEach((ee) => {
      if (ee.nodeType === 3) {
        texts.push(ee.textContent);
      } else {
        texts = texts.concat(getInnerText(ee));
      }
    });
  }
  return texts;
};

export const getPropertyValue = (e) => {
  var urlRegExp = /^(http(s)?:\/)?\//;
  var imgRegExp = /((http)?(s)?:?\/\/.*(\.(jpg|png|gif))?)/;
  var value = {
    text: "",
    image: "",
    link: "",
    background: "",
    imgType: "src",
    path: {},
  };
  var tag = e.prop("tagName").toLowerCase();
  switch (tag) {
    case "a":
      var href = e.attr("href");
      value.link =
        href.indexOf("http") === 0 || href.indexOf("//") === 0
          ? href
          : (e[0].baseURI + href.substring(1)).replace(/([^:]\/)\/+/g, "$1");
      var text = getInnerText(e[0]);
      var pathText = toCssSelector(e[0]);

      if (!text.length) {
        var aElms = e[0].ownerDocument.querySelectorAll("a");
        for (var i = 0; i < aElms.length; i++) {
          if (aElms[i].getAttribute("href") === href) {
            var text = getInnerText(aElms[i]);
            if (text.length) {
              pathText = toCssSelector(aElms[i]);
              break;
            }
          }
        }
      }

      value.text = text.join(" ");
      value.path.text = pathText;
      value.path.link = pathText;
      break;
    case "img":
      var src;
      if (e[0].srcset) {
        src = e[0].srcset;
        value.imgType = "srcset";
      } else if (e[0].src) {
        src = e[0].src;
      }
      var validImg =
        (src &&
          (src.indexOf("data:image") === 0 || imgRegExp.test(src)) &&
          value.imgType === "src") ||
        value.imgType === "srcset";
      if (validImg) {
        value.image =
          urlRegExp.test(src) || src.indexOf("data:image") === 0
            ? src
            : (e[0].baseURI + src).replace(/([^:]\/)\/+/g, "$1");
        value.path.image = toCssSelector(e[0]);
      }
      break;
    default:
      var text = getInnerText(e[0]);
      value.text = text.join(" ");
      value.path.text = toCssSelector(e[0]);
      break;
  }
  if (tag !== "a") {
    //console.log(e);
    var aElms = e.closest("a");

    if (!aElms.length || !urlRegExp.test(aElms.eq(0).attr("href"))) {
      aElms = e.parents("a");
    }

    if (!aElms.length) {
      aElms = e.find("a");
    }

    if (aElms.length && urlRegExp.test(aElms.eq(0).attr("href"))) {
      var aValue = getPropertyValue($(aElms[0]));
      value.link = aValue.link;
      value.image = aValue.image;
      value.imgType = aValue.imgType;
      value.path.link = aValue.path.link;
      value.path.image = aValue.path.image;
    }
  }
  if (e[0].style && (e[0].style.background || e[0].style.backgroundImage)) {
    var bgRegExp = /^url\(("|')?(((?!("|')).)*)("|')?\)$/;
    var bg = e[0].style.background.match(bgRegExp);
    if (!bg) {
      bg = e[0].style.backgroundImage.match(bgRegExp);
    }
    if (bg) {
      value.style = bg[2];
      value.path.style = toCssSelector(e[0]);
    }
  } else {
    for (var i = 0; i < e[0].attributes.length; i++) {
      var attr = e[0].attributes[i];
      if (
        ["src", "srcset", "href"].indexOf(attr.name) === -1 &&
        attr.value.match(imgRegExp)
      ) {
        value[attr.name] = attr.value;
        value.path[attr.name] = toCssSelector(e[0]);
        break;
      }
    }
  }
  if (!value.image) {
    var imgs = e.find("img");
    if (imgs.length) {
      if (imgs[0].srcset) {
        src = imgs[0].srcset;
        value.imgType = "srcset";
      } else if (imgs[0].src) {
        src = imgs[0].src;
      }
      var validImg =
        (imgRegExp.test(src) && value.imgType === "src") ||
        value.imgType === "srcset";
      if (validImg) {
        value.image = urlRegExp.test(src)
          ? src
          : (imgs[0].baseURI + src).replace(/([^:]\/)\/+/g, "$1");
        value.path.image = toCssSelector(imgs[0]);
      }
    }
  }
  return value;
};

export const getTableData = (node) => {
  var table = [];
  var parent = node.closest("table");
  var child = node.children("table").first();
  if (parent.length) {
    table = parent;
  } else if (child.length) {
    table = child;
  }
  if (table.length) {
    var tableHeaders = table.find("thead > tr > th");
    if (!tableHeaders.length) {
      tableHeaders = table.find("tbody > tr > th");
    }
    if (tableHeaders.length) {
      tableHeaders = Array.from(tableHeaders).map(function (e) {
        return e.innerText;
      });
    }
    var rows = table.find("tbody > tr");
    if (!rows.length) {
      rows = table.find(" > tr");
    }

    var maxLength = 0;
    var data = [];
    if (rows.length) {
      rows.each(function (index, e) {
        var cols = $(e).find("> td");
        if (cols.length) {
          maxLength = cols.length > maxLength ? cols.length : maxLength;
          const colData = [];
          cols.each(function (i, e) {
            colData.push(e.innerText);
          });
          data.push(colData);
        }
      });
    }
    var headers = new Array(maxLength).fill(null).map(function (e, i) {
      return {
        name: tableHeaders[i] || "",
        position: i,
      };
    });
    var result = {
      headers: headers,
      data: data,
      path: toCssSelector(table[0]),
      type: "table",
    };
    return result;
  }
  parent = node.closest("dl");
  child = node.children("dl").first();
  if (parent.length) {
    table = parent;
  } else if (child.length) {
    table = child;
  }
  if (table.length) {
    var rows = table.find("> dt, > dd");
    var index = -1;
    var headers = [];
    var data = [];
    for (var i = 0; i < rows.length; i++) {
      if (rows[i].nodeName.toLowerCase() === "dt") {
        index++;
        headers[index] = {
          name: rows[i].innerText,
          position: index,
        };
        data[index] = "";
      }
      if (rows[i].nodeName.toLowerCase() === "dd") {
        data[index] +=
          data[index] === "" ? rows[i].innerText : "\n" + rows[i].innerText;
      }
    }
    var result = {
      headers: headers,
      data: [data],
      path: toCssSelector(table[0]),
      type: "description-list",
    };
    return result;
  }

  return false;
};

export const randomColor = () => {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
};

export const buildHierarchy = (arry) => {
  var roots = [],
    pageTo = {};
  // find the top level nodes and hash the children based on parent
  for (var i = 0, len = arry.length; i < len; ++i) {
    var item = arry[i],
      p = item.browseId,
      target = !p ? roots : pageTo[p] || (pageTo[p] = []);
    target.push(_.cloneDeep(item));
  }
  // function to recursively build the tree
  var findChildren = function (parent) {
    if (pageTo[parent.id]) {
      parent.pageTo = pageTo[parent.id][0];
      for (var i = 0, len = parent.pageTo.userBrowses.length; i < len; ++i) {
        findChildren(parent.pageTo.userBrowses[i]);
      }
    }
  };
  // enumerate through to handle the case where there are multiple roots
  for (var i = 0, len = roots[0].userBrowses.length; i < len; ++i) {
    findChildren(roots[0].userBrowses[i]);
  }
  return roots[0];
};

export const buildTree = (arry) => {
  var roots = [],
    pageTo = {};
  // find the top level nodes and hash the children based on parent
  for (var i = 0, len = arry.length; i < len; ++i) {
    var item = arry[i],
      p = item.browseId,
      target = !p ? roots : pageTo[p] || (pageTo[p] = []);
    target.push(_.cloneDeep(item));
  }
  // function to recursively build the tree
  var findChildren = function (parent) {
    if (pageTo[parent.id]) {
      parent.pageTo = pageTo[parent.id][0];
      parent.url = parent.pageTo.url;
      parent.userBrowses = parent.pageTo.userBrowses;
      parent.key = parent.pageTo.id;
      for (var i = 0, len = parent.pageTo.userBrowses.length; i < len; ++i) {
        findChildren(parent.pageTo.userBrowses[i]);
      }
    }
  };
  // enumerate through to handle the case where there are multiple roots
  roots[0].key = roots[0].id;
  for (var i = 0, len = roots[0].userBrowses.length; i < len; ++i) {
    findChildren(roots[0].userBrowses[i]);
  }
  return [roots[0]];
};

export const createAction = (elm, type) => {
  var tag = $(elm).prop("tagName").toLowerCase();
  var node = $(elm)[0];
  var selector = toCssSelector(node);
  var attributes = {};
  _.forEach(elm.attributes, function (a) {
    attributes[a.nodeName] = a.nodeValue;
  });
  var action = {
    cssSelector: selector,
    actionType: type,
    inputValue: elm.value,
    tagAttributes: attributes,
    tagName: tag,
  };
  if (type === "link") {
    action.anchor = $(elm).text();
  }
  return action;
};

export const findParent = (pages, data) => {
  var parents = [];
  var find = function (page) {
    if (!page.browseId) {
      return parents;
    }
    for (var i = 0; i < pages.length; i++) {
      var idx = _.findIndex(pages[i].userBrowses, function (browse) {
        return browse.id === page.browseId;
      });
      if (idx !== -1) {
        var p = {
          id: pages[i].id,
          browseId: pages[i].browseId,
          url: pages[i].url,
          userBrowses: [pages[i].userBrowses[idx]],
        };
        parents.push(p);
        return find(pages[i]);
      }
    }
  };
  return find(data);
};

export const browse = (e, pages, page, mode, actions, browseId) => {
  const currentPage = _.cloneDeep(page);
  var event = e.type;
  var elm = $(e.target);
  var tag = elm.prop("tagName").toLowerCase();
  var type = elm[0].type;
  if (
    mode === 1 &&
    event === "click" &&
    (tag === "button" ||
      (tag === "input" && (type === "submit" || type === "button")))
  ) {
    var action = createAction(e.target, "submit");
    action.actionOrder = actions.length + 1;
    var idx = _.findIndex(actions, function (a) {
      return a.cssSelector === action.cssSelector;
    });
    if (idx === -1) {
      action.actionOrder = actions.length + 1;
      actions.push(action);
    } else {
      actions.splice(idx + 1);
    }
    var userBrowses = [
      {
        id: v4(),
        userActions: actions,
        type: 0,
        screenId: currentPage.screenId,
      },
    ];
    currentPage.userBrowses = currentPage.userBrowses.concat(userBrowses);
    browseId = userBrowses[0].id;
    var parents = findParent(pages, currentPage);
    parents.push({
      id: currentPage.id,
      browseId: currentPage.browseId,
      url: currentPage.url,
      userBrowses: [
        {
          userActions: actions,
          type: 0,
          screenId: currentPage.screenId,
        },
      ],
    });
    var data = _.cloneDeep(buildHierarchy(parents));
    return {
      data,
      currentPage,
      actions,
      browseId,
    };
  } else if (
    mode === 1 &&
    ["a", "p", "div", "span"].indexOf(tag) !== -1 &&
    event === "click"
  ) {
    var action = createAction(e.target, "link");
    action.actionOrder = actions.length + 1;
    actions.push(action);
    var userBrowses = [
      {
        id: v4(),
        userActions: actions,
        type: 0,
        screenId: currentPage.screenId,
      },
    ];
    currentPage.userBrowses = currentPage.userBrowses.concat(userBrowses);
    browseId = userBrowses[0].id;
    var parents = findParent(pages, currentPage);
    parents.push({
      id: currentPage.id,
      browseId: currentPage.browseId,
      url: currentPage.url,
      userBrowses: [
        {
          userActions: actions,
          type: 0,
          screenId: currentPage.screenId,
        },
      ],
    });
    var data = _.cloneDeep(buildHierarchy(parents));
    return {
      data,
      currentPage,
      actions,
      browseId,
    };
  } else if (event === "click" && (type === "checkbox" || type === "radio")) {
    var action = createAction(e.target, type);
    if (type === "checkbox") {
      var idx = _.findIndex(actions, function (a) {
        return a.cssSelector === action.cssSelector;
      });
      if (idx === -1) {
        action.actionOrder = actions.length + 1;
        actions.push(action);
      } else {
        actions.splice(idx, 1);
      }
    } else {
      var idx = _.findIndex(actions, function (a) {
        return a.tagAttributes.name === action.tagAttributes.name;
      });
      if (idx === -1) {
        action.actionOrder = actions.length + 1;
        actions.push(action);
      } else {
        actions[idx].cssSelector = action.cssSelector;
      }
    }
    return {
      actions,
    };
  } else if (event === "keyup") {
    var allowTypes = ["text", "password", "email", "number", "search"];
      if (tag === 'textarea' || (tag === "input" && allowTypes.indexOf(type) !== -1)) {
      var action = createAction(e.target, "textbox");
      var idx = _.findIndex(actions, function (a) {
        return a.cssSelector === action.cssSelector;
      });
      if (idx === -1) {
        action.actionOrder = actions.length + 1;
        actions.push(action);
      } else {
        actions[idx].inputValue = action.inputValue;
      }
    }
    return {
      actions,
    };
  } else {
    var a = $(e.target).closest("a");
    if (a.length && a !== e.target) {
      a.trigger("click");
    }
  }
  return null;
};

export const scroll = (doc, pages, page, actions, browseId) => {
  const currentPage = _.cloneDeep(page);
  var action = {
    actionType: "scroll",
    inputValue: doc.height(),
  };
  action.actionOrder = actions.length + 1;
  actions.push(action);
  var userBrowses = [
    {
      id: v4(),
      userActions: actions,
      type: 2,
      screenId: currentPage.screenId,
    },
  ];
  currentPage.userBrowses = currentPage.userBrowses.concat(userBrowses);
  browseId = userBrowses[0].id;
  var parents = findParent(pages, currentPage);
  parents.push({
    id: currentPage.id,
    browseId: currentPage.browseId,
    url: currentPage.url,
    userBrowses: [
      {
        userActions: actions,
        type: 2,
        screenId: currentPage.screenId,
      },
    ],
  });
  var data = _.cloneDeep(buildHierarchy(parents));
  return {
    data,
    currentPage,
    actions,
    browseId,
  };
};

export const validateData = (data, keepId, checkValidId) => {
  keepId = !!keepId;
  var d = _.cloneDeep(data);
  var clean = function (page) {
    if (!keepId || (checkValidId && isNaN(page.id))) {
      delete page.id;
    }
    delete page.browseId;
    delete page.selectedProperty;
    if (page.page) {
      var pagination = {
        fromPage: page.page.from,
        toPage: page.page.to,
      };
      page.pagination = pagination;
      delete page.page;
    }

    if (page.pagination) {
      var pagination = {
        fromPage: page.pagination.fromPage,
        toPage: page.pagination.toPage,
        id: page.pagination.id,
      };
      if (!keepId || (checkValidId && isNaN(page.id))) {
        delete pagination.id;
      }
      page.pagination = pagination;
      delete page.page;
    }

    if (page.properties && page.properties.length) {
      for (var i = 0; i < page.properties.length; i++) {
        var fields = [
          "id",
          "path",
          "pathInfer",
          "name",
          "transformers",
          "value",
          "type",
          "sampleData",
          "anchor",
          "tagAttributes",
          "tagName",
          "headers",
        ];
        if (!keepId) {
          fields.shift();
        }
        if (page.properties[i].values && page.properties[i].values.length) {
          page.properties[i].sampleData = page.properties[i].values[0].value;
        }
        page.properties[i] = _.pick(page.properties[i], fields);
        if (checkValidId && isNaN(page.properties[i].id))
          delete page.properties[i].id;
        if (
          page.properties[i].transformers &&
          page.properties[i].transformers.length
        ) {
          page.properties[i].transformers = _.map(
            page.properties[i].transformers,
            function (trans) {
              return {
                name: trans.value,
                displayName: trans.name,
                params: _.map(trans.transformerParams, function (param) {
                  return {
                    paramName: param.paramName,
                    paramDisplayName: param.paramNameDisplay,
                    paramValue: param.paramValue,
                  };
                }),
                position: trans.position,
              };
            }
          );
        }
        if (!page.properties[i].transformers)
          page.properties[i].transformers = [];
      }
    }
    _.remove(page.properties, function (p) {
      return !p.name;
    });
    if (page.userBrowses && !page.userBrowses.length) {
      delete page.userBrowses;
      return d;
    }
    if (page.userBrowses && page.userBrowses.length) {
      for (var i = 0; i < page.userBrowses.length; i++) {
        delete page.userBrowses[i].id;
        if (page.userBrowses[i].pageTo) {
          clean(page.userBrowses[i].pageTo);
        }
      }
    }
    return page;
  };
  return clean(d);
};

export const validate = function (pages) {
  const validPages = [].concat(pages);
  var $tree = _.cloneDeep(buildHierarchy(validPages));
  var valid = function (p) {
    p.properties = p.properties || [];
    _.remove(p.properties, function (p) {
      return p.isEmpty;
    });
    if (p.properties.length) {
      return true;
    }
    return false;
  };
  var checkNode = function (page, parent) {
    page.valid =
      (page.screenshots && page.screenshots.length) ||
      valid(page) ||
      (parent &&
        parent.valid &&
        parent.userBrowses.length &&
        parent.userBrowses[0].type === 1);
    if (page.userBrowses.length) {
      for (var i = 0; i < page.userBrowses.length; i++) {
        checkNode(page.userBrowses[i].pageTo, page);
        page.valid = page.valid || page.userBrowses[i].pageTo.valid;
      }
    }
    if (!page.valid) {
      _.remove(validPages, function (p) {
        return p.id === page.id;
      });
    }
  };
  checkNode($tree);
  for (var i = 0; i < validPages.length; i++) {
    const { content, ...data } = validPages[i];
    validPages[i] = data;
  }
  return validPages;
};

export const buildPage = (pages) => {
  const tree = buildHierarchy(_.cloneDeep(pages));
  const data = validateData(tree);
  return {
    page: data,
  };
};

export const download = function (data) {
  var blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
  var filename = ["csv", new Date().getTime()].join("-") + ".csv";
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
