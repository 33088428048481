import { Button, Form, Modal } from "antd";
import React, { useEffect, useState, useCallback, useContext } from "react";
import { FormDynamicBuilderContext } from "../../../../../context/FormDynamicContext";
import RowSetting from "./RowSetting";
import { isEmpty } from "lodash";
import TextFieldSetting from "./TextFieldSetting";
import CheckboxSetting from "./CheckboxSetting";
import RadioSetting from "./RadioSetting";
import SelectSetting from "./SelectSetting";
import FileUploadSetting from "./FileUploadSetting";
import TagsInputSetting from "./TagsInputSetting";
import DatasourceMappingSetting from "./DatasourceMappingSetting";
import RenameMultipleColumnsSetting from "./RenameMultipleColumnsSetting";

const SettingModal = ({ open, onCancel, onSave, onDelete }) => {
  const {
    componentSetting: { rowIndex, colIndex, component, index, actionMetaData },
  } = useContext(FormDynamicBuilderContext);
  const [form] = Form.useForm();
  const [modalTitle, setModalTitle] = useState("Component setting");

  useEffect(() => {
    if (!isEmpty(component)) {
      switch (component.type) {
        case "row":
          {
            setModalTitle("Row setting");
            form.setFieldValue("columns", component.columns);
          }
          break;
        case "text":
          {
            setModalTitle("TextField setting");
            form.setFieldsValue(component);
          }
          break;
        case "checkbox":
          {
            setModalTitle("Checkbox setting");
            form.setFieldsValue(component);
          }
          break;
        case "radio":
          {
            setModalTitle("Radio setting");
            form.setFieldsValue(component);
          }
          break;
        case "select":
          {
            setModalTitle("Select setting");
            const components = [].concat
              .apply(
                [],
                [].concat
                  .apply(
                    [],
                    actionMetaData.map(function (x) {
                      return x.columns;
                    })
                  )
                  .map(function (x) {
                    return x.components;
                  })
              )
              .map((x) => ({
                label: `${x.label} (${x.apiProperty})`,
                value: x.apiProperty,
              }));
            component.componentsOption = components;
            form.setFieldsValue(component);
          }
          break;
        case "fileUpload":
          {
            setModalTitle("FileUpload setting");
            form.setFieldsValue(component);
          }
          break;
        case "tagsinput":
          {
            setModalTitle("TagsInput setting");
            form.setFieldsValue(component);
          }
          break;
        case "dataSourceMapping":
          {
            setModalTitle("Datasource mapping setting");
            form.setFieldsValue(component);
          }
          break;
          case "renameMultipleColumns":
          {
            setModalTitle("Rename multiple column setting");
            component.getDatasetSourceFrom = [];
            form.setFieldsValue(component);
          }
          break;
      }
    }
  }, [component]);

  const handleCancel = () => {
    onCancel instanceof Function && onCancel();
  };
  const handleSave = (values) => {
    form.resetFields();
    onSave instanceof Function &&
      onSave(rowIndex, colIndex, component, index, values);
  };
  const handleOnDelete = () => {
    onDelete instanceof Function &&
      onDelete(rowIndex, colIndex, component, index);
  };
  return (
    <>
      <Modal
        width={900}
        title={modalTitle}
        open={open}
        onCancel={handleCancel}
        cancelText="Close"
        footer={[
          <Button key="save" onClick={() => form.submit()} type="primary">
            Save
          </Button>,
          <Button type="default" key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="clone" onClick={handleOnDelete} type="default" danger>
            Remove
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={handleSave}>
          {component?.type === "row" && <RowSetting component={component} />}
          {component?.type === "text" && (
            <TextFieldSetting component={component} />
          )}
          {component?.type === "checkbox" && (
            <CheckboxSetting component={component} />
          )}
          {component?.type === "radio" && (
            <RadioSetting component={component} />
          )}
          {component?.type === "select" && (
            <SelectSetting component={component} />
          )}
          {component?.type === "fileUpload" && (
            <FileUploadSetting component={component} />
          )}
          {component?.type === "tagsinput" && (
            <TagsInputSetting component={component} />
          )}
          {component?.type === "dataSourceMapping" && (
            <DatasourceMappingSetting component={component} />
          )}
          {component?.type === "renameMultipleColumns" && (
            <RenameMultipleColumnsSetting component={component} />
          )}
        </Form>
      </Modal>
    </>
  );
};
export default SettingModal;
