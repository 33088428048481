import { v4 } from "uuid";
import { cloneDeep } from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getExtractionConfig,
  getExtractionContent,
  getFileContent,
} from "../services";
import { getPreSignedUrl, uploadTos3 } from "../../../services/aws";

const getExtractionHtml = createAsyncThunk(
  "extranction/html",
  async (_, { getState }) => {
    try {
      const { extraction } = getState();
      const result = {};
      if (!extraction.config) {
        const r = await getExtractionConfig();
        result.config = r.data;
      }

      if (extraction.initial.type === "url") {
        let url = extraction.initial.url;
        if (url && !url.startsWith("http://") && !url.startsWith("https://")) {
          url = `http://${url}`;
        }
        const r = await getExtractionContent({
          url: encodeURI(url),
          width: window.outerWidth,
          height: window.outerHeight,
        });
        const page = {
          id: v4(),
          browseId: null,
          content: r.data.content,
          url: r.data.url,
          screenId: r.data.screenId,
          userBrowses: [],
          properties: [],
          page: {
            from: 1,
            to: 1,
          },
        };
        result.currentPage = cloneDeep(page);
      }

      if (extraction.initial.type === "file") {
        const { file } = extraction.initial;
        const fileName = file.name.replace(/\W/g, "-").replace(/-+/g, "-");
        const r1 = await getPreSignedUrl(fileName);
        await uploadTos3(r1.data.presignUrl, file);
        const r2 = await getFileContent({ fileName });
        const page = {
          id: v4(),
          browseId: null,
          content: r2.data.content,
          url: "",
          screenId: r2.data.screenId,
          userBrowses: [],
          properties: [],
          page: {
            from: 1,
            to: 1,
          },
        };
        result.currentPage = cloneDeep(page);
      }

      return result;
    } catch (e) {
      throw e;
    }
  }
);

export default getExtractionHtml;
