import React, { lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Menu } from "antd";
import { Link, Routes, Route, useLocation } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";

import { menuItems } from "../../../../configs/navigatorConfig";
import { logout } from "../../../../store/authSlice";

const Layout1Menu = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;

  const { user } = useSelector((states) => states.auth);
  const { user: userAuth } = user;

  const selectedSubMenuKey = menuItems.find(
    (item) =>
      item.children &&
      item.children.some((childItem) => childItem.path === pathname)
  )?.key;
  const isAuthen = (user, permission) => {
    const filtered = (user || []).filter((item) => (permission || []).includes(item));
    return filtered.length > 0;
  };
  const renderMenuItem = (item) => {
    if (item.hidden || !isAuthen(userAuth?.groups || [], item.auth || []))
      return null;
    return (
      <Menu.Item key={item.key}>
        <Link to={item.path}>{item.label}</Link>
      </Menu.Item>
    );
  };

  const handleLogout = () => {
    dispatch(logout()).then(() => {
      window.location.replace("http://dalavi.io/");
    });
  }
  const hasOne = (menu) => {
    return menu.children.some(item => !item.hidden && isAuthen(userAuth?.groups || [], item.auth))
  }
  return (
    <Menu
      {...props}
      selectedKeys={[pathname.split("/")[1]]}
      defaultOpenKeys={[selectedSubMenuKey]}
    >
      {menuItems.map((item) => {
        if (item.children && hasOne(item)) {
          return (
            <Menu.SubMenu key={item.key} title={item.label}>
              {item.children.map((childItem) => {
                return renderMenuItem(childItem);
              })}
            </Menu.SubMenu>
          );
        } else {
          return renderMenuItem(item);
        }
      })}
      <Button
        style={{
          position: "fixed",
          bottom: 0,
          width: 200,
        }}
        type="primary"
        icon={<LogoutOutlined />}
        onClick={handleLogout}
      >
        Logout
      </Button>
    </Menu>
  );
};

export default Layout1Menu;
