import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosCustom from "../services/base/axiosCustom";
import LoginModal from "./LoginModal";

import { notLogin, refreshLogin, autoLogin } from "../store/authSlice";

import { message } from "antd";
import { checkIsExpired } from "../Utilities";
import usePrevious from "../Utilities/usePrevious";

const Auth = ({ children }) => {
  const dispatch = useDispatch();
  const [startTime, setStartTime] = useState(new Date());

  const [minutes, setMinutes] = useState(5);
  const [messageApi, contextHolder] = message.useMessage();

  const { isLoggedIn, expiredTime } = useSelector((states) => states.auth);

  const prevLoggedIn = usePrevious(isLoggedIn);

  useEffect(async () => {
    if (!isLoggedIn) {
      const savedUser = JSON.parse(localStorage.getItem("user"));
      if (savedUser) {
        if (checkIsExpired(savedUser?.expiredTime)) {
          messageApi.open({
            type: "loading",
            content: "Automatic login..",
            duration: 0,
          });
          await dispatch(refreshLogin(savedUser));
          messageApi.destroy();
        } else {
          dispatch(autoLogin(savedUser));
        }
      } else if (prevLoggedIn) {
        window.location.href = "/";
      }
    } else {
      axiosCustom.init();
      setStartTime(new Date(expiredTime));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) return;
    let animationFrameId;
    let isMounted = true;
    const updateElapsedTime = () => {
      if (isMounted) {
        const currentTime = Date.now();
        const elapsedMillis = startTime.getTime() - currentTime;
        const elapsedMinutes = Math.floor(elapsedMillis / 60000);
        setMinutes(elapsedMinutes);
        animationFrameId = requestAnimationFrame(updateElapsedTime);
      }
    };

    animationFrameId = requestAnimationFrame(updateElapsedTime);

    return () => {
      isMounted = false;
      cancelAnimationFrame(animationFrameId);
    };
  }, [startTime]);

  useEffect(() => {
    if (!isLoggedIn) return;
    if (minutes <= 1) {
      dispatch(notLogin());
    }
  }, [minutes]);

  return (
    <div>
      {contextHolder}
      {children}
      <LoginModal />
    </div>
  );
};

export default Auth;
