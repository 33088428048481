import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Space,
  Drawer,
  Form,
  Input,
  Row,
  Col,
  Checkbox,
  notification,
  Switch,
} from "antd";
import {
  PlayCircleFilled,
  PauseCircleFilled,
  SaveFilled,
  ClockCircleFilled,
  DeleteFilled,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import Cron, { HEADER } from "react-cron-generator";
import "react-cron-generator/dist/cron-builder.css";
import { tryParse } from "../../Utilities";
import { createPipeline } from "../../services";
import { PipelineContext } from "../../context";
import { sleep } from "../../Utilities/index";
import {
  activeScheduler,
  deactiveScheduler,
  deleteScheduler,
  run,
  saveCreatePipeline,
  terminate,
  getPipelineDetailById,
} from "../../store/pipelineSlice";
import withErrorHandling from "../../HOC/withErrorHandler";
import { isEmpty, max } from "lodash";
import { useCallback } from "react";
import HistoryLog, { HISTORY_TYPE } from "../components/History/HistoryLog";
import SchedulerMagt from "../components/SchedulerMagt/SchedulerMagt";

const cronOptions = {
  headers: [HEADER.HOURLY, HEADER.DAILY, HEADER.WEEKLY, HEADER.MONTHLY],
};

const PipelineInfo = (props) => {
  const { nodes, edges } = props;
  const dispatch = useDispatch();

  const navigator = useNavigate();
  const { pipelineId } = useParams();
  const [form] = Form.useForm();
  const { setSubmiting } = useContext(PipelineContext);

  const { pipelineRender } = useSelector((states) => states.pipeline);

  const [scheduleLoading, setScheduleLoading] = useState(false);
  const [pipelineLoading, setPipelineLoading] = useState(true);
  const [openDetail, setOpenDetail] = useState(false);
  const [latestRunPipeline, setLatestRunPipeline] = useState();
  const [isPipelineRunning, setIsPipelineRunning] = useState(false);
  const [cronValue, setCronValue] = useState("0 0 00 1/1 * ? *");
  const [showCronTab, setShowCronTab] = useState(false);
  const [schedulers, setSchedulers] = useState([]);
  const [initialFromValues, setInitialFromValues] = useState({
    pipeName: "",
    pipeDescription: "",
    showCronTab: showCronTab,
  });

  useEffect(() => {
    // calculate status before set to obj
    let lastedRunId = max(
      pipelineRender.pipelineRuns
        ?.filter((x) => x.status !== "CANCEL")
        .map((x) => x.id)
    );
    let lastedRun = pipelineRender.pipelineRuns?.find(
      (x) => x.id === lastedRunId
    );
    setLatestRunPipeline(lastedRun);

    if (pipelineRender?.schedule) {
      setCronValue(pipelineRender.schedule?.crons || cronValue);
      setShowCronTab(true);
    }
    form.setFieldsValue({
      ...initialFromValues,
      pipelineName: pipelineRender?.name,
      pipelineDescription: pipelineRender?.description,
      showCronTab: !!pipelineRender?.schedule,
    });

    setPipelineLoading(false);
  }, [pipelineRender]);

  const handleSavePipeline = withErrorHandling(async () => {
    setPipelineLoading(true);
    let actionRequestsSource = nodes.map((node) => {
      let components = [].concat.apply(
        [],
        [].concat
          .apply(
            [],
            JSON.parse(node.data.action.actionMetadata).map(function (x) {
              return x.columns;
            })
          )
          .map(function (x) {
            return x.components;
          })
      );
      let actionRequestParameters = components.map((x) => {
        const parsed = tryParse(x[x.apiProperty]);
        if (
          (x.type === "renameMultipleColumns" ||
            x.type === "dataSourceMapping") &&
          parsed.success
        ) {
          return {
            paramName: x.apiProperty,
            paramValues: JSON.stringify(x[x.apiProperty]),
          };
        } else if (x.type === "fileUpload") {
          const fileValues = x[x.apiProperty]
            ?.map((file) => file.name)
            .join("||");
          return {
            paramName: x.apiProperty,
            paramValues: fileValues,
          };
        } else {
          return {
            paramName: x.apiProperty,
            paramValues: Array.isArray(x[x.apiProperty])
              ? x[x.apiProperty].join("||")
              : x[x.apiProperty],
          };
        }
      });
      let nodeSaved = [];
      if (
        pipelineRender.actionRequests &&
        pipelineRender.actionRequests.length
      ) {
        nodeSaved = pipelineRender.actionRequests.filter(
          (x) => x.idRender == node.idRender
        );
      }
      return {
        action: {
          id: node.data.action.id,
        },
        actionRequestName: node.data.label,
        outputColumns: node.data.selectedOutputColumns?.join(","),
        actionRequestParameters: actionRequestParameters,
        id: nodeSaved && nodeSaved.length ? nodeSaved[0].id : undefined,
        idRender: node.idRender,
      };
    });

    let pipelineConnectors = [];

    edges.forEach((connector) => {
      let sourceData = nodes.find((x) => x.idRender == connector.source);
      let targetData = nodes.find((x) => x.idRender == connector.target);
      if (sourceData && targetData) {
        pipelineConnectors.push({
          actionRequestFrom: {
            actionRequestName: sourceData?.data?.label,
            idRender: connector.source,
            // id: edgeSaved?.actionRequestFrom?.id,
          },
          actionRequestTo: {
            actionRequestName: targetData?.data?.label,
            idRender: connector.target,
            // id: edgeSaved?.actionRequestTo?.id,
          },
        });
      }
    });

    let nodesRemoveUnuseData = JSON.parse(JSON.stringify(nodes)).map(
      (element) => {
        let actionMetadata = JSON.parse(element.data.action.actionMetadata);
        actionMetadata.map((row) => {
          row.columns.map((col) => {
            col.components.map((c) => {
              if (
                c.type === "select" &&
                (c.dataSouceType == "dataset" ||
                  c.dataSouceType == "crawler" ||
                  c.dataSouceType == "column")
              ) {
                c.values = [];
                c.getDatasetSourceFrom = [];
              } else if (c.type == "taginputs") {
              }
            });
          });
        });

        element.data.action.actionMetadata = JSON.stringify(actionMetadata);

        return element;
      }
    );

    let pipelineObj = {
      nodes: nodesRemoveUnuseData,
      edges: edges,
    };

    const pipelineForm = form.getFieldsValue();

    let pipeline = {
      ...pipelineRender,
      name: pipelineForm.pipelineName,
      description: pipelineForm.pipelineDescription,
      actionRequests: actionRequestsSource,
      pipelineConnectors: pipelineConnectors,
      pipelineMetadata: JSON.stringify(pipelineObj),
    };

    if (showCronTab) {
      if (pipelineRender.schedule) {
        pipeline.schedule = { ...pipelineRender.schedule, crons: cronValue };
      } else {
        pipeline.schedule = {
          endTime: null,
          startTime: null,
          status: 1,
          timeZone: null,
          type: null,
          crons: cronValue,
        };
      }
    }

    setSubmiting(true);
    dispatch(saveCreatePipeline(pipeline)).then((res) => {
      setSubmiting(false);
      setPipelineLoading(false);
      setPipelineLoading(false);
      notification.success({
        message: `Save pipeline ${pipelineForm.pipelineName} succeed`,
        description: "",
      });
      console.log('saveCreatePipeline', res);
      navigator(`/pipelines/${res.payload?.id}`);
    });
    // try {
    //   const response = await createPipeline(pipeline);
    //   setPipelineLoading(false);
    //   notification.success({
    //     message: `Save pipeline ${pipelineForm.pipelineName} succeed`,
    //     description: "",
    //   });
    //   navigator(`/pipelines/${response.data.id}`);
    // } catch (err) {
    //   console.error(err);
    //   notification.error({
    //     message: `Save pipeline ${pipelineForm.pipelineName} error`,
    //     description: JSON.stringify(err),
    //   });
    // } finally {
    //   setSubmiting(false);
    //   setPipelineLoading(false);
    // }
  });

  const handleRunPipeline = withErrorHandling(async (e) => {
    if (isPipelineRunning) return;
    setPipelineLoading(true);

    const pipelineName = form.getFieldValue("pipelineName");
    dispatch(run(pipelineId));
    await sleep(1000);
    setPipelineLoading(false);
    setIsPipelineRunning(true);
    notification.success({
      message: `Pipeline ${pipelineName} are running`,
      description: "",
    });
    dispatch(getPipelineDetailById({ pipeline_id: pipelineId }));
    // .then((res) => {
    //   setPipelineLoading(false);
    //   setIsPipelineRunning(true);

    //   notification.success({
    //     message: `Pipeline ${pipelineName} are running`,
    //     description: "",
    //   });
    // })
    // .catch((err) => {
    //   setPipelineLoading(false);
    //   setIsPipelineRunning(false);

    //   notification.success({
    //     message: `Pipeline ${pipelineName} run failed`,
    //     description: "",
    //   });
    // });
  });

  const handleTerminatePipeline = withErrorHandling(() => {
    const pipelineName = form.getFieldValue("pipelineName");
    setPipelineLoading(true);
    dispatch(terminate(latestRunPipeline?.id))
      .then((res) => {
        setIsPipelineRunning(false);
        setPipelineLoading(false);
        notification.success({
          message: `Pipeline ${pipelineName} was terminated`,
          description: "",
        });
      })
      .catch((err) => {
        setPipelineLoading(false);
        setIsPipelineRunning(false);
        notification.success({
          message: `Pipeline ${pipelineName} terminate failed`,
          description: "",
        });
      });
  });

  const handleActiveSchedule = withErrorHandling((e) => {
    setScheduleLoading(true);
    dispatch(activeScheduler(pipelineRender.id)).then((res) => {
      setScheduleLoading(false);
    });
  });

  const handleDeactiveSchedule = withErrorHandling((e) => {
    setScheduleLoading(true);
    dispatch(deactiveScheduler(pipelineRender.id)).then((res) => {
      setScheduleLoading(false);
    });
  });

  const handleDeleteSchedule = withErrorHandling((e) => {
    setScheduleLoading(true);
    dispatch(deleteScheduler(pipelineRender.id)).then((res) => {
      setShowCronTab(false);
      setScheduleLoading(false);
    });
  });

  const handleOpenDetail = useCallback(() => {
    setOpenDetail(true);
  });

  return (
    <>
      <Space
        direction="vertical"
        align="end"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        <Button
          type="primary"
          onClick={handleOpenDetail}
          className="pipeline-btn"
        >
          Pipeline Config
        </Button>
        <HistoryLog
          type={HISTORY_TYPE.PIPELINE}
          data={pipelineRender}
          classn="pipeline-btn"
        />
        <SchedulerMagt
          value={schedulers}
          onChange={setSchedulers}
          classn="pipeline-btn"
          visible={pipelineRender?.id > 0}
          objectId={pipelineRender?.id} 
        />
      </Space>

      <Drawer
        width={520}
        mask={false}
        closable
        getContainer={false}
        open={openDetail}
        onClose={() => setOpenDetail(false)}
        extra={
          <Space>
            {!isPipelineRunning && (
              <Button
                onClick={() => {
                  form.submit();
                }}
                type="primary"
                icon={<SaveFilled />}
                loading={pipelineLoading}
              >
                Save Pipeline
              </Button>
            )}
            {!isPipelineRunning && pipelineId && (
              <Button
                type="primary"
                icon={<PlayCircleFilled />}
                onClick={handleRunPipeline}
                loading={pipelineLoading}
              >
                Run Pipeline
              </Button>
            )}
            {isPipelineRunning && (
              <Button
                type="primary"
                icon={<PauseCircleFilled />}
                danger
                onClick={handleTerminatePipeline}
                loading={pipelineLoading}
              >
                Terminate
              </Button>
            )}
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={initialFromValues}
          onFinish={handleSavePipeline}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="pipelineName"
                id="pipeline-name"
                label="Pipeline's Name"
                rules={[
                  { required: true, message: "Please enter pipeline's name" },
                ]}
              >
                <Input placeholder="Please enter pipeline's name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="pipelineDescription"
                id="pipeline-description"
                label="Description"
              >
                <Input placeholder="Please enter pipe's description" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};
export default React.memo(PipelineInfo);
