import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import requireLogin from "../middlewares/requireLogin";

import pipelineSlice from "./pipelineSlice";
import authSlice from "./authSlice";
import sharedSlice from "./sharedSlice";
import datasetSlice from "./datasetSlice";
import adminSlice from "./adminSlice";
import crawlerSlice from "./crawlerSlice";
import datastoreSlice from "./datastoreSlice";
import extractionSlice from "../pages/Front/slices";

export const store = configureStore({
  reducer: {
    pipeline: pipelineSlice,
    auth: authSlice,
    shared: sharedSlice,
    dataset: datasetSlice,
    admin: adminSlice,
    crawler: crawlerSlice,
    datastore: datastoreSlice,
    extraction: extractionSlice,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false, // Bỏ qua các action không serializable
      thunk: true,
    }),
    requireLogin,
    thunk,
  ],
});
