import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
  useRef,
  useLayoutEffect,
} from "react";
import { useSelector } from "react-redux";

import {
  Button,
  Space,
  Drawer,
  Form,
  Select,
  Row,
  Col,
  Spin,
  Input,
} from "antd";
import "./index.css";
import {
  FormSelectElement,
  FormCheckboxElement,
  FormTextElement,
  FileUploadElement,
  TagsInputElement,
  DatasourceMapping,
  RenameMultipleColumns,
} from "../components/FormBuilder/DynamicComponent";
import { tryParse } from "../../Utilities";
import { PipelineContext } from "../../context";
import { isEmpty } from "lodash";
import withErrorHandling from "../../HOC/withErrorHandler";

const DynamicComponentModal = (props) => {
  const { open, onClose, onSave } = props;
  const { nodes, edges } = useContext(PipelineContext);

  const {
    selectedActionData,
    outputFromDataSource,
    outputColumns,
    selectedPipe,
  } = useSelector((states) => states.pipeline);

  const [form] = Form.useForm();

  const [actionData, setActionData] = useState([]);
  const [initialFromValues, setInitialFromValues] = useState({});
  const [selectedOutputColumns, setSelectedOutputColumns] = useState([]);

  useEffect(() => {
    setActionData(selectedActionData);

    const initialValues = selectedActionData.reduce((acc, curr) => {
      const { columns } = curr;
      columns.forEach((col) => {
        const { components } = col;
        components.forEach((component) => {
          acc[component.apiProperty] = component[component.apiProperty];
        });
      });
      return acc;
    }, {});

    setInitialFromValues(initialValues);
    form.setFieldsValue(initialValues);
  }, [selectedActionData]);

  useEffect(() => {
    const initialValues = {
      ...initialFromValues,
      label: selectedPipe?.data?.label,
    };
    setInitialFromValues(initialValues);
    form.setFieldsValue(initialValues);

    // build output source
    // buildOutputSource(selectedPipe?.id);
  }, [selectedPipe]);

  // initial form values
  useEffect(() => {
    const initialValues = {
      ...initialFromValues,
      selectedOutputFrom: outputColumns.selectedOutputFrom,
      selectedOutputColumns: outputColumns.selectedOutputColumns,
    };
    setInitialFromValues(initialValues);
    form.setFieldsValue(initialValues);
  }, [outputColumns]);

  const getPreviousPipeOutput = useCallback(
    (targetPipeId) => {
      const previousPipeIds = edges
        .filter((x) => x.target == targetPipeId)
        .map((x) => x.source);
      return nodes.filter((x) => previousPipeIds.includes(x.idRender));
    },
    [nodes, edges]
  );

  const isMultiPreviousPipe = useCallback(
    (targetPipeId) => {
      return edges.filter((x) => x.target == targetPipeId).length > 1;
    },
    [edges]
  );

  const handleChangeOutput = withErrorHandling((value, option) => {
    if (["00000", "-1"].indexOf(value) !== -1 && selectedPipe) {
      if (value === "00000") {
        const previousPipes = getPreviousPipeOutput(selectedPipe?.id);
        const columns = previousPipes.flatMap(
          (x) => x.data?.selectedOutputColumns
        );
        const uniqueColumns = [...new Set(columns)];
        setOutputColumns(uniqueColumns);
        // const outputColumns = getPreviousPipeOutput(selectedPipe.id);
        // set to form
      }

      if (value === "-1") {
        const columns = selectedPipe.data?.action?.outputColumns
          ? selectedPipe.data?.action?.outputColumns.split("||")
          : [];
        const uniqueColumns = [...new Set(columns)];
        setOutputColumns(uniqueColumns);
      }
    } else {
      let components = [].concat.apply(
        [],
        [].concat
          .apply(
            [],
            actionData.map(function (x) {
              return x.columns;
            })
          )
          .map(function (x) {
            return x.components;
          })
      );

      const parsed = tryParse(value);

      if (parsed.success && parsed.data.hasOwnProperty("isFromSource")) {
        const outputSelected = components.find((x) => x.id == parsed.data.id);

        getOutputColumnDatasource(outputSelected, value, {
          ...parsed.data,
          type: "dataSourceMapping",
          columns:
            option.type === "dataSourceMapping" &&
            option.isFromSource === parsed.data.isFromSource
              ? option.columns
              : undefined,
        });
      } else {
        const outputSelected = components.find((x) => x.id == value);
        getOutputColumnDatasource(outputSelected, value, option);
      }
    }
  });

  const getOutputColumnDatasource = (outputSource, selectedOutput, options) => {
    const formValues = form.getFieldsValue();
    if (outputSource.id == selectedOutput) {
      const componentValue = formValues[outputSource.apiProperty];

      if (outputSource.type === "renameMultipleColumns") {
        setOutputColumnInternalComponent(outputSource, options);
        return;
      }

      if (outputSource.isMultiple) {
        setOuputColumnMultipleValue(outputSource, componentValue);
      } else {
        setOuputColumnSingleValue(outputSource, componentValue);
      }
    }

    if (options && options.type === "dataSourceMapping") {
      if (options && !isEmpty(options.columns) && options.columns.length > 0) {
        setOutputColumns(options.columns);
        return;
      }
      if (options.isFromSource) {
        const columns = formValues[
          outputSource?.apiProperty
        ]?.columnsMapping?.map((x) => x.source);
        setOutputColumns(columns || []);
      } else {
        const columns = formValues[
          outputSource?.apiProperty
        ]?.columnsMapping?.map((x) => x.target);
        setOutputColumns(columns || []);
      }
    }
  };

  const setOutputColumnInternalComponent = (outputSource, option) => {
    if (outputSource.type === "renameMultipleColumns") {
      if (
        option &&
        (option?.type === "renameMultipleColumns" ||
          option?.type === "dataSourceMapping")
      ) {
        setOutputColumns(option.columns);
        return;
      }
      const formValue = form.getFieldValue(outputSource.apiProperty);
      const columns = formValue?.map((x) => x.target);
      setOutputColumns(columns);
      return;
    }
  };

  const setOuputColumnMultipleValue = (outputSource, componentValue) => {
    switch (outputSource.type) {
      case "select":
        {
          const columns = outputSource.values
            .filter((x) => componentValue?.includes(x.value))
            .reduce((curr, next) => {
              return curr
                .concat(next.columns)
                .filter((value, index, self) => self.indexOf(value) === index);
            }, []);
          setOutputColumns(columns);
        }
        break;
    }
  };

  const setOuputColumnSingleValue = (outputSource, componentValue) => {
    switch (outputSource.type) {
      case "select":
        {
          const columns =
            outputSource.values.find((x) => x.value == componentValue)
              ?.columns || [];
          setOutputColumns(columns);
        }
        break;
    }
  };

  const setOutputColumns = (columns) => {
    columns = columns.filter((x) => !isEmpty(x));
    setSelectedOutputColumns(columns);

    const formValues = form.getFieldsValue();
    const formNewValues = {
      ...formValues,
      selectedOutputColumns: columns,
    };
    form.setFieldsValue(formNewValues);
  };

  const handleElementChange = (e, option) => {
    const selectedOutputFrom = form.getFieldValue("selectedOutputFrom");

    if (
      selectedOutputFrom &&
      selectedOutputFrom != "00000" &&
      selectedOutputFrom !== -1
    ) {
      handleChangeOutput(selectedOutputFrom, option);
    }
  };

  const renderDynamicUI = useCallback(() => {
    if (!actionData || !actionData.length) return <span>Nothing to show!</span>;
    return actionData.map((row, rowIndex) => (
      <Row gutter={[8, 8]} key={row.id}>
        {row.columns.map((column, colIndex) => (
          <Col span={column.column * 2} key={column.id}>
            {column.components.map((component, index) => {
              switch (component.type) {
                case "text":
                  return (
                    <FormTextElement component={component} key={component.id} />
                  );
                case "checkbox":
                  return (
                    <FormCheckboxElement
                      component={component}
                      key={component.id}
                    />
                  );
                case "select":
                  return (
                    <FormSelectElement
                      component={component}
                      key={component.id}
                      onChange={handleElementChange}
                    />
                  );
                case "tagsinput":
                  return (
                    <TagsInputElement
                      component={component}
                      key={component.id}
                    />
                  );
                case "fileUpload":
                  return (
                    <FileUploadElement
                      component={component}
                      key={component.id}
                    />
                  );
                case "dataSourceMapping":
                  return (
                    <DatasourceMapping
                      component={component}
                      key={component.id}
                      onChange={handleElementChange}
                    />
                  );
                case "renameMultipleColumns":
                  return (
                    <RenameMultipleColumns
                      component={component}
                      key={component.id}
                      onChange={handleElementChange}
                    />
                  );
              }
            })}
          </Col>
        ))}
      </Row>
    ));
  }, [actionData]);

  const handleCloseSetting = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleSaveSetting = (values) => {
    let savedActionData = JSON.parse(JSON.stringify(selectedActionData));
    savedActionData.forEach((row) => {
      row.columns.forEach((col) => {
        col.components.forEach((component) => {
          component[component.apiProperty] = values[component.apiProperty];
          component[`__${component.apiProperty}_dataSourceSelected`] =
            values[`__${component.apiProperty}_dataSourceSelected`];

          if (
            ["select", "column", "renameMultipleColumns"].includes(
              component.type
            )
          ) {
            if (
              component.type === "select" &&
              component.dataSouceType !== "values"
            ) {
              delete component.values;
            }
            delete component.getDatasetSourceFrom;
          }
        });
      });
    });

    if (typeof onSave === "function") {
      onSave(savedActionData, values);
    }
  };

  return (
    <Drawer
      title="Setting"
      width={520}
      closable
      mask={false}
      onClose={handleCloseSetting}
      open={open}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button onClick={() => form.submit()} type="primary">
            Save
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" form={form} onFinish={handleSaveSetting}>
        <Form.Item
          name="label"
          id="pipe-name"
          label="Pipe's Name"
          rules={[{ required: true, message: "Please enter pipe's name" }]}
        >
          <Input placeholder="Please enter pipe's name" />
        </Form.Item>

        {renderDynamicUI()}

        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Select output from" name="selectedOutputFrom">
              <Select onChange={handleChangeOutput}>
                <Select.Option value="00000" key={"00000"}>
                  {" "}
                  Previous action's output{" "}
                </Select.Option>
                <Select.Option value="-1" key={"-1"}>
                  Action output columns
                </Select.Option>

                {outputFromDataSource.map((output) => {
                  switch (output.type) {
                    case "dataSourceMapping": {
                      return (
                        <Select.Option
                          key={output.id}
                          value={JSON.stringify({
                            id: output.id,
                            isFromSource: !!output.isFromSource,
                          })}
                        >
                          {output.isFromSource
                            ? output.fromDataSourceTypeLabel
                            : output.toDataSourceTypeLabel}
                        </Select.Option>
                      );
                    }
                    case "renameMultipleColumns": {
                      return (
                        <option value={output.id} key={output.id}>
                          After Rename
                        </option>
                      );
                    }
                    default:
                      return (
                        <Select.Option value={output.id} key={output.id}>
                          {output.label}
                        </Select.Option>
                      );
                  }
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Columns of output"
              name="selectedOutputColumns"
              tooltip="Columns of output"
            >
              <Select mode="tags">
                {selectedOutputColumns?.map((x) => (
                  <Select.Option key={x} value={x}>
                    {x}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default React.memo(DynamicComponentModal);
