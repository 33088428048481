export const FORM_COMPONENTS = [
  {
    type: "basic",
    name: "Basic",
    isOpen: true,
    components: [
      {
        type: "text",
        name: "Text Field",
        label: "Text Field",
        icon: "fa fa-terminal",
      },
      {
        type: "checkbox",
        name: "Checkbox",
        label: "Checkbox",
        icon: "fa fa-check-square",
        trueValue: true,
        falseValue: false,
      },
      {
        type: "radio",
        name: "Radio",
        label: "Radio",
        icon: "fa fa-dot-circle-o",
        values: [{ label: "", value: "" }],
      },
      {
        type: "select",
        name: "Select",
        label: "Select",
        icon: "fa fa-plus-square",
        dataSouceType: "values",
        values: [],
      },
    ],
  },
  {
    type: "advance",
    name: "Advance",
    components: [
      {
        type: "fileUpload",
        name: "File Upload",
        label: "File Upload",
        icon: "fa fa-upload",
      },
      {
        type: "email",
        name: "Email",
        label: "Email",
        icon: "fa fa-envelope-o",
      },
      {
        type: "date",
        name: "Date",
        label: "Date",
        icon: "fa fa-calendar",
      },
      {
        type: "date_range",
        name: "Date range",
        label: "Date range",
        icon: "fa fa-calendar",
      },
      {
        type: "tagsinput",
        name: "Tags input",
        label: "Tags input",
        icon: "fa fa-tags",
      },
    ],
  },
  {
    type: "internalApp",
    name: "Internal App",
    components: [
      {
        type: "dataSourceMapping",
        name: "Data Source Mapping",
        label: "Data Source Mapping",
        icon: "fa fa-bars",
      },
      {
        type: "renameMultipleColumns",
        name: "Rename Multiple Columns",
        label: "Rename Multiple Columns",
        icon: "fa fa-bars",
        getDatasetSourceFrom: []
      },
    ],
  },
  {
    type: "layout",
    name: "Layout",
    components: [
      {
        type: "row",
        name: "Row",
        icon: "fa fa-columns",
      },
      {
        type: "panel",
        name: "Panel",
        icon: "fa fa-list-alt",
      },
      {
        type: "tab",
        name: "Tab",
        icon: "fa fa-folder-o",
      },
      {
        type: "content",
        name: "Content",
        icon: "fa fa-html5",
      },
    ],
  },
];

export const MIMETYPES_SUPPORT = {
  /// Renders the HTML payload in contentEditable elements and rich text (WYSIWYG) editors like Google Docs, Microsoft Word, and others.
  TEXT_HTML: "text/html",
  /// Sets the value of input elements, content of code editors, and the fallback from text/html
  TEXT_PLAIN: "text/plain",
  /// Navigates to the URL when dropping on the URL bar or browser page. A URL shortcut will be created when dropping on a directory or the desktop.
  TEXT_URL_LIST: "text/uri-list",
};

export const SETTING_TAB = {
  DISPLAY: 1,
  DATA: 2,
  VALIDATION: 3,
  API: 4,
  CONDITION: 5,
  EVENT: 6,
  DISPLAY_TEXT: "Display",
  DATA_TEXT: "Data",
  VALIDATION_TEXT: "Validation",
  API_TEXT: "API",
  CONDITION_TEXT: "Condition",
  EVENT_TEXT: "Event",
};

export const EVENT_SOURCE = [{ label: "OnChange", value: "onchange" }];

export const EVENT_ACTIONS = [
  { label: "Reload Datasource", value: "reloadDatasource" },
];

export const SELECT_DATASOURCE_TYPE = [
  { label: "Values", value: "values" },
  { label: "Column", value: "column" },
  { label: "Crawler", value: "crawler" },
  { label: "Dataset", value: "dataset" },
  { label: "Datastore", value: "datastore" },
  { label: "Url", value: "url" },
];
export const TAGS_INPUT_DATASOURCE_TYPE = [
  { label: "Empty", value: "Empty" },
  { label: "Values", value: "values" },
  { label: "Column", value: "column" },
  { label: "Crawler", value: "crawler" },
  { label: "Dataset", value: "dataset" },
  { label: "Datastore", value: "datastore" },
  { label: "Url", value: "url" },
];
export const SELECT_MAPPING_DATASOURCE_TYPE = [
  { label: "Values", value: "values" },
  { label: "Crawler", value: "crawler" },
  { label: "Dataset", value: "dataset" },
  { label: "Datastore", value: "datastore" },
  { label: "Url", value: "url" },
];
