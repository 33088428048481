import React from "react";

function withErrorHandling(func) {
  return function (...args) {
    try {
      return func.apply(this, args);
    } catch (error) {
      console.error(error);
      // handle the error here, e.g. show an error message to the user
    }
  };
}

export default withErrorHandling;
