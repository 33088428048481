import React, { useState, useCallback, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Card,
  Col,
  Collapse,
  Layout,
  Row,
  Space,
  Spin,
  Tabs,
  Typography,
  theme,
} from "antd";
import { EditFilled, ReloadOutlined } from "@ant-design/icons";

import DownloadButton from "../CrawlerList/widgets/DownloadButton/DownloadButton";
import { formatDate } from "../../Utilities";
import { isEmpty, uniqueId } from "lodash";
import {
  getCrawlerDetail,
  getCrawlerRunHistory,
} from "../../store/crawlerSlice";
import CrawlerProperty from "./TabItems/CrawlerProperty";
import CrawlerSchedule from "./TabItems/CrawlerSchedule";
import CrawlerRunHistory from "./TabItems/CrawlerRunHistory";
import DeleteData, { DELETE_TYPE } from "../components/DeleteData/DeleteData";
import { RouteConfigContext } from "../../context";

const { Content, Header } = Layout;

const CrawlerDetail = (props) => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { crawlerId } = useParams();
  const { customCurrentPageName, setCustomCurrentPageName } =
    useContext(RouteConfigContext);

  const {
    token: { colorBgContainer, colorFillAlter, borderRadiusLG },
  } = theme.useToken();
  const [isLoading, setIsLoading] = useState(false);
  
  const crawler = useSelector((states) => states.crawler.crawlerDetail);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getCrawlerDetail(crawlerId)).then(() => {
      setIsLoading(false);
    });
    dispatch(
      getCrawlerRunHistory({
        extract_request_id: crawlerId,
        page: 0,
        page_size: 10,
      })
    );
  }, [crawlerId, dispatch]);

  useEffect(() => {
    const customPageName = {
      ...customCurrentPageName,
      [`/crawlers/${crawlerId}`]: crawler?.name || "Crawler detail",
    };
    setCustomCurrentPageName(customPageName);
  }, [crawler]);

  return (
    <Layout>
      <Header
        style={{
          padding: 0,
          background: colorBgContainer,
        }}
      >
        <Row>
          <Col span={18}>
            <Button type="default" onClick={() => navigator("/crawlers")}>
              Back to list
            </Button>
          </Col>
          <Col span={18}>
            <Space>
              <Button type="primary" icon={<EditFilled />}></Button>
              <DeleteData type={DELETE_TYPE.CRAWLER} data={crawler} isReload />
            </Space>
          </Col>
        </Row>
      </Header>
      <Content
        style={{
          padding: 0,
          background: colorBgContainer,
        }}
      >
        {isLoading && <Spin>Loading...</Spin>}
        <Card size="small" style={{ backgroundColor: "#f8f8f8" }}>
          <Row gutter={16}>
            <Col span={18}>
              <Row gutter={16}>
                <Col span={6}>Tracking Link</Col>
                <Col span={18}>
                  <Link to={crawler?.apiLink} target="_blank">
                    {crawler?.apiLink}
                  </Link>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>Target site</Col>
                <Col span={18}>
                  <Link to={crawler?.page?.url} target="_blank">
                    {crawler?.page?.url}
                  </Link>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>Create date</Col>
                <Col span={18}>{formatDate(crawler?.createDate)}</Col>
              </Row>
            </Col>
            <Col span={6}>
              <Typography.Text>DOWNLOAD FORMAT:</Typography.Text>
              <br />
              <DownloadButton type="primary" data={crawler} />
            </Col>
          </Row>
        </Card>

        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: (
                <>
                  <Typography.Text>
                    Properties ({crawler?.page?.properties.length})
                  </Typography.Text>
                </>
              ),
              key: "1",
              children: (
                <CrawlerProperty properties={crawler?.page?.properties || []} />
              ),
            },
            // {
            //   label: "Schedule",
            //   key: "2",
            //   children: (
            //     <CrawlerSchedule
            //       crawlerId={crawlerId}
            //       schedules={crawler?.schedules || []}
            //     />
            //   ),
            // },
            {
              label: "Tracking History",
              key: "3",
              children: <CrawlerRunHistory crawlerId={crawlerId} />,
            },
          ]}
        />
      </Content>
    </Layout>
  );
};
export default CrawlerDetail;
