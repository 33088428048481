import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL, createDataset, deleteDatas, deleteDataset, getCategories, getDatasetDetail, getDatasets, getDatastoreCategories } from "../services";

export const getDatastoreList = createAsyncThunk(
  API_URL.DATASET_LIST,
  async (params) => {
    const response = await getDatasets(params);
    return response.data;
  }
);

export const getDatastoreCategoryList = createAsyncThunk(
  API_URL.DATASTORE_CATEGORY_LIST,
  async (params) => {
    const response = await getDatastoreCategories(params);
    const result = response.data?._embedded?.datastoreCategories?.map(x => ({
      label: x.category,
      value: x.category,
      item: x
    }));

    return result;
  }
)

const initialState = {
  datastoreCategories: [],
  datastoresObj: {
    list: [],
    total: 0,
    payload: {
      isOwn: true,
      page: 0,
      page_size: 10,
      keyword: "",
    },
  },
  datastoreObj: {
    list: [],
    total: 0,
    header: [],
    datastoreName: "",
    payload: {
      isOwn: true,
      page: 0,
      page_size: 10,
      keyword: "",
    },
  }
};

export const datastoreSlice = createSlice({
  name: "datastoreSlice",
  initialState,
  reducers: {
    setPayloadGetDatastoreList: (state, { payload }) => ({
      ...state,
      datastoresObj: {
        ...state.datastoresObj,
        payload: payload,
      },
    }),
  },
  extraReducers: {
    [getDatastoreList.fulfilled]: (state, { payload }) => {
      state.datasetsObj = {
        ...state.datasetsObj,
        list: payload.lstDataset,
        total: payload.total,
      };
    },
    [getDatastoreCategoryList.fulfilled]: (state, {payload}) => {
      state.datasetCategories = payload;
    },
  },
});

export const { setPayloadGetDatastoreList } = datastoreSlice.actions;

export default datastoreSlice.reducer;
