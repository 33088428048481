import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { getActions, getCategoryList } from "../../../services";
import "./index.css";

const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

const groupActions = [
  {
    key: "Load",
    open: false,
    actions: [],
  },
  {
    key: "Filter",
    open: false,
    actions: [],
  },
  {
    key: "Transform",
    open: false,
    actions: [],
  },
  {
    key: "Enrich",
    open: false,
    actions: [],
  },
  {
    key: "Export",
    open: false,
    actions: [],
  },
  {
    key: "Others",
    open: false,
    actions: [],
  },
];

const Sidebar = memo(({}) => {
  const [actions, setActions] = useState([]);
  const [groupActions, setGroupActions] = useState([]);

  useEffect(() => {
    getCategoryList().then(({ data }) => {
      if (data) {
        const optionsCategory = data.map((x) => ({
          key: x.name,
          open: false,
          actions: [],
        }));
        setGroupActions(optionsCategory);
      }
    });
  }, []);

  const groupBy = useCallback(
    (items, key) =>
      items.reduce((result, item) => {
        const group = item[key] || "Others";
        return {
          ...result,
          [group]: [...(result[group] || []), item],
        };
      }, {}),
    []
  );

  const loadActions = useCallback(async () => {
    try {
      const res = await getActions();
      const listAction = res.data.filter((x) =>
        x.applyFor.includes("PIPELINE")
      );
      const actionsGrouped = groupBy(listAction, "actionCategory");
      const result = groupActions.map((group) => ({
        ...group,
        actions: actionsGrouped[group.key] || [],
      }));
      setActions(result.filter((x) => x.actions.length > 0));
    } catch (error) {
      console.error(error);
    }
  }, [groupBy, groupActions]);

  useEffect(() => {
    if (groupActions) {
      loadActions();
    }
  }, [loadActions, groupActions]);

  const onDragStart = useCallback((event, nodeType) => {
    event.dataTransfer.setData(
      "application/reactflow",
      JSON.stringify(nodeType)
    );
    event.dataTransfer.effectAllowed = "move";
  }, []);

  const handleBacktoList = (e) => {
    window.location.href = "http://dalavi.io/#!/data/pipeline";
  };

  const renderedGroups = useMemo(() =>
    actions.map((group, index) => (
      <div key={index}>
        <div
          className="title"
          onClick={() => {
            group.open = !group.open;
            setActions([...actions]);
          }}
        >
          <div className="arrow-wrapper">
            <i
              className={
                group.open
                  ? "fa fa-angle-down fa-rotate-180"
                  : "fa fa-angle-down"
              }
            ></i>
          </div>
          <span className="title-text">{group.key}</span>
        </div>
        <div className={group.open ? "content content-open" : "content"}>
          <div
            className={
              group.open ? "content-text content-text-open" : "content-text"
            }
          >
            <div className="drag-component">
              {group.actions.map((action, index) => {
                return (
                  <div
                    className="dndnode input"
                    onDragStart={(event) => onDragStart(event, action)}
                    draggable
                    key={index}
                  >
                    {action.actionDisplayName}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    ))
  );

  return <div className="accordion">{renderedGroups}</div>;
});

export default Sidebar;
