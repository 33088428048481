import React, { useState, useEffect } from "react";
import { Checkbox, Form, Input, InputNumber, Select, Space, Tabs } from "antd";
import { EVENT_ACTIONS, EVENT_SOURCE } from "../../../formBuilderConfig";
import { isEmpty } from "lodash";

const EventSetting = ({ component }) => {
  const [event, setEvent] = useState();
  const [action, setAction] = useState();
  const [toComponent, setToComponent] = useState();
  return (
    <>
      <Form.Item label="Event" name="__event">
        <Select
          placeholder="Select..."
          showSearch
          allowClear
          optionFilterProp="children"
          options={EVENT_SOURCE}
          onChange={setEvent}
        ></Select>
      </Form.Item>

      <Form.Item label="Action" name="__eventAction">
        <Select
          placeholder="Select..."
          showSearch
          allowClear
          optionFilterProp="children"
          options={EVENT_ACTIONS}
          onChange={setAction}
        ></Select>
      </Form.Item>

      <Form.Item label="To components" name="__eventToComponent">
        <Select
          placeholder="Select..."
          showSearch
          allowClear
          mode="multiple"
          optionFilterProp="children"
          options={component.componentsOption}
          onChange={setToComponent}
        ></Select>
      </Form.Item>

      {action === "reloadDatasource" && !isEmpty(toComponent) && (
        <>
          <Form.Item
            label="Data source URL"
            name="__eventDataSourceUrl"
            style={{ marginBottom: 5 }}
          >
            <Input />
          </Form.Item>

          <Space.Compact block>
            <Form.Item
              label="Label Property"
              name={["__eventOptionSchema", "label"]}
              style={{ marginBottom: 5, width: "50%" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Value Property"
              name={["__eventOptionSchema", "value"]}
              style={{ marginBottom: 5, width: "50%" }}
            >
              <Input />
            </Form.Item>
          </Space.Compact>
        </>
      )}
    </>
  );
};
export default EventSetting;
