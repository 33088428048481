import { createSlice, current } from "@reduxjs/toolkit";
import { v4 } from "uuid";
import * as _ from "lodash";
import getExtractionHtml from "./getHtml";
import browse from "./browse";
import takeScreenshot from "./takeScreenshot";
import exportJson from "./exportJson";
import exportCsv from "./exportCsv";
import createApi from "./createApi";

const initialState = {
  status: "loading",
  initial: {
    url: "",
    file: null,
    id: null,
    type: "url", // url, file, edit
  },
  config: null,
  pages: [],
  currentPage: {
    properties: [],
    content: "",
  },
  preview: {
    json: "",
    csv: "",
    jsonLoading: false,
    csvLoading: false,
    createApiStatus: "idle", // idle, pending, done
    type: "data", // data, screenshot
  },
  mode: 2,
  error: "",
};

export const extractionSlice = createSlice({
  name: "extraction",
  initialState,
  reducers: {
    setInitial: (state, { payload }) => ({
      ...initialState,
      config: state.config,
      initial: payload,
    }),
    setStatus: (state, { payload }) => ({
      ...state,
      status: payload,
    }),
    setMode: (state, { payload }) => ({
      ...state,
      mode: payload,
    }),
    addEmptyProperty: (state) => ({
      ...state,
      currentPage: {
        ...state.currentPage,
        properties: [
          ...state.currentPage.properties,
          {
            id: v4(),
            name: "",
            values: [],
            empty: true,
          },
        ],
      },
    }),
    addProperty: (state, { payload }) => {
      const { properties } = state.currentPage;
      const index = properties.findIndex((p) => p.empty);
      state.currentPage.properties[index] = payload;
      const idx = _.findIndex(state.pages, (t) => {
        return t.id === state.currentPage.id;
      });
      if (idx !== -1) {
        state.pages[idx] = state.currentPage;
      }
    },
    removeProperty: (state, { payload }) => ({
      ...state,
      currentPage: {
        ...state.currentPage,
        properties: state.currentPage.properties.filter(
          (_, idx) => idx !== payload
        ),
      },
    }),
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    updatePages: (state, { payload }) => {
      const currentPage = _.cloneDeep(payload);
      const idx = _.findIndex(state.pages, (t) => {
        return t.id === currentPage.id;
      });
      if (idx === -1) {
        state.pages.push(currentPage);
      } else {
        state.pages[idx] = currentPage;
      }
      if (currentPage.id == state.currentPage.id) {
        state.currentPage = currentPage;
      }
    },
    setPreview: (state, { payload }) => {
      state.preview = { ...state.preview, ...payload };
    },
    setPreviewToDefault: (state) => {
      state.preview = { ...initialState.preview };
    },
    updatePreviewProperties: (state, { payload }) => {
      const { id, properties } = payload;
      const index = state.preview.pages.findIndex((p) => p.id === id);
      if (index !== -1) {
        state.preview.pages[index].properties = properties;
      }
    },
  },
  extraReducers: {
    [getExtractionHtml.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [getExtractionHtml.rejected]: (state, { error }) => ({
      ...state,
      status: "idle",
      error,
    }),

    // browse
    [browse.pending]: (state) => ({
      ...state,
      status: "loading",
    }),
    [browse.fulfilled]: (state, { payload }) => {
      state.currentPage = payload;
    },
    [browse.rejected]: (state, { meta: { arg } }) => {
      const index = _.findIndex(state.currentPage.userBrowses, (browse) => {
        return browse.id === arg.browseId;
      });
      state.currentPage.userBrowses.splice(index, 1);
      state.status = "idle";
    },

    // take screenshot
    [takeScreenshot.pending]: (state) => ({
      ...state,
      status: "loading",
    }),
    [takeScreenshot.fulfilled]: (state, { payload }) => {
      const currentPage = _.cloneDeep(state.currentPage);
      currentPage.screenshots = currentPage.screenshots || [];
      currentPage.screenshots.push(payload);
      state.currentPage = currentPage;
      const idx = _.findIndex(state.pages, (t) => {
        return t.id === currentPage.id;
      });
      if (idx !== -1) {
        state.pages[idx].screenshots = state.pages[idx].screenshots || [];
        state.pages[idx].screenshots.push(payload);
      }
      state.status = "idle";
    },
    [takeScreenshot.rejected]: (state) => {
      state.status = "idle";
    },

    // export json
    [exportJson.pending]: (state) => {
      state.preview.jsonLoading = true;
    },
    [exportJson.fulfilled]: (state, { payload }) => {
      state.preview.jsonLoading = false;
      state.preview.json = payload.data;
    },
    [exportJson.rejected]: (state) => {
      state.preview.jsonLoading = false;
    },

    // export csv
    [exportCsv.pending]: (state) => {
      state.preview.csvLoading = true;
    },
    [exportCsv.fulfilled]: (state, { payload }) => {
      state.preview.csvLoading = false;
      state.preview.csv = payload.data;
      state.preview.csvUpdated = new Date().getTime();
    },
    [exportCsv.rejected]: (state) => {
      state.preview.csvLoading = false;
    },

    // create API
    [createApi.pending]: (state) => {
      state.preview.createApiStatus = "pending";
    },
    [createApi.fulfilled]: (state, { payload }) => {
      state.preview.createApiStatus = "done";
    },
    [createApi.rejected]: (state) => {
      state.preview.createApiStatus = "idle";
    },
  },
});

export const {
  setInitial,
  setStatus,
  setMode,
  addEmptyProperty,
  addProperty,
  removeProperty,
  setCurrentPage,
  updatePages,
  setPreviewToDefault,
  setPreview,
  updatePreviewProperties,
} = extractionSlice.actions;

export default extractionSlice.reducer;
