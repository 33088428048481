import { v4 } from "uuid";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { takeScreenshot as takeScreenshotService } from "../services";

const takeScreenshot = createAsyncThunk(
  "extranction/takeScreenshot",
  async (params) => {
    const width = window.outerWidth;
    const height = window.outerHeight;
    try {
      const r = await takeScreenshotService({
        ...params,
        width,
        height,
      });
      return {
        data: r.data,
        startX: 0,
        startY: 0,
        width,
        height,
      };
    } catch (e) {
      throw e;
    }
  }
);

export default takeScreenshot;
