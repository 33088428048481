import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";

import { Button, Form, Modal, Input, notification } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { clone } from "../../store/pipelineSlice";
import withErrorHandling from "../../HOC/withErrorHandler";

const ClonePipeline = (props) => {
  const { pipeline } = props;
  const dispatch = useDispatch();
  const [pipelineLoading, setPipelineLoading] = useState(false);
  const [openClonePipeline, setOpenClonePipeline] = useState(false);

  const [form] = Form.useForm();
  const name = Form.useWatch("name", form);

  const handleOpenClonePipeline = () => {
    setOpenClonePipeline(true);
  };

  const handleCloseClonePipeline = () => {
    setOpenClonePipeline(false);
  };

  const handleClonePipeline = async () => {
    handleCloseClonePipeline();
    setPipelineLoading(true);
    const values = form.getFieldsValue();
    dispatch(
      clone({
        id: pipeline.id,
        ...values,
      })
    ).then((r) => {
      setPipelineLoading(false);
      form.setFieldsValue({ name: "", description: "" });
      if (r.error && r.error.message) {
        notification.error({
          message: r.error.message,
          description: "",
        });
      } else {
        notification.success({
          message: `Clone pipeline ${pipeline.name} success`,
          description: "",
        });
        props.onSuccess();
      }
    });
  };

  return (
    <>
      <Button
        type="primary"
        icon={<CopyOutlined />}
        onClick={handleOpenClonePipeline}
        loading={pipelineLoading}
      >
        Clone
      </Button>
      <Modal
        width={480}
        title="Clone pipeline"
        open={openClonePipeline}
        onCancel={handleCloseClonePipeline}
        onOk={handleClonePipeline}
        keyboard={false}
        maskClosable={false}
        closeIcon={false}
        okButtonProps={{
          disabled: !name || !name.replace(/\s+/, "").trim(),
        }}
      >
        <Form id="clonePipeline" form={form} layout="vertical">
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Pipeline name is requred",
              },
            ]}
            label="Name"
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default memo(ClonePipeline);
