import React, { useEffect, useState } from "react";
import { Select, Form } from "antd";
import { some } from "lodash";
import withErrorHandling from "../../../../../HOC/withErrorHandler";
import SettingButton from "../../Builder/SettingButton";
const FormSelectElement = (props) => {
  const { component, onChange, rowIndex, colIndex, index } = props;
  const { getDatasetSourceFrom } = component;

  const [options, setOptions] = useState(component.values || []);

  useEffect(() => {
    if (component.hasOptionSelectAll) {
      setOptions([
        {
          value: "all",
          label: "Select All",
        },
        ...component.values,
      ]);
    } else {
      setOptions(component.values);
    }
  }, [component]);

  const handleSelectDatasource = (value) => {
    if (value == "combine") {
      const sources = getDatasetSourceFrom
        .reduce((prev, curr) => {
          let values = curr.data.selectedOutputColumns;
          return prev.concat(values);
        }, [])
        .map((x) => ({ label: x, value: x }));

      setOptions(sources);
      return;
    }

    const selectedSource = getDatasetSourceFrom.find(
      (x) => x.idRender == value
    );

    if (selectedSource) {
      const sources = (selectedSource.data?.selectedOutputColumns || []).map(
        (x) => ({ label: x, value: x })
      );

      setOptions(sources);
      return;
    }

    setOptions([component.values]);
  };

  const handleOnChange = withErrorHandling((e) => {
    if (
      component.isMultiple &&
      e.length > 0 &&
      some(e, (x) => x.toString().includes("all"))
    ) {

      if (typeof onChange === "function") {
        onChange(
          options.map((x) => x.value).filter((x) => x !== "all"),
          { itemName: component.apiProperty, needUpdateForm: true, component }
        );
      }
      return;
    }

    if (typeof onChange === "function") {
      onChange(e, { component });
    }
  });

  return (
    <>
      {getDatasetSourceFrom && getDatasetSourceFrom.length > 0 && (
        <Form.Item name="dataSourceSelected">
          <Select
            placeholder="Select Column Datasource"
            onChange={handleSelectDatasource}
          >
            <Select.Option value="combine">
              Combine All Previous Pipe
            </Select.Option>
            {getDatasetSourceFrom?.map((source) => (
              <Select.Option value={source.idRender} key={source.idRender}>
                {source.data.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        key={component.id}
        label={component.isHideLabel ? "" : component.label}
        name={component.apiProperty}
        tooltip={component.tooltip}
        rules={[
          {
            required: component.isRequired,
            message: component.requiredMessage || "Required field",
          },
        ]}
      >
        <Select
          onChange={handleOnChange}
          placeholder={component.placeholder || "Select..."}
          showSearch
          mode={component.isMultiple ? "multiple" : ""}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={options}
        ></Select>
      </Form.Item>
      <SettingButton
        direction="horizontal"
        position="topRight"
        rowIndex={rowIndex}
        colIndex={colIndex}
        component={component}
        index={index}
      />
    </>
  );
};
export default FormSelectElement;
