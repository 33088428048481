import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import {
  Layout,
  Row,
  Form,
  Col,
  Space,
  Input,
  Button,
  Spin,
  Tree,
  List,
  Modal,
  notification,
  Alert,
} from "antd";
import {
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  UnorderedListOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import ReactJson from "react-json-view";
import * as _ from "lodash";
import { setPreviewToDefault } from "../slices";
import Menu from "../HomePage/Menu";
import * as utils from "./../utils";
import exportJson from "../slices/exportJson";
import exportCsv from "../slices/exportCsv";
import createApi from "../slices/createApi";
import { updatePreviewProperties } from "../slices";

import "./style.css";

const Preview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const apiName = Form.useWatch("name", form);

  const [pForm] = Form.useForm();
  const pName = Form.useWatch("name", pForm);
  const pValue = Form.useWatch("value", pForm);

  const preview = useSelector((states) => states.extraction.preview);
  const [selectedNode, setSelectedNode] = React.useState([]);
  const [properties, setProperties] = React.useState([]);
  const [openAddProperty, setOpenAddProperty] = React.useState(false);
  const [deleteProperty, setDeleteProperty] = React.useState(null);
  const [editProperty, setEditProperty] = React.useState({
    index: -1,
    name: "",
  });

  useEffect(() => {
    if (!preview || !preview.pages) {
      navigate("/");
      return;
    }
    setSelectedNode([preview.pages[0].id]);

    return () => {
      dispatch(setPreviewToDefault({}));
    };
  }, []);

  useEffect(() => {
    if (preview.csv && preview.csv.length) {
      utils.download(preview.csv);
    }
  }, [preview.csvUpdated]);

  useEffect(() => {
    if (preview.createApiStatus === "done") {
      navigate("/crawlers");
      dispatch(setPreviewToDefault());
    }
  }, [preview.createApiStatus]);

  useEffect(() => {
    if (selectedNode.length) {
      const page = _.find(preview.pages, (page) => page.id === selectedNode[0]);
      setProperties(page.properties);
    }
  }, [selectedNode]);

  const handleSaveAPI = (values) => {
    const params = utils.buildPage(preview.pages);
    params.name = values.name;
    if (preview.type === "screenshot") {
      params.isScreenshot = 1;
    }
    dispatch(createApi(params));
  };

  const handleExportJson = () => {
    dispatch(exportJson(utils.buildPage(preview.pages)));
  };

  const handleExportCSV = () => {
    dispatch(exportCsv(utils.buildPage(preview.pages)));
  };

  const handleUpdatePreviewProperties = (properties) => {
    dispatch(
      updatePreviewProperties({
        id: selectedNode[0],
        properties,
      })
    );
  };

  const handleCloseProperty = () => {
    setOpenAddProperty(false);
    pForm.setFieldsValue({ name: "", value: "" });
  };

  const handleAddProperty = () => {
    const name = pForm
      .getFieldValue("name")
      .trim()
      .replace(/(\s|\W)+/g, "_");
    const value = pForm.getFieldValue("value");
    const existed = _.find(properties, (p) => p.name === name);
    if (existed) {
      notification.error({
        message: "Duplicate property",
        description: `The property with name ${name} was exists, please select another.`,
        duration: 2.5,
      });
      return;
    }

    const newProperties = [
      ...properties,
      {
        id: v4(),
        name,
        type: "constant",
        text: value,
        value,
        values: [
          {
            id: v4(),
            value: value,
          },
        ],
      },
    ];

    setProperties(newProperties);
    handleUpdatePreviewProperties(newProperties);
    handleCloseProperty();
  };

  const handleEditPropertyChange = (e) => {
    setEditProperty({
      ...editProperty,
      name: e.target.value,
    });
  };

  const handleEditProperty = () => {
    const { index, name } = editProperty;
    const newProperties = [].concat(properties);
    newProperties[index] = { ...newProperties[index], name };
    handleUpdatePreviewProperties(newProperties);
    setProperties(newProperties);
    setEditProperty({ index: -1, name: "" });
  };

  const handleCloseDeleteProperty = () => {
    setDeleteProperty(null);
  };

  const handleDeleteProperty = () => {
    const newProperties = [].concat(properties);
    _.remove(newProperties, (p) => p.id === deleteProperty);
    setProperties(newProperties);
    handleUpdatePreviewProperties(newProperties);
    handleCloseDeleteProperty();
  };

  const count = _.sumBy(preview.pages, (p) => p.properties.length);

  const treeData = utils.buildTree(preview.pages);
  const listProps = {
    locale: {
      emptyText: "No properties",
    },
    size: "small",
    bordered: true,
    dataSource: properties,
    renderItem: (item, index) => (
      <List.Item>
        <div>
          <UnorderedListOutlined />
        </div>
        {editProperty.index !== index ? (
          <>
            <div>{item.name}</div>
            <div className="action">
              <Button
                type="dashed"
                size="small"
                icon={<EditOutlined />}
                onClick={() => setEditProperty({ name: item.name, index })}
              >
                Rename
              </Button>
              <Button
                type="dashed"
                size="small"
                danger
                icon={<DeleteOutlined />}
                onClick={() => setDeleteProperty(item.id)}
              >
                Delete
              </Button>
            </div>
          </>
        ) : (
          <>
            <div>
              <Input
                value={editProperty.name}
                onChange={handleEditPropertyChange}
              />
            </div>
            <div className="action">
              <Button
                type="primary"
                size="small"
                icon={<CheckCircleOutlined />}
                onClick={handleEditProperty}
                disabled={
                  !editProperty.name ||
                  !editProperty.name.replace(/\s+/, "").trim()
                }
              >
                Rename
              </Button>
              <Button
                type="dashed"
                size="small"
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => setEditProperty({ name: "", index: -1 })}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </List.Item>
    ),
  };

  return (
    <Layout id="extraction" className="extraction-preview">
      <Layout.Header>
        <Space>
          <a href="/" style={{ display: "inline-flex" }}>
            <img
              style={{ width: 40, marginRight: "1em" }}
              src="/assets/images/LOGO_2.png"
            />
          </a>
        </Space>
        <Space>
          <h3>Dalavi</h3>
        </Space>
        <Space className="right">
          <Space className="menu">
            <Menu />
          </Space>
        </Space>
      </Layout.Header>
      <Layout.Content>
        <Row>
          <Col span={18} offset={3}>
            <Layout className="preview-tracking-content">
              <Layout.Header className="header-tabs">
                <Row>
                  <Col span={24}>
                    <strong>Crawler</strong>
                  </Col>
                </Row>
              </Layout.Header>
              <Layout.Content>
                <Row>
                  <Col span={24}>
                    <Form
                      form={form}
                      name="apiForm"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={handleSaveAPI}
                    >
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Crawler is required.",
                          },
                        ]}
                        label="Crawler"
                        disabled={preview.createApiStatus === "pending"}
                      >
                        <Input
                          suffix={
                            <Button
                              htmlType="submit"
                              type="success"
                              icon={
                                preview.createApiStatus === "pending" ? (
                                  <Spin />
                                ) : null
                              }
                              disabled={
                                !apiName ||
                                preview.createApiStatus === "pending"
                              }
                            >
                              Save
                            </Button>
                          }
                        />
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
                {preview.type === "data" ? (
                  <>
                    <Row>
                      <Col span={12}>{count} properties</Col>
                      <Col span={12}>
                        <Button
                          type="primary"
                          htmlType="button"
                          style={{ float: "right" }}
                          disabled={preview.csvLoading}
                          icon={preview.csvLoading ? <Spin /> : null}
                          onClick={handleExportCSV}
                        >
                          Download CSV
                        </Button>
                        <Button
                          type="primary"
                          htmlType="button"
                          style={{ float: "right", marginRight: "1em" }}
                          disabled={preview.jsonLoading}
                          icon={preview.jsonLoading ? <Spin /> : null}
                          onClick={handleExportJson}
                        >
                          View JSON
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12} className="extraction-preview-tree">
                        <Tree
                          showLine
                          switcherIcon={<DownOutlined />}
                          treeData={treeData}
                          fieldNames={{
                            children: "userBrowses",
                            title: "url",
                            key: "key",
                          }}
                          selectedKeys={selectedNode}
                          onSelect={setSelectedNode}
                          multiple={false}
                        />
                      </Col>
                      <Col span={12} className="extraction-preview-list">
                        <List {...listProps} />
                        <Button
                          type="primary"
                          style={{ marginTop: "1em" }}
                          onClick={() => setOpenAddProperty(true)}
                        >
                          Add property
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row>
                    <Col
                      span={24}
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: 4,
                      }}
                    >
                      <img
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                        src={`data:image/png;base64,${preview.screenshot}`}
                      />
                    </Col>
                  </Row>
                )}
                {preview.json ? (
                  <Row>
                    <Col span={24} className="extraction-preview-json-view">
                      <ReactJson src={preview.json || {}} name={null} />
                    </Col>
                  </Row>
                ) : null}
              </Layout.Content>
            </Layout>
          </Col>
        </Row>

        <Modal
          width={480}
          title="Create new property"
          open={openAddProperty}
          onCancel={handleCloseProperty}
          onOk={handleAddProperty}
          keyboard={false}
          maskClosable={false}
          closeIcon={false}
          okButtonProps={{
            disabled: !pName || !pName.replace(/\s+/, "").trim() || !pValue,
          }}
        >
          <Form id="addProperty" form={pForm} layout="vertical">
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter property name",
                },
              ]}
              label="Property"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="value"
              rules={[
                {
                  required: true,
                  message: "Please enter property value",
                },
              ]}
              label="Value"
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          width={480}
          title="Delete property"
          open={Boolean(deleteProperty)}
          onCancel={handleCloseDeleteProperty}
          onOk={handleDeleteProperty}
        >
          <Alert
            message="Are you sure you want to delete this property?"
            type="warning"
          />
        </Modal>
      </Layout.Content>
      <Layout.Footer>© COPYRIGHT 2015</Layout.Footer>
    </Layout>
  );
};

export default Preview;
