import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
  useRef,
  memo,
} from "react";
import { useSelector } from "react-redux";

import {
  Button,
  Space,
  Drawer,
  Form,
  Select,
  Row,
  Col,
  Spin,
  Input,
  Typography,
  Card,
  Tooltip,
} from "antd";
import {
  CommentOutlined,
  DownloadOutlined,
  EllipsisOutlined,
  HeartOutlined,
  LikeOutlined,
  MailOutlined,
  MobileOutlined,
  ShareAltOutlined,
  StarOutlined,
  WarningOutlined,
  SettingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  FormSelectElement,
  FormCheckboxElement,
  FormTextElement,
  FileUploadElement,
  TagsInputElement,
  DatasourceMapping,
  RenameMultipleColumns,
} from "../DynamicComponent";
import { PipelineContext } from "../../../../context";
import withErrorHandling from "../../../../HOC/withErrorHandler";
import { tryParse } from "../../../../Utilities";
import { clone, isEmpty, uniqueId } from "lodash";
import { postToAction } from "../../../../services";
import SettingButton from "../Builder/SettingButton";
import { FormDynamicBuilderContext } from "../../../../context/FormDynamicContext";
import RadioElement from "../DynamicComponent/RadioElement/RadioElement";

const FormDynamicRender = ({
  actionData,
  onElementChange,
  getFormParams,
  isLoading,
  onDragOver,
  onDragEnter,
  onDragLeave,
  onDrop,
}) => {
  const formDynamicBuilderContext = useContext(FormDynamicBuilderContext);
  const { editable } = formDynamicBuilderContext || {};

  const [action, setAction] = useState([]);

  useEffect(() => {
    if (isLoading) return;

    setAction(actionData);
  }, [actionData, isLoading]);

  const handleElementChange = (e, option) => {
    buildThenRunComponentEvent(e, option?.component);
    if (typeof onElementChange === "function") {
      onElementChange(e, option);
    }
  };

  const buildThenRunComponentEvent = withErrorHandling(
    async (value, component) => {
      let actionCloned = clone(action);
      let datas = [];

      if (component.__eventAction == "reloadDatasource") {
        if (
          ["dataset", "datastore", "crawler"].includes(component.dataSouceType)
        ) {
          const selectedSource = component.values.find(
            (x) => x.value === value
          );
          datas = [...selectedSource?.columns]
            .filter((x) => !isEmpty(x))
            .map(function (x) {
              return { value: x, label: x };
            });
        } else {
          const params = getFormParams(null, component, "key", "value");
          const response = await postToAction(
            component.__eventDataSourceUrl,
            params
          );

          if (typeof response.data[0] == "string") {
            datas = (response.data || []).map(function (x) {
              return { value: x, label: x };
            });
          } else {
            datas = (response.data || []).map(function (x) {
              return {
                value: x[component.__eventOptionSchema.value],
                label: x[component.__eventOptionSchema.label],
              };
            });
          }
        }

        // fill one datasource
        // actionCloned[component.__eventToComponent.rowIndex].columns[
        //   component.__eventToComponent.columnIndex
        // ].components[component.__eventToComponent.cIndex].values = datas;

        // fill multi datasource
        const componentsFiltered = [].concat
          .apply(
            [],
            [].concat
              .apply(
                [],
                actionCloned.map(function (x) {
                  return x.columns;
                })
              )
              .map(function (x) {
                return x.components;
              })
          )
          .filter((x) => component.__eventToComponent.includes(x.apiProperty));
        componentsFiltered.forEach((x) => (x.values = clone(datas)));
        setAction([...actionCloned]);
      }
    }
  );

  const handleDragOver = (event) => {
    onDragOver instanceof Function && onDragOver(event);
  };
  const handleDragEnter = (event) => {
    onDragEnter instanceof Function && onDragEnter(event);
  };
  const handleDragLeave = (event) => {
    onDragLeave instanceof Function && onDragLeave(event);
  };
  const handleDrop = (event) => {
    onDrop instanceof Function && onDrop(event);
  };

  if (isLoading) return <Spin>Loading</Spin>;
  if (isEmpty(action))
    return (
      <Card
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        Nothing to show!
      </Card>
    );

  return action.map((row, rowIndex) => (
    <Row
      style={{
        width: "100%",
        position: "relative",
        padding: editable ? "5px 30px" : "",
      }}
      gutter={[8, 8]}
      key={row.id}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {editable && (
        <SettingButton
          direction="vertical"
          position="leftCenter"
          rowIndex={rowIndex}
          component={row}
          index={rowIndex}
        />
      )}
      {row.columns.map((column, colIndex) => (
        <Col span={column.column * 2} key={column.id}>
          <Card.Grid
            style={{
              width: "100%",
              position: "relative",
              padding: editable ? "5px 30px" : "",
            }}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            drop-row-index={rowIndex}
            drop-column-index={colIndex}
          >
            {editable && isEmpty(column.components) && (
              <Typography.Title
                level={5}
                style={{
                  textAlign: "center",
                  fontWeight: "initial",
                  margin: 0,
                }}
              >
                Column container
              </Typography.Title>
            )}
            {column.components.map((component, index) => {
              switch (component.type) {
                case "text":
                  return (
                    <>
                      <FormTextElement
                        component={component}
                        key={component.id}
                        rowIndex={rowIndex}
                        colIndex={colIndex}
                        index={index}
                      />
                    </>
                  );
                case "radio":
                  return (
                    <>
                      <RadioElement
                        component={component}
                        key={component.id}
                        rowIndex={rowIndex}
                        colIndex={colIndex}
                        index={index}
                      />
                    </>
                  );
                case "checkbox":
                  return (
                    <div key={uniqueId()}>
                      <FormCheckboxElement
                        component={component}
                        key={component.id}
                        rowIndex={rowIndex}
                        colIndex={colIndex}
                        index={index}
                      />
                    </div>
                  );
                case "select":
                  return (
                    <div key={uniqueId()}>
                      <FormSelectElement
                        component={component}
                        key={component.id}
                        onChange={handleElementChange}
                        rowIndex={rowIndex}
                        colIndex={colIndex}
                        index={index}
                      />
                    </div>
                  );
                case "tagsinput":
                  return (
                    <div key={uniqueId()}>
                      <TagsInputElement
                        component={component}
                        key={component.id}
                        rowIndex={rowIndex}
                        colIndex={colIndex}
                        index={index}
                      />
                    </div>
                  );
                case "fileUpload":
                  return (
                    <div key={uniqueId()}>
                      <FileUploadElement
                        component={component}
                        key={component.id}
                        rowIndex={rowIndex}
                        colIndex={colIndex}
                        index={index}
                      />
                    </div>
                  );
                case "dataSourceMapping":
                  return (
                    <div key={uniqueId()}>
                      <DatasourceMapping
                        component={component}
                        key={component.id}
                        onChange={handleElementChange}
                        rowIndex={rowIndex}
                        colIndex={colIndex}
                        index={index}
                      />
                    </div>
                  );
                case "renameMultipleColumns":
                  return (
                    <div key={uniqueId()}>
                      <RenameMultipleColumns
                        component={component}
                        key={component.id}
                        onChange={handleElementChange}
                        rowIndex={rowIndex}
                        colIndex={colIndex}
                        index={index}
                      />
                    </div>
                  );
              }
            })}
          </Card.Grid>
        </Col>
      ))}
    </Row>
  ));
};
export default memo(FormDynamicRender);
