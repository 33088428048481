import React, { useEffect } from "react";
import { Form, Input, Button, notification } from "antd";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import { forgotPassword, resetPassword } from "../../store/authSlice";
import usePrevious from "../../Utilities/usePrevious";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const authState = useSelector((states) => states.auth);

  const { error, status, step } = authState.forgotPassword;

  const prevStep = usePrevious(step);

  const loading = status === "pending";

  const handleForgotPassword = (values) => {
    dispatch(forgotPassword(values));
  };

  const handleResetPassword = (values) => {
    dispatch(resetPassword(values));
  };

  useEffect(() => {
    if (status === "error" && error?.message) {
      notification.error({
        message: "Error",
        description: error.message,
        duration: 3,
      });
    }

    if (status === "success") {
      if (prevStep === "forgot") {
        notification.success({
          message: "Success",
          description:
            "A forgot password code was sent to your email. Please check your email.",
          duration: 3,
        });
      }

      if (prevStep === "reset") {
        notification.success({
          message: "Success",
          description: "Reset password sucessfully. Please login.",
          duration: 3,
        });
      }
    }
  }, [status]);

  return step === "forgot" ? (
    <Form
      name="forgotPasswordForm"
      initialValues={{
        remember: true,
      }}
      onFinish={handleForgotPassword}
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your email.",
          },
        ]}
      >
        <Input
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>

      <Form.Item className="Forgot-footer">
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  ) : step === "reset" ? (
    <Form
      name="resetPasswordForm"
      initialValues={{
        remember: true,
      }}
      onFinish={handleResetPassword}
    >
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your password." }]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="New password"
        />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        rules={[
          { required: true, message: "Please confirm your password." },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The new password that you entered do not match.")
              );
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Confirm new password"
        />
      </Form.Item>

      <Form.Item
        name="code"
        rules={[{ required: true, message: "Please input your code." }]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Code"
        />
      </Form.Item>

      <Form.Item className="Forgot-footer">
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  ) : null;
};

export default ForgotPassword;
