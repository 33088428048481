import React, { lazy } from "react";
import { Button, Checkbox, Col, Input, Row, Select, Space } from "antd";
import {
  ReloadOutlined,
  PlusOutlined,
  RollbackOutlined,
} from "@ant-design/icons";

const TableToolBar = ({
  onChangeShared,
  onSearch,
  onReload,
  onClearFilter,
  keyword,
  isShared,
}) => {
  const [value, setValue] = React.useState("");
  const [shared, setShared] = React.useState(false);

  React.useEffect(() => {
    setValue(keyword);
  }, [keyword]);

  React.useEffect(() => {
    setShared(isShared);
  }, [isShared]);

  const handleSearchChange = (e) => {
    setValue(e.target.value);
  };

  const handleOnSearch = (e) => {
    onSearch instanceof Function && onSearch(e);
  };

  const handleGetShared = (e) => {
    onChangeShared instanceof Function && onChangeShared(e);
  };

  const handleReloadDataSource = () => {
    onReload instanceof Function && onReload();
  };
  const handleClearFilter = () => {
    onClearFilter instanceof Function && onClearFilter();
  };

  return (
    <Row gutter={12}>
      <Col xs={24} md={20}>
        <Space
          size={"small"}
          align="center"
          justify="start"
          style={{ width: "100%" }}
        >
          <Input.Search
            placeholder="Keyword"
            value={value}
            onChange={handleSearchChange}
            onSearch={handleOnSearch}
          />
          <Button type="default" key="clear" onClick={handleClearFilter}>
            Clear Filter
          </Button>
        </Space>
      </Col>
      <Col xs={24} md={4}>
        <Space
          size={"small"}
          align="center"
          justify="end"
          style={{ width: "100%" }}
        >
          <Button
            type="text"
            icon={<ReloadOutlined />}
            onClick={handleReloadDataSource}
          >
            Reload
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default TableToolBar;
