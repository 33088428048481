import { v4 } from "uuid";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { browse as browseService } from "../services";

const browse = createAsyncThunk("extranction/browse", async (params) => {
  try {
    const r = await browseService({
      ...params.data,
      width: window.outerWidth,
      height: window.outerHeight,
    });
    const page = {
      id: v4(),
      browseId: params.browseId,
      content: r.data.content,
      url: r.data.url,
      screenId: r.data.screenId,
      userBrowses: [],
      properties: [],
      page: {
        from: 1,
        to: 1,
      },
    };
    return page;
  } catch (e) {
    throw e;
  }
});

export default browse;
