import React, { memo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, notification } from "antd";
import { PlayCircleFilled } from "@ant-design/icons";
import { isEmpty } from "lodash";
import withErrorHandling from "../../../HOC/withErrorHandler";
import { run } from "../../../store/pipelineSlice";

const RunPipeline = (props) => {
  const { btnText, pipeline } = props;
  const dispatch = useDispatch();
  const [pipelineLoading, setPipelineLoading] = useState(false);

  const handleRunPipeline = withErrorHandling((e) => {
    setPipelineLoading(true);
    dispatch(run(pipeline.id))
      .then((res) => {
        setPipelineLoading(false);

        notification.success({
          message: `Pipeline ${pipeline.name} are running`,
          description: "",
        });
      })
      .catch((err) => {
        setPipelineLoading(false);

        notification.success({
          message: `Pipeline ${pipeline.name} run failed`,
          description: "",
        });
      });
  });

  return (
    <Button
      type="primary"
      icon={<PlayCircleFilled />}
      onClick={handleRunPipeline}
      disabled={isEmpty(pipeline)}
      loading={pipelineLoading}
    >
      {btnText || "Run Pipeline"}
    </Button>
  );
};

export default memo(RunPipeline);
