import React, { useCallback, useContext } from "react";
import {
  useStore,
  getBezierPath,
  getEdgeCenter,
  applyEdgeChanges,
} from "react-flow-renderer";
import classNames from "classnames";
import { FaRegTrashAlt } from "react-icons/fa";
import { getEdgeParams } from "./utils.js";
import { PipelineContext } from "../../../context/PipelineContext";
import "./FloatEdge.css";

const foreignObjectSize = 40;

function FloatingEdge({ id, source, target, markerEnd, style }) {
  const { edges, setEdges } = useContext(PipelineContext);
  const store = useStore(useCallback((store) => store));
  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode
  );

  const edgePath = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty,
  });

  const onDeleteEdge = (evt, id) => {
    setEdges(edges.filter((x) => x.id !== id));
  };

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <main>
          <button
            className={classNames("edgebutton", {
              hidden: sourceNode.data.status === "COMPLETED",
            })}
            onClick={(event) => onDeleteEdge(event, id)}
          >
            <FaRegTrashAlt />
          </button>
        </main>
      </foreignObject>
    </>
  );
}

export default FloatingEdge;
