import React from "react";
import { SELECT_DATASOURCE_TYPE, SETTING_TAB } from "../../formBuilderConfig";
import {
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tabs,
} from "antd";
import DisplaySetting from "./Tabs/DisplaySetting";
import DataSetting from "./Tabs/DataSetting";
import ValidationSetting from "./Tabs/ValidationSetting";
import ApiSetting from "./Tabs/ApiSetting";
import ConditionSetting from "./Tabs/ConditionSetting";

const DatasourceMappingSetting = ({ component, formSetting }) => {
  const items = [
    {
      key: SETTING_TAB.DISPLAY,
      label: SETTING_TAB.DISPLAY_TEXT,
      children: (
        <DisplaySetting component={component}>
          <Space.Compact block>
            <Form.Item
              label="From Data source Label"
              name="fromDataSourceTypeLabel"
              style={{ marginBottom: 10, width: "80%" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label=" "
              name="isHideFromDataSourceTypeLabel"
              style={{ marginBottom: 10, width: "20%" }}
              valuePropName="checked"
            >
              <Checkbox>Hide Label</Checkbox>
            </Form.Item>
          </Space.Compact>

          <Space.Compact block>
            <Form.Item
              label="To Data source Label"
              name="toDataSourceTypeLabel"
              style={{ marginBottom: 10, width: "80%" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label=" "
              name="isHideToDataSourceTypeLabel"
              style={{ marginBottom: 10, width: "20%" }}
              valuePropName="checked"
            >
              <Checkbox>Hide Label</Checkbox>
            </Form.Item>
          </Space.Compact>
        </DisplaySetting>
      ),
    },
    {
      key: SETTING_TAB.DATA,
      label: SETTING_TAB.DATA_TEXT,
      children: (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Data Source Type" name="fromDataSourceType">
              <Select
                placeholder="Select..."
                showSearch
                optionFilterProp="children"
                options={SELECT_DATASOURCE_TYPE}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Data Source Type" name="toDataSourceType">
              <Select
                placeholder="Select..."
                showSearch
                optionFilterProp="children"
                options={SELECT_DATASOURCE_TYPE}
              ></Select>
            </Form.Item>
          </Col>

          {/* <Col span={24}>
            {dataSouceTypeSelected === "values" && <OptionList />}
          </Col> */}
        </Row>
      ),
    },
    {
      key: SETTING_TAB.VALIDATION,
      label: SETTING_TAB.VALIDATION_TEXT,
      children: <ValidationSetting component={component} />,
    },
    {
      key: SETTING_TAB.API,
      label: SETTING_TAB.API_TEXT,
      children: <ApiSetting component={component} />,
    },
    // {
    //   key: SETTING_TAB.CONDITION,
    //   label: SETTING_TAB.CONDITION_TEXT,
    //   children: <ConditionSetting component={component} />,
    // },
  ];

  return <Tabs defaultActiveKey={SETTING_TAB.DISPLAY} items={items} />;
};
export default DatasourceMappingSetting;
