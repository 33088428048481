import { clone, isEmpty, pick, remove } from "lodash";
import withErrorHandling from "../../HOC/withErrorHandler";

const fields = [
  "id",
  "path",
  "pathInfer",
  "name",
  "transformers",
  "value",
  "type",
  "sampleData",
  "anchor",
  "tagAttributes",
  "tagName",
  "headers",
];

export const validateExtraction = withErrorHandling(
  (data, keepId, checkValidId) => {
    keepId = !!keepId;
    let page = clone(data);

    if (!keepId || (checkValidId && isNaN(page.id))) {
      delete page.id;
    }
    delete page.browseId;
    delete page.selectedProperty;

    if (!isEmpty(page.page)) {
      const pagination = {
        fromPage: page.page.from,
        toPage: page.page.to,
      };
      page.pagination = pagination;
    }
    page.pagination = buildPagination(page, keepId, checkValidId);
    delete page.page;

    page.properties = buildPageProperty(page, keepId, checkValidId);

    if (isEmpty(page.userBrowses)) {
      page.userBrowses = [];
      return page;
    }

    if (!isEmpty(page.userBrowses)) {
      for (const i = 0; i < page.userBrowses.length; i++) {
        delete page.userBrowses[i].id;
        if (page.userBrowses[i].pageTo) {
          validateExtraction(page.userBrowses[i].pageTo);
        }
      }
    }

    return page;
  }
);

export const buildPagination = (data, keepId, checkValidId) => {
  const page = clone(data);

  if (page.pagination) {
    let pagination = {
      fromPage: page.pagination.fromPage,
      toPage: page.pagination.toPage,
      id: page.pagination.id,
    };

    if (!keepId || (checkValidId && isNaN(page.id))) {
      delete pagination.id;
    }
    return pagination;
  }
};
export const buildPageProperty = (data, keepId, checkValidId) => {
  let page = clone(data);
  let properties = [];
  if (isEmpty(page.properties)) return clone(page.properties);
  for (var i = 0; i < page.properties.length; i++) {
    let property = {};
    let fieldsCloned = clone(fields);
    if (!keepId) {
      fieldsCloned.shift();
    }
    if (!isEmpty(page.properties[i].values)) {
      // page.properties[i].sampleData = clone(page.properties[i].values[0].value);
      property = clone(page.properties[i].values[0].value);
    }
    // page.properties[i] = clone(pick(page.properties[i], fields));
    property = clone(pick(page.properties[i], fieldsCloned));

    if (checkValidId && isNaN(page.properties[i].id)) {
      delete page.properties[i].id;
    }
    if (!isEmpty(page.properties[i].transformers)) {
      // page.properties[i].transformers = page.properties[i].transformers.map(
      property.transformers = page.properties[i].transformers.map((trans) => {
        return {
          name: trans.value,
          displayName: trans.name,
          params: trans.transformerParams?.map((param) => {
            return {
              paramName: param.paramName,
              paramDisplayName: param.paramNameDisplay,
              paramValue: param.paramValue,
            };
          }),
          position: trans.position,
        };
      });
    }
    if (!page.properties[i].transformers) {
      property.transformers = [];
    }

    properties.push(property);
  }
  remove(properties, (p) => !p.name);
  return clone(properties);
};
