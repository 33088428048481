import React from "react";
import { SETTING_TAB } from "../../formBuilderConfig";
import { Checkbox, Form, Input, InputNumber, Radio, Space, Tabs } from "antd";
import DisplaySetting from "./Tabs/DisplaySetting";
import DataSetting from "./Tabs/DataSetting";
import ValidationSetting from "./Tabs/ValidationSetting";
import ApiSetting from "./Tabs/ApiSetting";
import ConditionSetting from "./Tabs/ConditionSetting";
import OptionList from "./Tabs/OptionList";

const FileUploadSetting = ({ component, formSetting }) => {
  const items = [
    {
      key: SETTING_TAB.DISPLAY,
      label: SETTING_TAB.DISPLAY_TEXT,
      children: <DisplaySetting component={component} />,
    },
    {
      key: SETTING_TAB.DATA,
      label: SETTING_TAB.DATA_TEXT,
      children: (
        <DataSetting component={component}>
          <Form.Item
            name="isMultiple"
            style={{ marginBottom: 5 }}
            valuePropName="checked"
          >
            <Checkbox>Multiple</Checkbox>
          </Form.Item>
        </DataSetting>
      ),
    },
    {
      key: SETTING_TAB.VALIDATION,
      label: SETTING_TAB.VALIDATION_TEXT,
      children: <ValidationSetting component={component} />,
    },
    {
      key: SETTING_TAB.API,
      label: SETTING_TAB.API_TEXT,
      children: <ApiSetting component={component} />,
    },
    // {
    //   key: SETTING_TAB.CONDITION,
    //   label: SETTING_TAB.CONDITION_TEXT,
    //   children: <ConditionSetting component={component} />,
    // },
  ];

  return <Tabs defaultActiveKey={SETTING_TAB.DISPLAY} items={items} />;
};
export default FileUploadSetting;
