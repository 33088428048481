import React from "react";
import { Tooltip, Slider } from "antd";
import {
  QuestionCircleOutlined,
  SyncOutlined,
  CameraOutlined,
} from "@ant-design/icons";

import { useSelector, useDispatch } from "react-redux";

import { setMode } from "./../slices";

const SwitchMode = () => {
  const dispatch = useDispatch();
  const mode = useSelector((states) => states.extraction.mode);

  const onChange = (v) => {
    dispatch(setMode(v));
  };

  const modes = {
    1: "Browsing Mode",
    2: "Traking Mode",
    // 3: "Paging Mode",
    3: "Scrolling Mode",
  };

  const tooltipModes = {
    1: "Browsing mode is used to browse the site similar to browser",
    2: "Tracking mode is used to select the tracking text, image, link",
    // 3: "Pagination mode is used to track the content in multiple pages",
    3: "Scrolling mode is used to track the content which required to scroll down to load more items",
  };

  const handleScrolling = () => {
    window.postMessage("extraction_scrolling");
  };

  const handleTakeScreenshot = () => {
    window.postMessage("extraction_take_screenshot");
  };

  return (
    <>
      <div>
        <Tooltip placement="bottom" title={tooltipModes[mode]}>
          <QuestionCircleOutlined style={{ color: "#e9a632" }} />
        </Tooltip>
      </div>
      <div>
        <strong>{modes[mode]}</strong>
      </div>
      <div>
        <Slider
          min={1}
          style={{ width: 35 }}
          max={3}
          value={mode}
          onChange={onChange}
          tooltip={{
            open: false,
          }}
        />
      </div>
      {mode === 3 ? (
        <div>
          <Tooltip placement="bottom" title="Click here to scoll">
            <SyncOutlined onClick={handleScrolling} />
          </Tooltip>
        </div>
      ) : null}
      {/* <div>
        <Tooltip placement="bottom" title="Take screenshot">
          <CameraOutlined onClick={handleTakeScreenshot} />
        </Tooltip>
      </div> */}
    </>
  );
};

export default SwitchMode;
