import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Space,
  Table,
  Tabs,
  Typography,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const OptionList = ({ component }) => {
  return (
    <Card title="Values">
      <Form.List name="values">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Space.Compact block key={field.key} align="baseline">
                <Form.Item
                  {...field}
                  name={[field.name, "label"]}
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                  style={{ width: "50%", marginBottom: 5 }}
                >
                  <Input placeholder="Enter Label" />
                </Form.Item>

                <Form.Item
                  {...field}
                  name={[field.name, "value"]}
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                  style={{ width: "50%", marginBottom: 5 }}
                >
                  <Input placeholder="Enter Value" />
                </Form.Item>
                <Button type="ghost" icon={<MinusCircleOutlined />} onClick={() => remove(field.name)}></Button>
              </Space.Compact>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  var seq = fields.length;

                  if (seq > 0) {
                    seq = fields[seq - 1].order + 1;
                  }
                  add({
                    label: "",
                    value: "",
                    order: seq,
                  });
                }}
                block
                icon={<PlusOutlined />}
              >
                Add Value
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Card>
  );
};

export default OptionList;
