import { Spin } from "antd";
import React from "react";
import ReactJson from "react-json-view";

const baseConfig = {
  theme: "apathy:inverted",
  indentWidth: 2,
  displayDataTypes: false,
  name: false
};

const JsonPrettyViewer = ({ config, json, loading }) => {
  const viewerConfig = {
    ...baseConfig,
    config,
  };

  if (loading)
    return (
      <>
        <Spin>Loading</Spin>
      </>
    );

  return <ReactJson src={json} {...viewerConfig} />;
};

export default JsonPrettyViewer;
