import React, { useState } from "react";
import { SETTING_TAB } from "../../formBuilderConfig";
import {
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Tabs,
} from "antd";
import DisplaySetting from "./Tabs/DisplaySetting";
import DataSetting from "./Tabs/DataSetting";
import ValidationSetting from "./Tabs/ValidationSetting";
import ApiSetting from "./Tabs/ApiSetting";
import ConditionSetting from "./Tabs/ConditionSetting";
import OptionList from "./Tabs/OptionList";
import { SELECT_DATASOURCE_TYPE } from "../../formBuilderConfig";
import EventSetting from "./Tabs/EventSetting";

const TagsInputSetting = ({ component, formSetting }) => {
  const [dataSouceTypeSelected, setDataSouceTypeSelected] = useState();
  const handleChangeDatasourceType = (e) => {
    setDataSouceTypeSelected(e);
  };

  const items = [
    {
      key: SETTING_TAB.DISPLAY,
      label: SETTING_TAB.DISPLAY_TEXT,
      children: <DisplaySetting component={component} showPlaceholder />,
    },
    {
      key: SETTING_TAB.DATA,
      label: SETTING_TAB.DATA_TEXT,
      children: (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Data Source Type" name="dataSouceType">
              <Select
                placeholder="Select..."
                showSearch
                optionFilterProp="children"
                options={SELECT_DATASOURCE_TYPE}
                onChange={handleChangeDatasourceType}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            {dataSouceTypeSelected === "url" && (
              <Form.Item
                label="Data source URL"
                name="dataSourceUrl"
                style={{ marginBottom: 5 }}
              >
                <Input />
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            {dataSouceTypeSelected === "url" && (
              <Form.Item
                label="Label Property"
                name={["optionSchema", "label"]}
                style={{ marginBottom: 5 }}
              >
                <Input />
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            {dataSouceTypeSelected === "url" && (
              <Form.Item
                label="Value Property"
                name={["optionSchema", "value"]}
                style={{ marginBottom: 5 }}
              >
                <Input />
              </Form.Item>
            )}
          </Col>

          <Col span={24}>
            {dataSouceTypeSelected === "values" && <OptionList />}
          </Col>
        </Row>
      ),
    },
    {
      key: SETTING_TAB.VALIDATION,
      label: SETTING_TAB.VALIDATION_TEXT,
      children: <ValidationSetting component={component} />,
    },
    {
      key: SETTING_TAB.API,
      label: SETTING_TAB.API_TEXT,
      children: <ApiSetting component={component} />,
    },
    // {
    //   key: SETTING_TAB.CONDITION,
    //   label: SETTING_TAB.CONDITION_TEXT,
    //   children: <ConditionSetting component={component} />,
    // },
    // {
    //   key: SETTING_TAB.EVENT,
    //   label: SETTING_TAB.EVENT_TEXT,
    //   children: <EventSetting component={component}/>,
    // },
  ];

  return <Tabs defaultActiveKey={SETTING_TAB.DISPLAY} items={items} />;
};
export default TagsInputSetting;
