import React, { useState, useCallback, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Input,
  Pagination,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";
import {
  ReloadOutlined,
  PlusOutlined,
  RollbackOutlined,
  DownOutlined,
  EditFilled,
} from "@ant-design/icons";

import DeleteData, { DELETE_TYPE } from "../components/DeleteData/DeleteData";
import {
  emptyAction,
  getAdminActionList,
  setPayloadGetAdminActionList,
} from "../../store/adminSlice";
import { orderBy } from "lodash";

const ActionList = (props) => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dataSource, setDataSource] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [keywordValue, setKeywordValue] = useState("");

  const { list: actions, total } = useSelector(
    (states) => states.admin?.actionsObj
  );

  const columns = [
    {
      title: "Action name",
      dataIndex: "actionName",
      key: "actionName",
      render: (text, record) => (
        <Space direction="vertical">
          <Link to={`/admin/actions/${record.id}`}>{text}</Link>
          {/* <Link to={`http://dalavi.io/#!/admin/actions/${record.id}`}>
            {text}
          </Link> */}
        </Space>
      ),
      align: "left",
      width: "50%",
    },
    {
      title: "Apply For",
      dataIndex: "applyFor",
      key: "applyFor",
      align: "center",
      width: "30%",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <DeleteData type={DELETE_TYPE.ADMIN_ACTION} data={record} isReload />
      ),
      align: "right",
    },
  ];

  useEffect(() => {
    handleGetAdminActionList();
  }, [pageSize, currentPage, keyword]);

  useEffect(() => {
    setDataSource(orderBy(actions, ['id'], ['desc']));
  }, [actions]);

  const handleGetAdminActionList = useCallback(() => {
    setIsLoading(true);
    const payload = {
      page: currentPage - 1,
      page_size: pageSize,
      keyword: keyword,
    };
    dispatch(setPayloadGetAdminActionList(payload));
    dispatch(getAdminActionList(payload)).then(() => {
      setIsLoading(false);
    });
  }, [currentPage, pageSize, keyword]);

  const handleOnSearch = useCallback((e) => {
    setKeyword(e);
  });
  const handleOnchangeKeyWord = (e) => {
    setKeywordValue(e.target.value);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleClearFilter = () => {
    setCurrentPage(1);
    setKeyword("");
    setKeywordValue("");
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        size="small"
        loading={isLoading}
        pagination={false}
        title={() => (
          <Row gutter={12}>
            <Col xs={24} md={18}>
              <Space
                size={"small"}
                align="center"
                justify="start"
                style={{ width: "100%" }}
              >
                <Input.Search
                  placeholder="Keyword"
                  onSearch={handleOnSearch}
                  onChange={handleOnchangeKeyWord}
                  value={keywordValue}
                />
                <Button type="default" key="clear" onClick={handleClearFilter}>
                  Clear Filter
                </Button>
              </Space>
            </Col>
            <Col xs={24} md={6}>
              <Space
                size={"small"}
                align="center"
                justify="end"
                style={{ width: "100%" }}
              >
                <Button
                  type="text"
                  icon={<ReloadOutlined />}
                  onClick={handleGetAdminActionList}
                >
                  Reload
                </Button>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    dispatch(emptyAction());
                    navigator(`/admin/actions/new`);
                  }}
                >
                  Create
                </Button>
              </Space>
            </Col>
          </Row>
        )}
        footer={() => (
          <Pagination
            total={total || 0} // total number of items
            current={currentPage || 1} // current page number
            pageSize={pageSize || 10} // number of items per page
            showSizeChanger // allow user to change page size
            showQuickJumper
            showTotal={(total) => `Total ${total} items`}
            onChange={handlePageChange} // handle page changes
          />
        )}
      />
    </>
  );
};
export default ActionList;
