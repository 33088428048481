import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL, createDataset, deleteDatas, deleteDataset, getCategories, getDatasetDetail, getDatasets, searchData, updateData } from "../services";

export const getDatasetList = createAsyncThunk(
  API_URL.DATASET_LIST,
  async (params) => {
    const response = await getDatasets(params);
    return response.data;
  }
);

export const deleteDatasetItem = createAsyncThunk(
  API_URL.DATASET_DELETE_DATASET,
  async (params) => {
    const response = await deleteDataset(params);
    return response.data;
  }
);

export const saveDatasetItem = createAsyncThunk(
    API_URL.DATASET_CREATE_DATASET,
    async (params) => {
      const response = await createDataset(params);
      return response.data;
    }
  );
export const getDatasetCategoryList = createAsyncThunk(
  API_URL.DATASET_CATEGORY_LIST,
  async (params) => {
    const response = await getCategories(params);
    const result = response.data?._embedded?.datastoreCategories?.map(x => ({
      label: x.category,
      value: x.category,
      item: x
    }));

    return result;
  }
)
export const getDatasetDetailById = createAsyncThunk(
  API_URL.DATASET_DETAIL,
  async (params) => {
    const response = await getDatasetDetail(params);
    return response.data;
  }
)

export const deleteDataRow = createAsyncThunk(
  API_URL.DELETE_DATA_ROW,
  async (params) => {
    const response = await deleteDatas(params);
    return response.data;
  }
)

export const updateDataItem = createAsyncThunk(
  API_URL.UPDATE_DATA,
  async (params) => {
    await updateData(params);
    return params;
  }
)

export const searchDatasetDetail = createAsyncThunk(
  API_URL.SEARCH_DATA,
  async (params) => {
    const response = await searchData(params);
    return response.data;
  }
)

const initialState = {
  datasetCategories: [],
  datasetsObj: {
    list: [],
    total: 0,
    payload: {
      isOwn: true,
      page: 0,
      page_size: 10,
      keyword: "",
    },
  },
  datasetObj: {
    list: [],
    total: 0,
    header: [],
    datasetName: "",
    payload: {
      isOwn: true,
      page: 0,
      page_size: 10,
      keyword: "",
    },
  }
};

export const datasetSlice = createSlice({
  name: "datasetSlice",
  initialState,
  reducers: {
    setPayloadGetDatasetList: (state, { payload }) => ({
      ...state,
      datasetsObj: {
        ...state.datasetsObj,
        payload: payload,
      },
    }),
    setPayloadGetDatasetDetail: (state, { payload }) => ({
      ...state,
      datasetObj: {
        ...state.datasetObj,
        payload: payload,
      },
    }),
  },
  extraReducers: {
    [getDatasetList.fulfilled]: (state, { payload }) => {
      state.datasetsObj = {
        ...state.datasetsObj,
        list: payload.lstDataset,
        total: payload.total,
      };
    },
    [getDatasetCategoryList.fulfilled]: (state, {payload}) => {
      state.datasetCategories = payload;
    },
    [getDatasetDetailById.fulfilled]: (state, {payload}) => {
      state.datasetObj = {
        ...state.datasetObj,
        list: payload.lstDataStaging,
        total: payload.total,
        header: payload.header,
        datasetName: payload.name
      };
    },
    [searchDatasetDetail.fulfilled]: (state, {payload}) => {
      state.datasetObj = {
        ...state.datasetObj,
        list: payload.lstDataStaging,
        total: payload.total,
        header: payload.header,
        datasetName: payload.name
      };
    }
  },
});

export const { setPayloadGetDatasetList, setPayloadGetDatasetDetail } = datasetSlice.actions;

export default datasetSlice.reducer;
