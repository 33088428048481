import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL, getAccountUserList, keepAlive } from "../services";
import {
  loginAWSCognito,
  refreshTokenAWSCognito,
  registerUser,
  resendVerificationCode,
  verifyUser,
  forgotPassword as forgotPasswordApi,
  resetPassword as resetPasswordApi,
} from "../services/aws";
import { getExpiredTime } from "../Utilities";
import { cloneDeep } from "lodash";

const initialState = {
  isReLoad: false,
  isLoggedIn: false,
  expiredTime: 0,
  user: {
    user: {},
    childrens: {
      dataSource: [],
      total: 0,
      payload: {},
    },
  },
  inApp: false,
  loginType: 0, // 1: manual, 2: auto, 3: refresh]
  tab: "login", // login || register
  login: {
    status: "", // pending, success, error
    error: "",
    payload: {},
  },
  register: {
    status: "", // pending, success, error, sent
    error: "",
    values: {},
    step: "register", // register, verification
  },
  forgotPassword: {
    step: "forgot", // forgot, reset
    email: "",
    status: "",
    error: "",
    values: {},
  },
};

export const login = createAsyncThunk(API_URL.LOGIN, async (params) => {
  try {
    const response = await loginAWSCognito(params);
    return response;
  } catch (e) {
    throw e;
  }
});

export const refreshLogin = createAsyncThunk(
  API_URL.REFRESH_TOKEN,
  async (params) => {
    try {
      const response = await refreshTokenAWSCognito(
        params.user.email,
        params.refresh_token
      );
      return response;
    } catch (e) {
      localStorage.clear();
      throw e;
    }
  }
);

export const logout = createAsyncThunk("LOGOUT", async () => {
  localStorage.clear();
  return true;
});

export const checkKeepAlive = createAsyncThunk(
  "keepAlive",
  async (params, { getState }) => {
    const response = await keepAlive();
    return response;
  }
);

export const register = createAsyncThunk(
  "register",
  async ({ name, email, password, rootUserId }) => {
    try {
      await registerUser({
        name,
        email,
        password,
        rootUserId,
      });
      return {
        name,
        email,
        rootUserId,
      };
    } catch (e) {
      throw e;
    }
  }
);

export const resendCode = createAsyncThunk(
  "resendCode",
  async (params, { getState }) => {
    try {
      const { auth } = getState();
      const { register } = auth;
      const email = register.values?.email;
      if (email) {
        await resendVerificationCode(email);
      } else {
        throw {
          message: "Something went wrong...Please try again later",
        };
      }
    } catch (e) {
      throw e;
    }
  }
);

export const verify = createAsyncThunk(
  "verify",
  async ({ verificationCode }, { getState }) => {
    const { auth } = getState();
    const { register } = auth;
    const email = register.values?.email;
    try {
      await verifyUser(email, verificationCode);
    } catch (e) {
      throw e;
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async ({ email }) => {
    try {
      await forgotPasswordApi(email);
      return email;
    } catch (e) {
      throw e;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async ({ password, code }, { getState }) => {
    try {
      const { auth } = getState();
      const { forgotPassword } = auth;
      const email = forgotPassword.email;
      if (email) {
        await resetPasswordApi(email, password, code);
      } else {
        throw {
          message: "Something went wrong...Please try again later",
        };
      }
    } catch (e) {
      throw e;
    }
  }
);
export const getAccountUsers = createAsyncThunk(
  "getAccountUsers",
  async (params, { getState }) => {
    const response = await getAccountUserList(params);
    return response;
  }
);
export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    notLogin: (state) => {
      return { ...cloneDeep(initialState), inApp: state.inApp };
    },
    autoLogin: (state, { payload }) => {
      return {
        ...state,
        user: {...payload, childrens: {...initialState.user.childrens}},
        isLoggedIn: true,
        isReLoad: true,
        expiredTime: payload.expiredTime,
        inApp: true,
        loading: false,
      };
    },
    setLoginType: (state, { payload }) => {
      return {
        ...state,
        login: {
          ...cloneDeep(initialState.login),
        },
        register: {
          ...cloneDeep(initialState.register),
        },
        forgotPassword: {
          ...cloneDeep(initialState.forgotPassword),
        },
        loginType: payload,
      };
    },
    changeTab: (state, { payload }) => {
      return {
        ...cloneDeep(initialState),
        loginType: state.loginType,
        tab: payload,
      };
    },
    setPayloadGetAccountUsers: (state, { payload }) => {
      return {
        ...state,
        user: {
          ...state.user,
          childrens: {
            ...state.user.childrens,
            payload: payload,
          },
        },
      };
    },
    setPayloadLogin: (state, { payload }) => {
      state.login.payload = payload;
    },
  },
  extraReducers: {
    [login.pending]: (state, { payload }) => {
      return cloneDeep({
        ...state,
        login: { ...state.login, status: "pending", error: "" },
      });
    },
    [login.fulfilled]: (state, { payload }) => {
      const plus58 = getExpiredTime();
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...payload,
          expiredTime: plus58,
        })
      );
      return {
        ...state,
        isLoggedIn: true,
        user: { ...payload, childrens: { ...initialState.user.childrens } },
        expiredTime: plus58,
        inApp: true,
        loginType: 0,
        login: {
          ...initialState.login,
        },
      };
    },
    [login.rejected]: (state, obj) => {
      console.log("login.rejected", obj);
      const { error } = obj;
      return cloneDeep({
        ...state,
        login: { ...state.login, status: "error", error },
      });
    },
    [refreshLogin.fulfilled]: (state, { payload }) => {
      const cookie = JSON.parse(localStorage.getItem("user"));
      const plus58 = getExpiredTime();
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...cookie,
          ...payload,
          expiredTime: plus58,
        })
      );
      return {
        ...state,
        isLoggedIn: true,
        isReLoad: true,
        user: {
          user: cookie.user,
          ...payload,
          childrens: { ...initialState.user.childrens },
        },
        expiredTime: plus58,
        inApp: true,
      };
    },
    [refreshLogin.rejected]: () => {
      return cloneDeep(initialState);
    },
    [logout.fulfilled]: () => {
      return cloneDeep(initialState);
    },
    [register.pending]: (state) => {
      return {
        ...state,
        register: {
          ...state.register,
          status: "pending",
        },
      };
    },
    [register.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        register: {
          ...state.register,
          status: "",
          step: "verification",
          values: payload,
        },
      };
    },
    [register.rejected]: (state, { error }) => {
      return {
        ...state,
        register: {
          ...state.register,
          status: "error",
          error,
        },
      };
    },
    [resendCode.pending]: (state) => {
      return {
        ...state,
        register: {
          ...state.register,
          status: "",
        },
      };
    },
    [resendCode.fulfilled]: (state) => {
      return {
        ...state,
        register: {
          ...state.register,
          status: "sent",
        },
      };
    },
    [resendCode.rejected]: (state, { error }) => {
      return {
        ...state,
        register: {
          ...state.register,
          status: "error",
          error,
        },
      };
    },
    [verify.pending]: (state) => {
      return {
        ...state,
        register: {
          ...state.register,
          status: "pending",
        },
      };
    },
    [verify.fulfilled]: (state) => {
      return {
        ...state,
        register: {
          ...state.register,
          status: "success",
        },
        tab: "login",
        loginType: 1,
      };
    },
    [verify.rejected]: (state, { error }) => {
      return {
        ...state,
        register: {
          ...state.register,
          status: "error",
          error,
        },
      };
    },
    [forgotPassword.pending]: (state) => ({
      ...state,
      forgotPassword: {
        ...state.forgotPassword,
        status: "pending",
      },
    }),
    [forgotPassword.fulfilled]: (state, { payload }) => ({
      ...state,
      forgotPassword: {
        ...state.forgotPassword,
        status: "success",
        email: payload,
        step: "reset",
      },
    }),
    [forgotPassword.rejected]: (state, { error }) => ({
      ...state,
      forgotPassword: {
        ...state.forgotPassword,
        status: "error",
        error,
      },
    }),
    [resetPassword.pending]: (state) => ({
      ...state,
      forgotPassword: {
        ...state.forgotPassword,
        status: "pending",
      },
    }),
    [resetPassword.fulfilled]: (state) => ({
      ...state,
      forgotPassword: {
        ...state.forgotPassword,
        status: "success",
      },
      tab: "login",
      loginType: 1,
    }),
    [resetPassword.rejected]: (state, { error }) => ({
      ...state,
      forgotPassword: {
        ...state.forgotPassword,
        status: "error",
        error,
      },
    }),
    [getAccountUsers.fulfilled]: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        childrens: {
          ...state.user.childrens,
          ...payload.data,
        },
      },
    }),
  },
});

export const {
  notLogin,
  autoLogin,
  pushReturnAction,
  setLoginType,
  changeTab,
  verification,
  setPayloadGetAccountUsers,
  setPayloadLogin,
} = authSlice.actions;

export default authSlice.reducer;
