import {
  CognitoUser,
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { userPool } from "./cognitoAuth";
import each from "lodash/each";
import axiosCustom from "../base/axiosCustom";

const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const loginAWSCognito = ({ username: email, password, newPassword }) => {
  const userData = {
    Username: email,
    Pool: userPool,
  };

  const authenticationData = {
    Username: email,
    Password: password,
  };

  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        cognitoUser.getUserAttributes((err, attrs) => {
          try {
            if (!err) {
              var authData = {
                user: {},
                id_token: result.idToken.jwtToken,
                access_token: result.accessToken.jwtToken,
                refresh_token: result.refreshToken.token,
              };
              var decodeInfo = parseJwt(result.idToken.jwtToken);
              console.log("decodeInfo", decodeInfo);
              each(attrs, function (attr) {
                authData.user[attr.Name] = attr.Value;
              });
              authData.user.id = authData.user.sub;
              authData.user.groups = decodeInfo["cognito:groups"] || [];
              resolve(authData);
              return;
            }

            reject(err);
          } catch (e) {
            reject(e);
          }
        });
      },
      onFailure: (err) => {
        reject(err);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        if (!newPassword) {
          reject("NEW_PASSWORD_REQUIRED");
          return;
        }
        cognitoUser.completeNewPasswordChallenge(newPassword, requiredAttributes, {
          onSuccess: (result) => {
            console.log("NEW PASSWORD COMPLETED: ", result);
            resolve(result);
          },
          onFailure: (err) => {
            console.error("NEW PASSWORD ERROR: ", err);
            reject(err);
          },
        });
      },
    });
  });
};

export const refreshTokenAWSCognito = async (username, refreshToken) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };

  const token = new CognitoRefreshToken({ RefreshToken: refreshToken });

  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(token, (err, session) => {
      if (err) {
        console.error("Failed to refresh session:", err);
        reject(err);
      } else {
        const accessToken = session.getAccessToken().getJwtToken();
        const idToken = session.getIdToken().getJwtToken();
        const refreshToken = session.getRefreshToken().getToken();
        resolve({
          access_token: accessToken,
          id_token: idToken,
          refresh_token: refreshToken,
        });
      }
    });
  });
};

export const getPreSignedUrl = (file_name) => {
  return axiosCustom.get(`/file/presignedUrl?file_name=${file_name}`);
};

export const uploadTos3 = (signnedUrl, file) => {
  return axiosCustom.putForm(signnedUrl, file);
};

export const registerUser = ({ name, email, password, rootUserId }) => {
  const userAttributes = [
    new CognitoUserAttribute({
      Name: "name",
      Value: name,
    }),
    new CognitoUserAttribute({
      Name: "email",
      Value: email,
    }),
  ];

  if (rootUserId) {
    userAttributes.push(
      new CognitoUserAttribute({
        Name: "profile",
        Value: rootUserId,
      })
    );
  }

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, userAttributes, [], (err, result) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(result);
    });
  });
};

export const verifyUser = (username, verificationCode) => {
  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });
  return new Promise((resolve, reject) => {
    user.confirmRegistration(verificationCode, true, (err, result) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(result);
    });
  });
};

export const resendVerificationCode = (username) => {
  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    user.resendConfirmationCode((err, result) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(result);
    });
  });
};

export const forgotPassword = (username) => {
  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    user.forgotPassword({
      onSuccess: () => resolve(),
      onFailure: (err) => reject(err),
    });
  });
};

export const resetPassword = (username, password, code) => {
  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    user.confirmPassword(code, password, {
      onSuccess: () => resolve(),
      onFailure: (err) => reject(err),
    });
  });
};

export const changePassword = (username, oldPassword, password) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };

  const authenticationData = {
    Username: username,
    Password: oldPassword,
  };

  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
          // Change password
          cognitoUser.changePassword(oldPassword, password, function(err, result) {
              if (err) {
                  console.error('Password change failed:', err);
                  reject(err)
                  return;
              }
              console.log('Password change successful:', result);
              resolve(result);
          });
      },
      onFailure: function (err) {
          console.error('Authentication failed:', err);
          reject(err);
      }
  });
  });
}