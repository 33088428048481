import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";

import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";

import App from "./App";
import { store } from "./store";

// Importing the Bootstrap CSS
// import "bootstrap/dist/css/bootstrap.min.css";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          colorSuccess: "#0ac18b",
        },
      }}
    >
      <App />
    </ConfigProvider>
  </Provider>,
  rootElement
);
