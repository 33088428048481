import React, { useEffect, useState, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Col, Input, Pagination, Row, Select, Space, Table } from "antd";
import {
  ReloadOutlined,
  PlusOutlined,
  RollbackOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { isEmpty } from "lodash";

import DropdownAction, { ACTION_TYPE } from "../components/DropdownAction/DropdownAction";
import HistoryLog, { HISTORY_TYPE } from "../components/History/HistoryLog";
import DeleteData, { DELETE_TYPE } from "../components/DeleteData/DeleteData";
import { allCrawlers, allDataset } from "../../store/pipelineSlice";
import { getActionCategoryList, getActionList } from "../../store/adminSlice";
import { getDatastoreCategoryList, getDatastoreList, setPayloadGetDatastoreList } from "../../store/datastoreSlice";

const columns = [
  {
    title: "Datastore Name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => (
      <Space direction="vertical">
        <Link to={`/datastores/${record.id}`}>{text}</Link>
      </Space>
    ),
    align: "left",
    width: "60%",
  },
  {
    title: "",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <DropdownAction type={ACTION_TYPE.DATASTORE} data={record} />
        <HistoryLog type={HISTORY_TYPE.DATASTORE} data={record} />
        <DeleteData type={DELETE_TYPE.DATASTORE} data={record} isReload />
      </Space>
    ),
    align: "right",
  },
];

const DatastoreList = (props) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dataSource, setDataSource] = useState([]);
  const [isGetShared, setIsGetShared] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [keywordValue, setKeywordValue] = useState("");
  const [category, setCategory] = useState(null);

  const { list: datastores, total } = useSelector(
    (states) => states.datastore?.datastoresObj
  );
  const { actions } = useSelector((states) => states.admin?.actions);
  const { datastoreCategories: categories } = useSelector(
    (states) => states.datastore
  );

  useEffect(() => {
    dispatch(allCrawlers());
    dispatch(allDataset());
    dispatch(getDatastoreCategoryList());

    if (isEmpty(actions)) {
      dispatch(getActionCategoryList()).then(() => {
        dispatch(getActionList());
      })
    }
  }, [dispatch]);

  useEffect(() => {
    handleGetDatastores();
  }, [pageSize, currentPage, isGetShared, keyword, category]);

  useEffect(() => {
    setDataSource(datastores);
  }, [datastores]);

  const handleGetDatastores = useCallback(() => {
    setIsLoading(true);
    const payload = {
      isOwn: !isGetShared,
      page: currentPage - 1,
      page_size: pageSize,
      keyword: keyword,
      category: category,
    };
    dispatch(setPayloadGetDatastoreList(payload));
    dispatch(getDatastoreList(payload)).then(() => {
      setIsLoading(false);
    });
  }, [currentPage, pageSize, isGetShared, keyword, category]);

  const handleOnSearch = useCallback((e) => {
    setKeyword(e);
  });
  const handleOnchangeKeyWord = (e) => {
    setKeywordValue(e.target.value);
  };

  const handleGetShared = useCallback((e) => {
    setIsGetShared(e.target.checked);
  });

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleClearFilter = () => {
    setCurrentPage(1);
    setIsGetShared(false);
    setKeyword("");
    setCategory(null);
    setKeywordValue("");
  };

  return (
    <>
      <Table
        className="custom-ant-table"
        bordered
        scroll={"scroll"}
        loading={isLoading}
        size="middle"
        dataSource={dataSource}
        columns={columns}
        pagination={false} // hide default pagination
        title={() => (
          <Row gutter={12}>
            <Col xs={24} md={18}>
              <Space
                size={"small"}
                align="center"
                justify="start"
                style={{ width: "100%" }}
              >
                <Space.Compact style={{ width: 500 }}>
                  <Input.Search
                    placeholder="Keyword"
                    onSearch={handleOnSearch}
                    onChange={handleOnchangeKeyWord}
                    value={keywordValue}
                  />
                  <Select
                    placeholder="Select category"
                    options={categories}
                    style={{
                      width: '70%',
                    }}
                    onChange={(e) => setCategory(e)}
                    value={category}
                  />
                </Space.Compact>

                <Checkbox onChange={handleGetShared} checked={isGetShared}>
                  Shared with me
                </Checkbox>
                <Button type="default" key="clear" onClick={handleClearFilter}>
                  Clear Filter
                </Button>
              </Space>
            </Col>
            <Col xs={24} md={6}>
              <Space
                size={"small"}
                align="center"
                justify="end"
                style={{ width: "100%" }}
              >
                <Button
                  type="text"
                  icon={<ReloadOutlined />}
                  // onClick={handleGetDatasets}
                >
                  Reload
                </Button>
                {/* <CreateDatasetModal isCreate /> */}
              </Space>
            </Col>
          </Row>
        )}
        footer={() => (
          <Pagination
            total={total || 0} // total number of items
            current={currentPage || 1} // current page number
            pageSize={pageSize || 10} // number of items per page
            showSizeChanger // allow user to change page size
            showQuickJumper
            showTotal={(total) => `Total ${total} items`}
            onChange={handlePageChange} // handle page changes
          />
        )}
      />
    </>
  );
};
export default DatastoreList;
