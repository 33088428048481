import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
  useRef,
  useLayoutEffect,
} from "react";
import { useSelector } from "react-redux";

import {
  Button,
  Space,
  Drawer,
  Form,
  Select,
  Row,
  Col,
  Spin,
  Input,
  Modal,
  notification,
} from "antd";
import { PlayCircleFilled } from "@ant-design/icons";
// import "./index.css";
import { each, forEach, isEmpty, some } from "lodash";
import FormDynamicRender from "./FormDynamicRender";
import withErrorHandling from "../../../../HOC/withErrorHandler";
import {
  checkResponseIsFileThenDownload,
  tryParse,
} from "../../../../Utilities";
import { FormDynamicContext, PipelineContext } from "../../../../context";
import { postToAction, runAction } from "../../../../services";
import DragableModal from "../../DragableModal/DragableModal";

const FormRender = (props) => {
  const { type, actionData, open, onCancel, currentData, dataType } = props;
  const formDynamicContext = useContext(FormDynamicContext);
  const { actionSelected } = formDynamicContext;
  const [actionBuilded, setActionBuilded] = useState();
  const [loading, setLoading] = useState(true);
  const [initialFromValues, setInitialFromValues] = useState();
  const [actionName, setActionName] = useState("");

  const [form] = Form.useForm();

  const { crawlers, datasets, datastores } = useSelector(
    (states) => states.pipeline
  );

  useEffect(() => {
    setActionName(actionSelected?.actionDisplayName || "");
    buildingAction();
  }, [actionSelected]);

  const buildingAction = useCallback(
    withErrorHandling(() => {
      if (!actionSelected) return;

      let action = JSON.parse(actionSelected?.actionMetadata || "[]");
      action.forEach((row) => {
        row.columns.forEach((col) => {
          col.components.forEach((c) => {
            // migrate to new version pipeline
            if (
              !isEmpty(c[c.apiProperty]) &&
              typeof c[c.apiProperty] === "string" &&
              c[c.apiProperty].includes("||")
            ) {
              c[c.apiProperty] = c[c.apiProperty].split("||");
            }

            // migrate to new version event __eventToComponent
            // if (c.type === "select" && !isEmpty(c.__eventToComponent)) {
            //   c.__eventToComponent = [c.__eventToComponent.apiProperty];
            // }

            c.isValid = true;
            // const numberOfPreviousPipe = edges.filter(
            //   (x) => x.target == selectedNode.id
            // ).length;

            if (c.type === "select") {
              if (c.dataSouceType === "dataset") {
                c.values = datasets;
              }
              if (c.dataSouceType === "crawler") {
                c.values = crawlers;
              }
              if (c.dataSouceType === "datastore") {
                c.values = datastores;
              }
              if (
                c.dataSouceType === "dataset" &&
                // c.isMultiple &&
                c.isFillCurrentData &&
                !isEmpty(currentData)
              ) {
                c[c.apiProperty] = currentData?.id;
              }
              // if (c.dataSouceType === "column" && numberOfPreviousPipe == 1) {
              //   c.values = getPreviousPipeOutputDataSource(selectedNode.id);
              // }
              // if (c.dataSouceType === "column" && numberOfPreviousPipe > 1) {
              //   c.getDatasetSourceFrom = getPreviousPipes(selectedNode.id);
              //   c.values = [];
              // }
            } else if (c.type == "dataSourceMapping") {
              if (c.fromDataSourceType == "dataset") {
                c.fromDatasourceValues = datasets;
              }
              if (c.fromDataSourceType == "crawler") {
                c.fromDatasourceValues = crawlers;
              }
              if (c.fromDataSourceType == "datastore") {
                c.fromDatasourceValues = datastores;
              }
              if (c.toDataSourceType == "dataset") {
                c.toDatasourceValues = datasets;
              }
              if (c.toDataSourceType == "crawler") {
                c.toDatasourceValues = crawlers;
              }
              if (c.toDataSourceType == "datastore") {
                c.toDatasourceValues = datastores;
              }
            } else if (c.type == "renameMultipleColumns") {
              // let previousActionsColumns = [];
              // if (numberOfPreviousPipe == 1) {
              //   previousActionsColumns = getPreviousPipeOutputDataSource(
              //     selectedNode.id
              //   );
              // }
              // if (numberOfPreviousPipe > 1) {
              //   c.getDatasetSourceFrom = getPreviousPipes(selectedNode.id);
              //   previousActionsColumns = c[c.apiProperty]?.map((x) => {
              //     return { label: x.source, value: x.source };
              //   });
              // }
              // const apiComponentData = tryParse(c[c.apiProperty], []).data;
              // const renameObj = previousActionsColumns.map((x) => {
              //   const mapped = apiComponentData?.find((m) => m.source == x.value);
              //   return {
              //     source: x.value,
              //     target: mapped ? mapped.target : x.value,
              //     isNew: !mapped,
              //   };
              // });
              // c[c.apiProperty] = renameObj;
              // c[`__${c.apiProperty}_str`] = JSON.stringify(renameObj);
            }
          });
        });
      });

      setActionBuilded(action);

      const initialValues = action.reduce((acc, curr) => {
        const { columns } = curr;
        columns.forEach((col) => {
          const { components } = col;
          components.forEach((component) => {
            acc[component.apiProperty] = component[component.apiProperty];
          });
        });
        return acc;
      }, {});

      setInitialFromValues(initialValues);
      form.setFieldsValue(initialValues);
    }),
    [actionSelected]
  );

  const handleElementChange = withErrorHandling((value, options) => {
    const { needUpdateForm, itemName, component } = options;

    if (needUpdateForm) {
      form.setFieldValue(itemName, value);
    }
  });

  const handleRunAction = useCallback(
    withErrorHandling((values) => {
      const params = buildActionParams(values);
      const payload = {
        action: {
          id: actionSelected?.id,
        },
        actionRequestName: values.actionRequestName,
        actionRequestParameters: params,
      };

      const request = runAction(payload);
      if (actionSelected.isWaitForAction) {
        setLoading(true);
        request.then(function (res) {
          checkResponseIsFileThenDownload(res);
          setLoading(false);
          notification.success({
            message: "Submit Successfully",
          });
          onCancel instanceof Function && onCancel(null, true);
        });
      } else {
        notification.success({
          message: "Submit Successfully",
          description:
            "Please visit the activities page to monitor this request",
        });
        onCancel instanceof Function && onCancel(null, true);
      }
    })
  );

  const buildActionParams = withErrorHandling(
    (
      values,
      triggerComponent,
      paramKey = "paramName",
      paramValue = "paramValues"
    ) => {
      if (isEmpty(values)) {
        values = form.getFieldsValue();
      }

      let params = [];
      each(values, (value, key) => {
        let param = {};
        param[paramKey] = key;
        if (
          Array.isArray(value) &&
          some(value, (x) => x.hasOwnProperty("originFileObj"))
        ) {
          param[paramValue] = value
            .map((x) => x.originFileObj?.name)
            .join("||");
        } else if (Array.isArray(value)) {
          param[paramValue] = value.join("||");
        } else {
          param[paramValue] = value;
        }

        if (!isEmpty(triggerComponent)) {
          param.isSelect = key === triggerComponent.apiProperty ? 1 : 0;
        }

        params.push(param);
      });

      return params;
    }
  );
  switch (type) {
    case FORM_RENDER_TYPE.MODAL: {
      return (
        <>
          <DragableModal
            width={700}
            title={actionName}
            open={open}
            // onOk={handleOk}
            confirmLoading={loading}
            onCancel={onCancel}
            footer={[
              <Button
                key="submit"
                onClick={() => form.submit()}
                type="primary"
                icon={<PlayCircleFilled />}
              >
                Run Action
              </Button>,
              <Button key="back" onClick={onCancel} danger>
                Cancel
              </Button>,
            ]}
          >
            <Form
              layout="vertical"
              form={form}
              initialValues={initialFromValues}
              onFinish={handleRunAction}
            >
              <FormDynamicRender
                actionData={actionBuilded}
                onElementChange={handleElementChange}
                getFormParams={buildActionParams}
              />
              <Row>
                <Col>
                  <Form.Item label="Name" name="actionRequestName">
                    <Input placeholder="Enrer pipe's name" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </DragableModal>
        </>
      );
    }
    case FORM_RENDER_TYPE.DRAWER: {
      return <></>;
    }
    default:
      return <></>;
  }
};

export default React.memo(FormRender);
export const FORM_RENDER_TYPE = {
  MODAL: "MODAL",
  DRAWER: "DRAWER",
};
