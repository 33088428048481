import React, { useState, useCallback, useEffect } from "react";
import { Collapse, Typography, theme } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { isEmpty, uniqueId } from "lodash";

const { Panel } = Collapse;

const CrawlerProperty = ({ properties }) => {
  const {
    token: { colorBgContainer, colorFillAlter, borderRadiusLG },
  } = theme.useToken();

  const panelStyle = {
    marginBottom: 10,
    background: colorFillAlter,
    borderRadius: borderRadiusLG,
    border: "none",
  };
  return (
    <Collapse
      pse
      defaultActiveKey={["1"]}
      bordered={false}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      style={{
        background: colorBgContainer,
      }}
    >
      {!isEmpty(properties) &&
        properties.map((prop) => (
          <Panel style={panelStyle} header={prop.name} key={uniqueId("prop")}>
            <Typography.Text>{prop.sampleData}</Typography.Text>
          </Panel>
        ))}
    </Collapse>
  );
};
export default CrawlerProperty;
