import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import orderBy from "lodash/orderBy";
import {
  API_URL,
  crawlerDeleteSchedule,
  crawlerInactiveSchedule,
  crawlerRunHistory,
  deleteCrawler,
  getCrawlerById,
  getCrawlers,
  previewCrawlerJson,
} from "../services";
import { crawlerActiveSchedule } from "../services";

export const getCrawlerList = createAsyncThunk(
  API_URL.CRAWLER_LIST,
  async (params) => {
    const response = await getCrawlers(params);
    const { total, crawlers } = response.data;
    const crawlerOrdered = orderBy(crawlers, ["createDate"], ["desc"]);
    let crawlerList = crawlerOrdered.map(function (x) {
      return {
        value: x.id,
        label: x.name,
        name: x.name,
        columns: x.page.properties.map((prop) => prop.name),
        item: x,
      };
    });
    return { total, crawlerList };
  }
);

export const deleteCrawlerById = createAsyncThunk(
  API_URL.DELETE_CRAWLER,
  async (params) => {
    const response = await deleteCrawler(params);
    return response.data;
  }
);

export const previewData = createAsyncThunk(
  API_URL.CRAWLER_PREVIEW_JSON,
  async (params) => {
    const response = await previewCrawlerJson(params);
    return response.data;
  }
);

export const getCrawlerDetail = createAsyncThunk(
  API_URL.CRAWLER_PREVIEW_JSON,
  async (params) => {
    const response = await getCrawlerById(params);
    return response.data;
  }
);

export const activeCrawlerSchedule = createAsyncThunk(
  API_URL.CRAWLER_ACTIVE_SCHEDULE,
  async (params) => {
    await crawlerActiveSchedule(params);
    return params;
  }
);

export const inactiveCrawlerSchedule = createAsyncThunk(
  API_URL.CRAWLER_INACTIVE_SCHEDULE,
  async (params) => {
    await crawlerInactiveSchedule(params);
    return params;
  }
);

export const deleteCrawlerSchedule = createAsyncThunk(
  API_URL.CRAWLER_DELETE_SCHEDULE,
  async (params) => {
    await crawlerDeleteSchedule(params);
    return params;
  }
);

export const getCrawlerRunHistory = createAsyncThunk(
  API_URL.CRAWLER_RUN_HISTORY,
  async (params) => {
    const response = await crawlerRunHistory(params);
    return response.data;
  }
);

const initialState = {
  isReLoad: false,
  crawlersObj: {
    list: [],
    total: 0,
    payload: {
      isOwn: true,
      page: 0,
      page_size: 10,
      keyword: "",
    },
  },
  crawlerDetail: {},
  runHistory: {
    list: [],
    total: 0,
    payload: {
      page: 0,
      page_size: 10,
    },
  },
};

export const crawlerSlice = createSlice({
  name: "crawlerSlice",
  initialState,
  reducers: {
    setPayloadGetCrawlerList: (state, { payload }) => ({
      ...state,
      crawlersObj: {
        ...state.crawlersObj,
        payload: payload,
      },
    }),
  },
  extraReducers: {
    [getCrawlerList.fulfilled]: (state, { payload }) => {
      state.crawlersObj = {
        ...state.crawlersObj,
        list: payload.crawlerList,
        total: payload.total,
      };
    },
    [getCrawlerDetail.fulfilled]: (state, { payload }) => {
      state.crawlerDetail = payload;
    },
    [activeCrawlerSchedule.fulfilled]: (state, { payload }) => {
      state.crawlerDetail.schedules.forEach((schedule) => {
        if (schedule.id === payload.schedule_id) {
          schedule.status = 1;
        }
      });
    },
    [inactiveCrawlerSchedule.fulfilled]: (state, { payload }) => {
      state.crawlerDetail.schedules.forEach((schedule) => {
        if (schedule.id === payload.schedule_id) {
          schedule.status = 0;
        }
      });
    },
    [deleteCrawlerSchedule.fulfilled]: (state, { payload }) => {
      state.crawlerDetail.schedules = state.crawlerDetail.schedules.filter(
        (schedule) => schedule.id !== payload.schedule_id
      );
    },
    [getCrawlerRunHistory.fulfilled]: (state, { payload }) => {
      state.runHistory = {
        ...state.runHistory,
        list: payload.lstApiRun,
        total: payload.total,
      };
    },
  },
});

export const { setPayloadGetCrawlerList } = crawlerSlice.actions;

export default crawlerSlice.reducer;
