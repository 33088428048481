import React, { memo, useCallback } from "react";
import { clone, first } from "lodash";
import DayJS from "react-dayjs";

import withErrorHandling from "../../../HOC/withErrorHandler";

const LogContent = (props) => {
  const { data } = props;

  const renderTooltipContent = useCallback(
    withErrorHandling((runSets) => {
      if (runSets?.length > 0) {
        const { actionRequestLogs } = first(runSets);
        const actionRequestLogsCloned = clone(actionRequestLogs);
        return actionRequestLogsCloned
          ?.sort((s, e) => s.id - e.id)
          .map((log) => (
            <p key={log.id}>
              <span>{log.log}</span>
              <DayJS format="MM-DD-YYYY HH:MM:ss">{log.logTime}</DayJS>
            </p>
          ));
      }
      return <span />;
    })
  );
  return (
    <div className="pipe-log-container">
      {/* <div className="log-header">
        <h6>{data.label}</h6>
      </div>
      <hr /> */}
      <div className="log-content">
        {renderTooltipContent(data.actionRequestRunSet)}
      </div>
    </div>
  );
};

export default memo(LogContent);
