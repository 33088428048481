import React from "react";
import { SETTING_TAB } from "../../formBuilderConfig";
import { Checkbox, Form, Input, InputNumber, Space, Tabs } from "antd";
import DisplaySetting from "./Tabs/DisplaySetting";
import DataSetting from "./Tabs/DataSetting";
import ValidationSetting from "./Tabs/ValidationSetting";
import ApiSetting from "./Tabs/ApiSetting";
import ConditionSetting from "./Tabs/ConditionSetting";

const CheckboxSetting = ({ component, formSetting }) => {
  const items = [
    {
      key: SETTING_TAB.DISPLAY,
      label: SETTING_TAB.DISPLAY_TEXT,
      children: <DisplaySetting component={component} />,
    },
    {
      key: SETTING_TAB.DATA,
      label: SETTING_TAB.DATA_TEXT,
      children: (
        <DataSetting component={component}>
          <Space.Compact block>
            <Form.Item
              label="True value"
              name="trueValue"
              style={{ marginBottom: 5, width: "50%" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="False value"
              name="falseValue"
              style={{ marginBottom: 5, width: "50%" }}
            >
              <Input />
            </Form.Item>
          </Space.Compact>
        </DataSetting>
      ),
    },
    {
      key: SETTING_TAB.VALIDATION,
      label: SETTING_TAB.VALIDATION_TEXT,
      children: <ValidationSetting component={component} />,
    },
    {
      key: SETTING_TAB.API,
      label: SETTING_TAB.API_TEXT,
      children: <ApiSetting component={component} />,
    },
    // {
    //   key: SETTING_TAB.CONDITION,
    //   label: SETTING_TAB.CONDITION_TEXT,
    //   children: <ConditionSetting component={component} />,
    // },
  ];

  return <Tabs defaultActiveKey={SETTING_TAB.DISPLAY} items={items} />;
};
export default CheckboxSetting;
