import React, { useEffect } from "react";
import { Input } from "antd";
import { HomeFilled, ArrowRightOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setInitial } from "../slices";

const InputUrl = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [url, setUrl] = React.useState(props.url);

  const newUrl = () => {
    dispatch(
      setInitial({
        type: "url",
        url,
      })
    );
  };

  const goToHome = () => {
    dispatch(
      setInitial({
        url: "",
        type: "url",
        file: null,
      })
    );
    navigate("/");
  };

  const onChange = (e) => {
    setUrl(e.target.value);
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 13) {
      newUrl();
    }
  };

  useEffect(() => {
    setUrl(props.url);
  }, [props.url]);

  return (
    <Input
      addonBefore={<HomeFilled onClick={goToHome} />}
      addonAfter={<ArrowRightOutlined onClick={newUrl} />}
      placeholder="https:// enter url"
      size="large"
      value={url}
      onChange={onChange}
      onKeyUp={onKeyUp}
    />
  );
};

export default InputUrl;
