import React, { useCallback, useContext } from "react";
import { useRoutes, Link, useMatch } from "react-router-dom";
import { Breadcrumb, Typography } from "antd";
import { RouteConfigContext } from "../../../../context";
import { flattenArray } from "../../../../Utilities";
import { isEmpty } from "lodash";

const CustomBreadcrumb = (props) => {
  const { routerConfigs, customCurrentPageName } =
    useContext(RouteConfigContext);
  const flatRoutes = flattenArray(routerConfigs, "children");
  const routes = useRoutes(flatRoutes);
  const {
    match: { route, pathname, params },
  } = routes.props;

  const renderBreadcrumByKey = (key) => {
    const routeFoundByKey = flatRoutes.find((x) => x.key === key);
    const currentPath = key === route.breadcrumb[route.breadcrumb.length - 1];
    let url = routeFoundByKey?.path;
    let change = false;
    Object.keys(params).forEach((p) => {
      if (url.includes(p)) {
        change = true;
      }

      url = url.replace(`:${p}`, params[p]);
    });
    if (currentPath) {
      return (
        <Typography.Text style={{ fontSize: 15 }}>
          {!isEmpty(customCurrentPageName[pathname])
            ? customCurrentPageName[pathname]
            : routeFoundByKey?.label}
        </Typography.Text>
      );
    } else {
      return (
        <Link to={url}>
          {change && !isEmpty(customCurrentPageName[url]) ? customCurrentPageName[url] : routeFoundByKey?.label}
        </Link>
      );
    }
  };

  return (
    <Breadcrumb {...props}>
      {route.breadcrumb?.map((item, index) => (
        <Breadcrumb.Item key={item}>
          {renderBreadcrumByKey(item)}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;
