import React, { useEffect } from "react";
import { Form, Input, Button, notification } from "antd";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import {
  setLoginType,
  changeTab,
  resendCode,
  register,
  verify,
} from "../../store/authSlice";

const Register = () => {
  const dispatch = useDispatch();
  const authState = useSelector((states) => states.auth);

  const { error, status, step } = authState.register;

  const loading = status == "pending";

  const resend = (e) => {
    e.preventDefault();
    dispatch(resendCode());
  };

  const cancel = () => {
    dispatch(changeTab("register"));
  };

  useEffect(() => {
    if (status === "error") {
      notification.error({
        message: "Error",
        description: error.message,
        duration: 3,
      });
    }

    if (step === "verification") {
      const m =
        status === "success"
          ? "Register sucessfully. Please login."
          : status === "sent"
          ? "Resend code sucessfully. Please check your email"
          : "";
      if (m) {
        notification.success({
          message: "Success",
          description: m,
          duration: 2.5,
        });
      }
    }
  }, [status]);

  const onRegister = (values) => {
    dispatch(register(values));
  };

  const onVerify = (values) => {
    dispatch(verify(values));
  };

  const forgotPassword = (e) => {
    e.preventDefault();
    dispatch(changeTab("forgot"));
  };
  return step === "register" ? (
    <Form
      name="registerForm"
      initialValues={{ remember: true }}
      onFinish={onRegister}
    >
      <Form.Item
        name="name"
        rules={[{ required: true, message: "Please input your username." }]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Username"
        />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[{ required: true, message: "Please input your email." }]}
      >
        <Input
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your password." }]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        rules={[
          { required: true, message: "Please confirm your password." },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The new password that you entered do not match.")
              );
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Confirm password"
        />
      </Form.Item>
      <Form.Item>
        <span style={{ maxWidth: "50%" }}>
          By signing up, you are agreeing to our Terms of service and Privacy
          policy.
        </span>
      </Form.Item>
      <Form.Item className="Login-footer">
        <a className="login-form-forgot" href="" onClick={forgotPassword}>
          Forgot password
        </a>
        <Button type="primary" htmlType="submit" loading={loading}>
          Sign up for FREE
        </Button>
      </Form.Item>
    </Form>
  ) : step === "verification" ? (
    <Form
      name="verificationForm"
      initialValues={{ remember: true }}
      onFinish={onVerify}
    >
      <Form.Item>
        <span style={{ maxWidth: "50%" }}>
          A verification code has been sent to your email. Please check your
          email or{" "}
          <a href="#" onClick={resend}>
            resend
          </a>
        </span>
      </Form.Item>
      <Form.Item
        name="verificationCode"
        rules={[
          { required: true, message: "Please input your verification code." },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Verification code"
        />
      </Form.Item>

      <Form.Item className="Forgot-footer">
        <Button
          type="default"
          htmlType="button"
          onClick={cancel}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          Verify
        </Button>
      </Form.Item>
    </Form>
  ) : null;
};

export default Register;
