import React, { useCallback, useEffect, useState } from "react";
import { getAccountTransactions } from "../../services";
import { DatePicker, Radio, Space, Table, Typography } from "antd";
import { buildDateRange, formatDate } from "../../Utilities";
const TransactionsPage = () => {
  const [lstAccountTransaction, setLstAccountTransaction] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [usageFilter, setUsageFilter] = useState('1M');
  
  useEffect(async () => {
    await getDatas();
  }, [page, usageFilter]);

  const getDatas = useCallback(async (ranges) => {
    let fromD = '', toD = '';
    if(!ranges){
      const { from, to } = buildDateRange(usageFilter);
      fromD = from;
      toD = to;
    } else if(ranges?.length === 2) {
      fromD = ranges[0];
      toD = ranges[1];
    }

    const { data } = await getAccountTransactions({
      "from-date": fromD,
      "to-date": toD,
      page: page - 1,
      page_size: 10,
    });
    console.log(data);
    setTotal(data?.total || 0);
    setLstAccountTransaction(data?.lstAccountTransaction || []);
  }, [page, usageFilter, setTotal, setLstAccountTransaction, getAccountTransactions]);

  const onPageChange = (e) => {
    setPage(e);
  };
  const onChangeUageRadio = (e) => {
    setUsageFilter(e.target.value);
  };
  const handleDateRangeChange = (dates, str) => {
    console.log(dates, str);
    getDatas(str);
  }

  const columns = [
    {
      title: "Type",
      dataIndex: "transactionType",
      key: "transactionType",
      render: (_, record) => {
        return <Typography.Text>{record.transactionType}</Typography.Text>;
      },
    },
    {
      title: "Date",
      dataIndex: "transactionTime",
      key: "transactionTime",
      render: (_, record) => {
        return (
          <Typography.Text>
            {formatDate(record.transactionTime)}
          </Typography.Text>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "transactionAmount",
      key: "transactionAmount",
      render: (_, record) => {
        return (
          <Typography.Text>
            {record.transactionAmount} {record.transactionCurrency}
          </Typography.Text>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "transactionStatus",
      key: "transactionStatus",
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={lstAccountTransaction}
      size="middle"
      pagination={{
        total,
        onChange: onPageChange,
        showSizeChanger: false,
        current: page,
      }}
      title={() => (
        <Space>
          <Radio.Group onChange={onChangeUageRadio} value={usageFilter}>
            <Radio.Button value="1M">1M</Radio.Button>
            <Radio.Button value="3M">3M</Radio.Button>
            <Radio.Button value="6M">6M</Radio.Button>
          </Radio.Group>
          <DatePicker.RangePicker onChange={handleDateRangeChange} />
        </Space>
      )}
    />
  );
};
export default TransactionsPage;
