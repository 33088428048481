import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import {
  ReloadOutlined,
  PlusOutlined,
  RollbackOutlined,
  DownOutlined,
  EditFilled,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  changePrivilege,
  disableUser,
  enableUser,
  getAccount,
  getAccountUserList,
  postChangePassword,
} from "../../services";
import {
  getAccountUsers,
  logout,
  setPayloadGetAccountUsers,
} from "../../store/authSlice";
import RegisterUser from "./RegisterUser";
import { findCurrentSubscription, getPermission } from "../../Utilities";
import UpgradePlan from "../components/UpgradePlan/UpgradePlan";
import DeleteData, { DELETE_TYPE } from "../components/DeleteData/DeleteData";
import { getSubscriptionPlans } from "../../store/sharedSlice";
import { changePassword } from "../../services/aws";
import ToggleButton from "../components/ToggleButton/ToggleButton";

const AccountPage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { user, childrens } = useSelector((states) => states.auth?.user);
  const { plans } = useSelector((state) => state.shared);

  const [plan, setPlan] = useState({});
  const [showRegisterUser, setShowRegisterUser] = useState(false);
  const [isShowModalUpgradePlan, setIsShowModalUpgradePlan] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [isLoadingGetUser, setIsLoadingGetUser] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [keywordValue, setKeywordValue] = useState("");

  const permission = getPermission(user?.groups || []);

  useEffect(async () => {
    dispatch(getSubscriptionPlans());
    const response = await getAccount();
    form.setFieldValue("accountNumber", response.data?.accountId);
    setAccountId(response.data?.accountId);
    console.log(response);
  }, [dispatch]);

  useEffect(() => {
    if (plans?.length === 0) return;

    setPlan(findCurrentSubscription(plans, user?.groups || []));
  }, [plans, user]);

  useEffect(async () => {
    if (!accountId || !checkUpgradeUser()) return;
    await getAccountUserList();
  }, [pageSize, currentPage, accountId, keyword]);
  useEffect(() => {
    setDataSource(childrens?.lstAccountUsers);
    setTotal(childrens?.total);
  }, [childrens]);

  const getAccountUserList = useCallback(async () => {
    setIsLoadingGetUser(true);
    const payload = {
      page: currentPage - 1,
      page_size: pageSize,
      keyword: keyword,
    };
    dispatch(setPayloadGetAccountUsers(payload));
    dispatch(getAccountUsers(payload)).then(() => {
      setIsLoadingGetUser(false);
    });
  }, [pageSize, currentPage, accountId, keyword]);

  const onFinish = async (values) => {
    console.log("Change Password:", values);

    const response = await changePassword(
      values.email,
      values.currentPassword,
      values.newPassword
    );
    console.log(response);
    form.resetFields();
    message.success("Password changed successfully");
    dispatch(logout());
  };

  const checkUpgradeUser = () => {
    if (!permission.isAdmin && !permission.isPremiumOrBusiness) {
      setIsShowModalUpgradePlan(true);
      return false;
    }
    return true;
  };
  const handleCreateUser = () => {
    if (!checkUpgradeUser()) return;
    setShowRegisterUser(true);
  };
  const handleOnSearch = useCallback((e) => {
    setKeyword(e);
  });
  const handleOnchangeKeyWord = (e) => {
    setKeywordValue(e.target.value);
  };
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleClearFilter = () => {
    setCurrentPage(1);
    setKeyword("");
    setKeywordValue("");
  };
  const handleOpenModalSubscription = () => {
    setIsShowModalUpgradePlan(true);
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "left",
      width: "25%",
    },
    {
      title: "Full name",
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "25%",
    },
    {
      title: "Balance",
      dataIndex: "availableBalance",
      key: "availableBalance",
      align: "center",
      width: "10%",
    },
    {
      title: "Privilege",
      dataIndex: "privilege",
      key: "privilege",
      align: "center",
      width: "10%",
      render: (_, record) => {
        switch (_) {
          case 1:
            return <>Read</>;
          case 2:
            return <>Write</>;
          case 3:
            return <>Delete</>;
          case 4:
            return <>Full</>;
        }
      },
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      render: (_, record) =>
        !record.isAccountOwner && (
          <Space>
            <EditPrivilege
              data={record}
              onConfirn={() => {
                getAccountUserList();
              }}
            />
            <ToggleButton
              activeText={"Active"}
              inactiveText={"Deactive"}
              activeValue="ACTIVE"
              value={record.status}
              asyncFunc={async (isActive) => {
                if (isActive) {
                  await disableUser({
                    userId: record.sid,
                  });
                } else {
                  await enableUser({
                    userId: record.sid,
                  });
                }
                getAccountUserList();
              }}
            />
            <DeleteData type={DELETE_TYPE.CHILD_USER} data={record} isReload />
          </Space>
        ),
    },
  ];
  return (
    <>
      <Row>
        <Col span={8}>
          <Form
            form={form}
            name="change-password"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            onFinish={onFinish}
            autoComplete="off"
            initialValues={user}
          >
            <Form.Item label="Account Number" name="accountNumber">
              <Input disabled />
            </Form.Item>
            <Form.Item label="Username" name="email">
              <Input disabled />
            </Form.Item>
            <Form.Item label="Fullname" name="name">
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Current Password"
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your current password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm New Password"
              name="confirmNewPassword"
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("New passwords do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={16}>
          {!permission.isAssistant && (
            <Card className="plan-card m-auto">
              <Typography.Title level={4} className="plan-title">
                {plan.groupName}
              </Typography.Title>
              <Typography.Text type="success">Subscribed</Typography.Text>

              <Typography.Title level={2} className="plan-price">
                {plan.monthlyCost} {plan.currency}
              </Typography.Title>

              {plan.benefit?.map((item) => (
                <p className="benefit-item ">{item}</p>
              ))}
              <Button
                type="primary"
                className="btn"
                onClick={handleOpenModalSubscription}
              >
                Change Subscription
              </Button>
            </Card>
          )}
        </Col>
      </Row>

      <>
        <div className="w-full mt-4">
          {!permission.isAssistant && (
            <Table
              columns={columns}
              dataSource={dataSource}
              loading={isLoadingGetUser}
              size="small"
              pagination={false}
              title={() => (
                <Row gutter={12}>
                  <Col xs={24} md={18}>
                    <Space
                      size={"small"}
                      align="center"
                      justify="start"
                      style={{ width: "100%" }}
                    >
                      <Input.Search
                        placeholder="Username"
                        onSearch={handleOnSearch}
                        onChange={handleOnchangeKeyWord}
                        value={keywordValue}
                        readOnly={!accountId}
                      />
                      <Button
                        type="default"
                        key="clear"
                        onClick={handleClearFilter}
                        disabled={!accountId}
                      >
                        Clear Filter
                      </Button>
                    </Space>
                  </Col>
                  <Col xs={24} md={6}>
                    <Space
                      size={"small"}
                      align="center"
                      justify="end"
                      style={{ width: "100%" }}
                    >
                      <Button
                        type="text"
                        icon={<ReloadOutlined />}
                        onClick={getAccountUserList}
                        disabled={!accountId}
                      >
                        Reload
                      </Button>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={handleCreateUser}
                      >
                        Create
                      </Button>
                    </Space>
                  </Col>
                </Row>
              )}
              footer={() => (
                <Pagination
                  total={total || 0} // total number of items
                  current={currentPage || 1} // current page number
                  pageSize={pageSize || 10} // number of items per page
                  showSizeChanger // allow user to change page size
                  showQuickJumper
                  showTotal={(total) => `Total ${total} items`}
                  onChange={handlePageChange} // handle page changes
                />
              )}
            />
          )}
        </div>

        <Modal
          open={showRegisterUser}
          onCancel={() => setShowRegisterUser(false)}
          footer={null}
          keyboard={false}
        >
          <RegisterUser onCancel={() => setShowRegisterUser(false)} />
        </Modal>

        <UpgradePlan
          open={isShowModalUpgradePlan}
          onCancel={() => {
            setIsShowModalUpgradePlan(false);
          }}
        />
      </>
    </>
  );
};

const EditPrivilege = ({ data, onConfirn }) => {
  const [open, setOpen] = useState(false);
  const [privilege, setPrivilege] = useState(data.privilege || 1);
  const handleChangePrivilege = async (e) => {
    setPrivilege(e);
  };
  return (
    <>
      <Button onClick={() => setOpen(true)} type="primary">
        Privilege
      </Button>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title="Edit Privilege"
        onOk={async () => {
          await changePrivilege({
            userId: data.sid,
            privilege: privilege,
          });
          onConfirn();
          setOpen(false);
        }}
      >
        <Select
          value={privilege}
          className="w-full"
          optionFilterProp="children"
          placeholder="Privilege"
          options={[
            { value: 1, label: "READ" },
            { value: 2, label: "WRITE" },
            { value: 3, label: "DELETE" },
          ]}
          onChange={handleChangePrivilege}
        ></Select>
      </Modal>
    </>
  );
};

export default AccountPage;
