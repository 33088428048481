import React, { useState, useRef, useEffect, useMemo } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import { RouteConfigContext } from "./context";
import Auth from "./Authen/Auth";
import Layout1 from "./pages/components/Layout/Layout1/Layout1";
import { menuItems } from "./configs/navigatorConfig";

import "./styles.css";

const history = createBrowserHistory();
export default function App() {
  const _routerConfigs = useMemo(() => menuItems, []);
  const [customCurrentPageName, setCustomCurrentPageName] = useState({});
  return (
    <RouteConfigContext.Provider
      value={{
        routerConfigs: _routerConfigs,
        customCurrentPageName,
        setCustomCurrentPageName,
      }}
    >
      <Auth>
        <Router history={history}>
          <Layout1 />
        </Router>
      </Auth>
    </RouteConfigContext.Provider>
  );
}
