import React from 'react';
import cronstrue from 'cronstrue';
import { Typography } from 'antd';

const options = {
    use24HourTimeFormat: false,
    dayOfWeekStartIndexZero: false,
    verbose: true
  };

const CronText = ({ cronString }) => {
    if(!cronString){
        return <Typography.Text>No Setting</Typography.Text>
    }
  const text = cronstrue.toString(cronString, options);
  return <Typography.Text strong>{text}</Typography.Text>;
};

export default CronText;