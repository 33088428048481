import React from "react";
import { List, Typography } from "antd";

const imgStyle = { height: 35 };

export default ({ data, type, selected }) => {
  return (
    <List
      className="list-values"
      size="small"
      header={null}
      footer={null}
      type={type}
      bordered
      dataSource={data}
      renderItem={(item, index) => (
        <List.Item
          key={`item-${index}`}
          className={!selected && index !== 0 ? "disabled" : ""}
        >
          {type === "text" || type === "link" ? (
            <Typography.Text>{item.value}</Typography.Text>
          ) : type === "image" && item.imgType === "src" ? (
            <img src={item.value} style={imgStyle} />
          ) : type === "image" && item.imgType === "srcset" ? (
            <img srcset={item.value} style={imgStyle} />
          ) : (
            <img src={item.value} style={imgStyle} />
          )}
        </List.Item>
      )}
    />
  );
};
