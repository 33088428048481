import React from "react";
import { Input } from "antd";

export default ({ dataSource, onChange }) => {
  const [state, setState] = React.useState({
    headers: [],
    data: [],
  });

  React.useEffect(() => {
    const { headers, data } = dataSource;
    setState({
      headers,
      data,
    });
  }, [dataSource]);

  const getData = () => {
    return state;
  };

  const handleInputChange = (e, i) => {
    const { headers } = state;
    headers[i].name = e.target.value;
    const newState = {
      ...state,
      headers,
    };
    setState(newState);
    onChange(newState);
  };

  const { headers, data } = state;

  return (
    <div className="ant-table-wrapper css-byeoj0">
      <div className="ant-table">
        <div className="ant-table-container">
          <div className="ant-table-content">
            <table style={{ tableLayout: "auto" }}>
              <tbody className="ant-table-tbody">
                <tr className="ant-table-row ant-table-row-level-0">
                  {headers.map((v, i) => (
                    <td key={i} className="ant-table-cell">
                      <Input
                        value={v.name}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </td>
                  ))}
                </tr>
                {data.map((row, i) => (
                  <tr key={i} className="ant-table-row ant-table-row-level-0">
                    {row.map((v, j) => (
                      <td key={j} className="ant-table-cell">
                        <span>{v}</span>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
