import axiosCustom from "../../services/base/axiosCustom";

export const getExtractionConfig = (params) => {
  return axiosCustom.get("/conf/getConfig", params);
};
export const getExtractionContent = (params) => {
  return axiosCustom.get("/webapi/extraction/html", params);
};

export const browse = (data) => {
  return axiosCustom.post("/webapi/extraction/browse", data);
};

export const takeScreenshot = (data) => {
  return axiosCustom.post("webapi/extraction/screenshot", data);
};

export const exportJson = (data) => {
  return axiosCustom.post("webapi/exportjson", data);
};

export const exportCsv = (data) => {
  return axiosCustom.post("webapi/exportcsv", data);
};

export const createApi = (data) => {
  return axiosCustom.post("crawler/createApi", data);
};

export const getFileContent = (data) => {
  return axiosCustom.post("file/getFileContent", data);
};
