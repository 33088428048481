import { createAsyncThunk } from "@reduxjs/toolkit";
import { createApi } from "../services";

export default createAsyncThunk("extranction/createApi", async (params) => {
  try {
    const r = await createApi(params);
    return r;
  } catch (e) {
    throw e;
  }
});
