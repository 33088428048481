import React, { useEffect, useState } from "react";
import { Form, Input, Button, notification, Select } from "antd";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import {
  setLoginType,
  changeTab,
  resendCode,
  register,
  verify,
} from "../../store/authSlice";
import { createUser } from "../../services";

const RegisterUser = ({ onCancel }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const authState = useSelector((states) => states.auth);

  const [loading, setLoading] = useState(false);

  const { error, status } = authState.register;

  useEffect(() => {
    if (status === "error") {
      notification.error({
        message: "Error",
        description: error.message,
        duration: 3,
      });
    }
  }, [status]);

  const onRegister = async (values) => {
    setLoading(true);
    try {
      const response = await createUser(values);
      console.log(response);
    } catch (err) {
    } finally {
      setLoading(false);
      onCancel instanceof Function && onCancel();
    }
  };

  return (
    <Form
      name="registerForm"
      initialValues={{ name: "", email: "", password: "" }}
      onFinish={onRegister}
      form={form}
    >
      <h2>Create user</h2>
      <Form.Item
        name="userName"
        rules={[{ required: true, message: "Please input your username." }]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Username"
        />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[{ required: true, message: "Please input your email." }]}
      >
        <Input
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="privilege"
        rules={[
          {
            required: true,
            message: "Required",
          },
        ]}
      >
        <Select
          optionFilterProp="children"
          placeholder="Privilege"
          options={[
            { value: 1, label: "READ" },
            { value: 2, label: "WRITE" },
            { value: 3, label: "DELETE" }
          ]}
        ></Select>
      </Form.Item>
      <Form.Item className="Login-footer">
        <Button type="primary" htmlType="submit" loading={loading}>
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegisterUser;
