import { Avatar, Button, Drawer, Input, Modal, Space } from "antd";
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { getStripeCheckoutSession } from "../../services";
import StripeLogo from "../../assets/images/stripe-favicon.ico";
import AmexLogo from "../../assets/images/amex.svg";
import DinnersLogo from "../../assets/images/diners.svg";
import DiscoverLogo from "../../assets/images/discover.svg";
import JCBLogo from "../../assets/images/jcb.svg";
import MasterCardLogo from "../../assets/images/mastercard.svg";
import UPILogo from "../../assets/images/unionpay.svg";
import VisaLogo from "../../assets/images/visa.svg";

const stripePromise = loadStripe(
  "pk_live_51OXDlnAaPMcml8eS7kFQvMMy73d6iRdH6Sp5SY46fwzTTYwSkGbgPLlUhUOyStSbCsRc34ZAOIDFEqqhLnnj3UMl00AIXpxtiO"
);

const StripeCheckoutPayment = () => {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");

  const createPayment = async () => {
    setLoading(true);
    const response = await getStripeCheckoutSession({ amountTopup: amount });
    console.log("getStripeCheckoutSession", response);
    setLoading(false);

    if (response.data !== "") {
      setOpen(true);
      setClientSecret(response.data);
    }
    // setInvoiceId(response.data.invoiceId);
  };

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return <>Loading...</>;
  }

  if (!loading && !open) {
    return (
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <Input prefix="$" value={amount} onChange={handleChangeAmount} placeholder="Enter your topup amount" />
        <Button onClick={createPayment} type="primary">
          <Avatar size={"small"} src={StripeLogo} /> Top up with Stripe
        </Button>
        <Space direction="horizontal" className="w-full justify-center">
          <Avatar size={"small"} src={AmexLogo} />
          <Avatar size={"small"} src={DinnersLogo} />
          <Avatar size={"small"} src={DiscoverLogo} />
          <Avatar size={"small"} src={JCBLogo} />
          <Avatar size={"small"} src={MasterCardLogo} />
          <Avatar size={"small"} src={UPILogo} />
          <Avatar size={"small"} src={VisaLogo} />
        </Space>
      </div>
    );
  }

  return (
    <Drawer
      title="Full Screen Drawer"
      placement="top"
      closable={false}
      onClose={handleClose}
      open={open}
      width="100vw" // Set the width to 100% of the viewport width
      height="100vh" // Set the width to 100% of the viewport width
      extra={
        <Space>
          <Button onClick={handleClose} type="primary">
            Close
          </Button>
        </Space>
      }
    >
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{ clientSecret }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </Drawer>
  );
};
export default StripeCheckoutPayment;
