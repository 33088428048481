import React, { useState } from "react";
import { Row, Col, Button, Input, Layout, Typography, Upload } from "antd";
import { ArrowRightOutlined, UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./styles.css";
import { setLoginType, changeTab } from "../../../store/authSlice";
import { setInitial, setStatus } from "../slices";
import Menu from "./Menu";

const { Header, Content, Footer } = Layout;

const HomePage = () => {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((states) => states.auth);

  const [url, setUrl] = useState("");

  const uploadProps = {
    fileList: [],
    itemRender: () => null,
    maxCount: 1,
    beforeUpload: (file) => {
      dispatch(
        setInitial({
          file,
          type: "file",
        })
      );
      dispatch(setStatus("loading"));
      navigator("/extraction");
      return false;
    },
  };

  const handleGotoExtraction = () => {
    if (!url) {
      return;
    }
    dispatch(
      setInitial({
        url: url,
        type: "url",
      })
    );
    dispatch(setStatus("loading"));
    navigator("/extraction");
  };

  const handleOnchangeUrl = (e) => {
    setUrl(e.target.value);
  };

  const openLogin = () => {
    dispatch(setLoginType(1));
    dispatch(changeTab("login"));
  };

  const openRegister = () => {
    dispatch(setLoginType(1));
    dispatch(changeTab("register"));
  };

  return (
    <Layout
      style={{
        background: "url('/assets/images/bg.png')",
      }}
      className="Home-page"
    >
      <Header>
        {!authState.isLoggedIn ? (
          <>
            <Button type="primary" onClick={openLogin}>
              Login
            </Button>
            <Button type="primary" onClick={openRegister}>
              Register
            </Button>
          </>
        ) : (
          <Menu />
        )}
      </Header>
      <Content>
        <Row>
          <Col span={12} offset={6}>
            <Row>
              <Col span={24}>
                <img style={{ width: 80 }} src="/assets/images/LOGO_2.png" />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Typography.Title style={{ color: "#fff" }}>
                Dalavi
                </Typography.Title>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="Url-input">
                <Input
                  placeholder="Enter URL"
                  size="large"
                  value={url}
                  onChange={handleOnchangeUrl}
                  onPressEnter={handleGotoExtraction}
                />
                <Button
                  type="primary"
                  icon={<ArrowRightOutlined />}
                  onClick={handleGotoExtraction}
                ></Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Typography.Text style={{ color: "#fff" }}>OR</Typography.Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Upload {...uploadProps}>
                  <Button type="primary" size="large" icon={<UploadOutlined />}>
                    HTML File
                  </Button>
                </Upload>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <Footer />
    </Layout>
  );
};

export default HomePage;
