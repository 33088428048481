import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Button, Switch, Typography, Modal, Drawer, message } from "antd";
import "./PricingPlans.css"; // Custom styles
import { getListPlan, upgradePlan } from "../../../services";
import { refreshLogin } from "../../../store/authSlice";
import { getSubscriptionPlans } from "../../../store/sharedSlice";
const { Title, Text } = Typography;

const UpgradePlan = ({ open, onCancel }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user?.user);
  const { plans } = useSelector((state) => state.shared);

  const [btnSubmitting, setBtnSubmitting] = useState({});

  const handleUpgradePlan = async (plan) => {
    try {
      setBtnSubmitting({ ...btnSubmitting, [plan.id]: true });
      const response = await upgradePlan({ planId: plan.id });
      console.log("handleUpgradePlan", response);
      message.success(`Upgrade to ${plan.name} successfully`);
      const savedUser = JSON.parse(localStorage.getItem("user"));
      await dispatch(refreshLogin(savedUser));
      onCancel instanceof Function && onCancel();
    } catch (err) {
      console.error("handleUpgradePlan", err);
      message.error(`Upgrade to ${plan.name} has error!`);
    } finally {
      setBtnSubmitting({ ...btnSubmitting, [plan.id]: false });
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onCancel}
      width="100vw" // Set the width to 100% of the viewport width
      height="100vh" // Set the width to 100% of the viewport width
      placement="top"
      title="Upgrade your plan"
    >
      <div className="pricing-container">
        <div className="plans">
          {plans?.map((plan) => (
            <Card className="plan-card">
              <Title level={4} className="plan-title">
                {/* MONTHLY <span className="highlight-red">SUBSCRIPTION</span> */}
                {plan.groupName}
              </Title>
              {user?.groups?.some((x) => x === plan.groupName) && (
                <Text type="success">Subscribed</Text>
              )}

              <Title level={2} className="plan-price">
                {plan.monthlyCost} {plan.currency}
              </Title>

              {plan.benefit?.map((item) => (
                <p className="benefit-item ">{item}</p>
              ))}

              {plan.monthlyCost === 0 && (
                <Button
                  type="default"
                  className="btn"
                  loading={btnSubmitting[plan.id]}
                  onClick={() => handleUpgradePlan(plan)}
                >
                  FREE PLAN
                </Button>
              )}
              {plan.monthlyCost > 0 && (
                <Button
                  type="primary"
                  className="btn"
                  loading={btnSubmitting[plan.id]}
                  onClick={() => handleUpgradePlan(plan)}
                >
                  Upgrade
                </Button>
              )}
            </Card>
          ))}
        </div>
      </div>
    </Drawer>
  );
};
export default UpgradePlan;
