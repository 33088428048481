import React, { useCallback, useState } from "react";
import { Handle, Position } from "react-flow-renderer";
import classNames from "classnames";
// import Tooltip from "react-power-tooltip";
import { Popover } from "antd";
import DayJS from "react-dayjs";
import { clone, first } from "lodash";
import "./PipeNode.css";
import withErrorHandling from "../../../HOC/withErrorHandler";
import LogContent from "./LogContent";

const handleStyle = {};

function PipeNode(props) {
  const { data } = props;

  return (
    <Popover content={<LogContent data={data} />} title={data.label}>
      <div
        className={classNames("pipe-node", {
          inprogress: data.status !== "COMPLETED",
        })}
      >
        <Handle
          type="target"
          position={Position.Top}
          className={classNames({
            completed: data.status === "COMPLETED",
            inprogress: data.status !== "COMPLETED",
          })}
        />
        <label htmlFor="text">{data.label}</label>
        <Handle
          type="source"
          position={Position.Bottom}
          style={handleStyle}
          className={classNames({
            completed: data.status === "COMPLETED",
            inprogress: data.status !== "COMPLETED",
          })}
        />
      </div>
    </Popover>
  );
}

export default PipeNode;
