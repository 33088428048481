import React, { memo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Space,
  Table,
  Typography,
  notification,
  Popover,
} from "antd";
import { HistoryOutlined, ProfileOutlined } from "@ant-design/icons";
import { clone, isEmpty, orderBy, sortBy } from "lodash";
import withErrorHandling from "../../../HOC/withErrorHandler";
import { formatDate } from "../../../Utilities";
import { uniqueId } from "lodash";

import "./index.css";
import { getDatasetHistory, getPipelineHistory } from "../../../services";

const PAGE_SIZE = 10;

const columns = [
  {
    title: "Action Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Start Time",
    dataIndex: "startTime",
    key: "startTime",
    render: (_, record) => {
      return <Typography.Text>{formatDate(record.startTime)}</Typography.Text>;
    },
  },
  {
    title: "End Time",
    dataIndex: "endTime",
    key: "endTime",
    render: (_, record) => {
      return <Typography.Text>{formatDate(record.endTime)}</Typography.Text>;
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, record) => (
      <Space>
        <Typography.Text className={`status-${record.status.toLowerCase()}`}>
          {record.status}
        </Typography.Text>

        {!isEmpty(record.logs) && (
          <Popover
            content={
              <Table
                columns={[
                  {
                    title: "",
                    dataIndex: "log",
                    key: "log",
                  },
                  {
                    title: "",
                    dataIndex: "logTime",
                    key: "logTime",
                    render: (_, log) => (
                      <Typography.Text>
                        {formatDate(log.logTime)}
                      </Typography.Text>
                    ),
                  },
                ]}
                dataSource={record.logs}
                size="small"
                showHeader={false}
              />
            }
            title="Action Logs"
            trigger="click"
          >
            <Button type="primary" icon={<ProfileOutlined />}>
              Logs
            </Button>
          </Popover>
        )}
      </Space>
    ),
  },
];

const HistoryLog = (props) => {
  const { type, data } = props;
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalText, setModalText] = useState("");

  const name = data.name || "";

  const showModal = withErrorHandling((e) => {
    if (type === HISTORY_TYPE.PIPELINE) {
      setModalText("Action History");
      _getPipelineHistory(0);
    }

    if (type === HISTORY_TYPE.DATASET) {
      _getDatasetHistory(0);
    }
    setPage(1);
    setOpen(true);
  });

  const _getPipelineHistory = async (page) => {
    setIsLoading(true);
    const response = await getPipelineHistory({
      pipeline_id: data.id,
      page,
      page_size: PAGE_SIZE,
    });
    const { pipelineRuns, total } = response.data;
    setTotal(total);
    setDataSource(buildPipelineHistories(pipelineRuns));
    setIsLoading(false);
  };
  const buildPipelineHistories = withErrorHandling((data) => {
    const dataSourceTemp = data.map((pipelineRun) => {
      let runSet = {
        startTime: !isEmpty(pipelineRun.startTime)
          ? new Date(pipelineRun.startTime)
          : null,
        endTime: !isEmpty(pipelineRun.endTime)
          ? new Date(pipelineRun.endTime)
          : null,
        status: pipelineRun.status,
        id: pipelineRun.id,
        key: pipelineRun.id + uniqueId("runSet"),
        name,
      };

      if (
        pipelineRun.actionRequestRuns &&
        pipelineRun.actionRequestRuns.length
      ) {
        const actionRequestRunSets = pipelineRun.actionRequestRuns.map(
          (item) => {
            const cloned = clone(item);
            return {
              startTime: !isEmpty(cloned.startTime)
                ? new Date(cloned.startTime)
                : null,
              endTime: !isEmpty(cloned.endTime)
                ? new Date(cloned.endTime)
                : null,
              status: cloned.status,
              id: cloned.id,
              key: cloned.id + uniqueId("actionRequestRunSet"),
              name: cloned.actionRequest.actionRequestName,
              logs: orderBy(cloned.actionRequestLogs, ["logTime"], ["asc"]),
            };
          }
        );

        runSet.children = [].concat.apply(
          [],
          [].concat.apply(
            [],
            actionRequestRunSets
          )
        );
      }

      return runSet;
    });
    return orderBy(dataSourceTemp, ["startTime"], ["desc"]);
  });

  const _getDatasetHistory = async (page) => {
    setIsLoading(true);
    const response = await getDatasetHistory({
      dataset_id: data.datasetId,
      page,
      page_size: PAGE_SIZE,
    });
    const { actionRequestRuns, total } = response.data;
    setTotal(total);
    setDataSource(buildDatasetHistories(actionRequestRuns));
    setIsLoading(false);
  };

  const buildDatasetHistories = withErrorHandling((sources) => {
    const dataSourceTemp = sources.map((source) => {
      const runSet = {
        startTime: !isEmpty(source.startTime)
          ? new Date(source.startTime)
          : null,
        endTime: !isEmpty(source.endTime) ? new Date(source.endTime) : null,
        status: source.status,
        id: source.id,
        key: source.id + uniqueId("runSet"),
        name: source.actionRequest.action.actionDisplayName,
        logs: orderBy(source.actionRequestLogs, ["logTime"], ["asc"]),
      };

      // const actionRequestRunSets = source.actionRequestRunSet.map(function (
      //   __
      // ) {
      //   const cloned = clone(__);
      //   return {
      //     startTime: !isEmpty(cloned.startTime)
      //       ? new Date(cloned.startTime)
      //       : null,
      //     endTime: !isEmpty(cloned.endTime) ? new Date(cloned.endTime) : null,
      //     status: cloned.status,
      //     id: cloned.id,
      //     key: cloned.id + uniqueId("actionRequestRunSet"),
      //     name: source.action?.actionDisplayName,
      //     logs: orderBy(cloned.actionRequestLogs, ["logTime"], ["asc"]),
      //   };
      // });
      // runSet.children = [].concat.apply(
      //   [],
      //   [].concat.apply([], actionRequestRunSets)
      // );

      return runSet;
    });
    return orderBy(dataSourceTemp, ["id"], ["desc"]);
  });

  const handleCancel = () => {
    setOpen(false);
  };

  const onPageChange = (e) => {
    setPage(e);
    if (type === HISTORY_TYPE.PIPELINE) {
      setModalText("Action History");
      _getPipelineHistory(e - 1);
    }

    if (type === HISTORY_TYPE.DATASET) {
      _getDatasetHistory(e - 1);
    }
  };

  return (
    <>
      <Button type="primary" icon={<HistoryOutlined />} onClick={showModal} className={props.classn}>
        History
      </Button>
      <Modal
        width={900}
        title={modalText}
        open={open}
        onCancel={handleCancel}
        cancelText="Close"
        footer={[
          <Button key="back" onClick={handleCancel} danger>
            Cancel
          </Button>,
        ]}
      >
        <Table
          columns={columns}
          dataSource={dataSource}
          size="middle"
          loading={isLoading}
          pagination={{
            total,
            onChange: onPageChange,
            showSizeChanger: false,
            current: page,
          }}
        />
      </Modal>
    </>
  );
};

export default memo(HistoryLog);

export const HISTORY_TYPE = {
  PIPELINE: "PIPELINE",
  DATASET: "DATASET",
};
