import React from "react";
import { Checkbox, Form, Input } from "antd";
import SettingButton from "../../Builder/SettingButton";

const FormTextElement = (props) => {
  const { component, rowIndex, colIndex, index } = props;

  return (
    <>
      <Form.Item
        key={component.id}
        label={component.isHideLabel ? "" : component.label}
        tooltip={component.tooltip}
        name={component.apiProperty}
        rules={[
          {
            required: component.isRequired,
            message: component.requiredMessage || "Required field",
          },
        ]}
      >
        <Input placeholder={component.placeholder} />
      </Form.Item>
      <SettingButton
        direction="horizontal"
        position="topRight"
        rowIndex={rowIndex}
        colIndex={colIndex}
        component={component}
        index={index}
      />
    </>
  );
};
export default FormTextElement;
