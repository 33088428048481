import React, { useCallback, useState, useContext } from "react";
import { Handle, Position } from "react-flow-renderer";
import { FaRegTrashAlt } from "react-icons/fa";
import { PipelineContext } from "../../../context";
import "./DefaultPipeNode.css";

const handleStyle = {};

function DefaultPipeNode(props) {
  const {
    nodes,
    edges,
    setNodes,
    setEdges,
    selectedNode,
    showSetting,
    setShowSetting,
  } = useContext(PipelineContext);
  const { data, id } = props;

  const onDeleteNode = (evt, id) => {
    setNodes(nodes.filter((x) => x.id !== id));
    setEdges(edges.filter((x) => x.source !== id || x.target !== id));
  };

  const handleClickNode = () => {
    if (selectedNode?.id == id && !showSetting) {
      setShowSetting(true);
    }
  };

  return (
    <div className="pipe-default-node" onClick={handleClickNode}>
      <button
        className="edgebutton"
        onClick={(event) => onDeleteNode(event, id)}
      >
        <FaRegTrashAlt />
      </button>
      <Handle type="target" position={Position.Top} />
      <label htmlFor="text">{data.label}</label>
      <Handle type="source" position={Position.Bottom} style={handleStyle} />
    </div>
  );
}

export default DefaultPipeNode;
