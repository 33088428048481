import React, {useState, useEffect, useContext} from "react";
import { Button, Space, Tooltip } from "antd";
import {
  CommentOutlined,
  DownloadOutlined,
  EllipsisOutlined,
  HeartOutlined,
  LikeOutlined,
  MailOutlined,
  MobileOutlined,
  ShareAltOutlined,
  StarOutlined,
  WarningOutlined,
  SettingOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { FormDynamicBuilderContext } from "../../../../context/FormDynamicContext";
import { isEmpty } from "lodash";

const SettingButton = ({direction, position, rowIndex, colIndex, component, index}) => {
  const formDynamicBuilderContext = useContext(FormDynamicBuilderContext);
  const { editable, onDeleteComponent, onOpenSetting, actionMetaData } = (formDynamicBuilderContext || {});
  const getPosition = () => {
    switch(position) {
      case "leftCenter": return {
        position: "absolute",
        top: "50%",
        left: -35,
        transform: "translateY(-50%)",
      }
      case "topRight": return {
        position: "absolute",
        top: 0,
        right: 0,
      }
    }
  }
  const handleDeleteComponent = () => {
    if(isEmpty(actionMetaData)) return;
    onDeleteComponent instanceof Function && onDeleteComponent(rowIndex, colIndex, component, index)
  }
  const handleOpenSetting = () => {
    if(isEmpty(actionMetaData)) return;
    onOpenSetting instanceof Function && onOpenSetting(rowIndex, colIndex, component, index)
  }

  if(!editable) return <></>

  return (
    <Space.Compact
      direction={direction || "horizontal"}
      style={getPosition()}
    >
      <Tooltip title="Setting">
        <Button
          icon={<SettingOutlined />}
          onClick={handleOpenSetting}
        />
      </Tooltip>
      <Tooltip title="Delete">
        <Button
          icon={<DeleteOutlined />}
          onClick={handleDeleteComponent}
        />
      </Tooltip>
    </Space.Compact>
  )
}
export default SettingButton;