import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Col, Input, Modal, Pagination, Row, Space, Table } from "antd";
import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import DeleteData, { DELETE_TYPE } from "../components/DeleteData/DeleteData";
import {
  deleteDataRow,
  getDatasetDetailById,
  searchDatasetDetail,
  setPayloadGetDatasetDetail,
  updateDataItem,
} from "../../store/datasetSlice";
import { tryParse, tryParseInt } from "../../Utilities";
import DropdownAction, {
  ACTION_TYPE,
} from "../components/DropdownAction/DropdownAction";
import HistoryLog, { HISTORY_TYPE } from "../components/History/HistoryLog";
import withErrorHandling from "../../HOC/withErrorHandler";
import { getActionCategoryList, getActionList } from "../../store/adminSlice";
import { isEmpty, pick } from "lodash";
import {
  allCrawlers,
  allDataset,
} from "../../store/pipelineSlice";
import { RouteConfigContext, TableEditableContext } from "../../context";
import EditableRow from "../components/Table/EditableRow/EditableRow";
import EditableCell from "../components/Table/EditableCell/EditableCell";

const headers = [
  {
    title: "",
    key: "id",
    render: (_, record) => (
      <DeleteData type={DELETE_TYPE.DATASET_ROW} data={record} isReload />
    ),
    align: "right",
    width: 50,
  },
];

const DataDetail = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { datasetId } = useParams();
  const { customCurrentPageName, setCustomCurrentPageName } =
    useContext(RouteConfigContext);

  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmDeleteData, setConfirmDeleteData] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [keywordValue, setKeywordValue] = useState("");

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const { actions } = useSelector((states) => states.admin?.actions);
  const { list, total, header, datasetName } = useSelector(
    (states) => states.dataset?.datasetObj
  );

  useEffect(() => {
    dispatch(allCrawlers());
    dispatch(allDataset());

    if (isEmpty(actions)) {
      dispatch(getActionCategoryList()).then(() => {
        dispatch(getActionList());
      })
    }
  }, [dispatch]);

  useEffect(() => {
    const parsed = tryParseInt(datasetId);
    if (!parsed.succeed) return;

    handleGetDataset();
  }, [datasetId]);

  useEffect(() => {
    const datas = list?.map((x) => {
      const parser = tryParse(x.data);
      return parser.success
        ? { ...parser.data, id: x.id, key: x.id, datasetId: x.datasetId }
        : { id: x.id, key: x.id, datasetId: x.datasetId };
    });
    setDataSource(datas);

    const customPageName = {
      ...customCurrentPageName,
      [`/datasets/${datasetId}`]: datasetName || "Data",
    };
    setCustomCurrentPageName(customPageName);
  }, [list]);

  useEffect(() => {
    const columnBuilded = header?.map((x) => ({
      title: x,
      dataIndex: x,
      key: x,
      width: 250,
      editable: true,
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: x,
        title: x,
        handleSave: handleSaveCell,
      }),
      sorter: (a, b) => a[x].localeCompare(b[x]),
      ...getColumnSearchProps(x),
    }));
    setColumns([...columnBuilded, ...headers]);
  }, [header]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
    setSearchedColumn("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const handleSaveCell = (data) => {
    dispatch(
      updateDataItem({
        id: data.id,
        sourceName: "DATASET",
        data: JSON.stringify(pick(data, header)),
      })
    ).then(() => {
      handleGetDataset();
    });
  };

  useEffect(() => {
    handleGetDataset();
  }, [pageSize, currentPage, keyword]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleOnSearch = useCallback((e) => {
    setKeyword(e);
  });
  const handleOnchangeKeyWord = (e) => {
    setKeywordValue(e.target.value);
  };

  const handleClearFilter = () => {
    setCurrentPage(1);
    setKeyword("");
    setKeywordValue("");
  };

  const handleGetDataset = useCallback(() => {
    setIsLoading(true);
    const payload = {
      dataset_id: datasetId,
      page: currentPage - 1,
      page_size: pageSize,
      keyword: keyword,
    };
    dispatch(setPayloadGetDatasetDetail(payload));
    if (!isEmpty(keyword)) {
      dispatch(searchDatasetDetail(payload)).then(() => {
        setIsLoading(false);
      });
    } else {
      dispatch(getDatasetDetailById(payload)).then(() => {
        setIsLoading(false);
      });
    }
  }, [currentPage, pageSize, keyword]);

  const handleDeleteMultipleData = withErrorHandling(() => {
    setIsLoading(true);
    dispatch(
      deleteDataRow({
        sourceId: datasetId,
        dataIds: selectedRows?.map((x) => x.id),
      })
    ).then(() => {
      setConfirmDeleteData(false);
      handleGetDataset();
    });
  });

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    onChange: onSelectChange,
    selectedRowKeys: selectedRows.map((row) => row.key),
    selections: [
      {
        key: "id",
        text: "Delete",
        onSelect: (changeableRowKeys) => {
          if (selectedRows.length === 0) return;
          setConfirmDeleteData(true);
        },
      },
    ],
  };

  return (
    <>
      <Table
        className="custom-ant-table"
        bordered
        rowSelection={rowSelection}
        loading={isLoading}
        size="middle"
        dataSource={dataSource}
        columns={columns}
        pagination={false} // hide default pagination
        scroll={{
          x: "70vw",
          y: "65vh",
        }}
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        title={() => (
          <Row gutter={12}>
            <Col xs={24} md={18}>
              <Space
                size={"small"}
                align="center"
                justify="start"
                style={{ width: "100%" }}
              >
                <Button
                  type="default"
                  key="back"
                  onClick={() => {
                    navigator("/datasets");
                  }}
                >
                  Back
                </Button>
                <HistoryLog
                  type={HISTORY_TYPE.DATASET}
                  data={{ datasetId: datasetId }}
                />
                <DropdownAction
                  type={ACTION_TYPE.DATASET}
                  data={{
                    id: datasetId,
                    columns: header,
                  }}
                />

                <Input.Search
                  placeholder="Keyword"
                  onSearch={handleOnSearch}
                  onChange={handleOnchangeKeyWord}
                  value={keywordValue}
                />

                <Button type="default" key="clear" onClick={handleClearFilter}>
                  Clear Filter
                </Button>
              </Space>
            </Col>
            <Col xs={24} md={6}>
              <Space
                size={"small"}
                align="center"
                style={{ width: "100%", justifyContent: "end" }}
              >
                <Button
                  type="text"
                  icon={<ReloadOutlined />}
                  onClick={handleGetDataset}
                >
                  Reload
                </Button>
              </Space>
            </Col>
          </Row>
        )}
        footer={() => (
          <Pagination
            total={total || 0} // total number of items
            current={currentPage || 1} // current page number
            pageSize={pageSize || 10} // number of items per page
            showSizeChanger // allow user to change page size
            showQuickJumper
            showTotal={(total) => `Total ${total} items`}
            onChange={handlePageChange} // handle page changes
          />
        )}
      />

      <Modal
        title="Confirm"
        open={confirmDeleteData}
        onOk={handleDeleteMultipleData}
        onCancel={() => setConfirmDeleteData(false)}
        okText="Yes, delete it!"
        cancelText="No"
      >
        <p>{`Are you source delete ${selectedRows.length} data?`}</p>
      </Modal>
    </>
  );
};
export default DataDetail;
