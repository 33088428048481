import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Space, Table, Tooltip, Typography } from "antd";
import { EditFilled } from "@ant-design/icons";

import DownloadButton from "./widgets/DownloadButton/DownloadButton";
import ScheduleSetting from "../components/ScheduleSetting";
import CloneCrawler from "./widgets/CloneCrawler";
import DeleteData, { DELETE_TYPE } from "../components/DeleteData/DeleteData";
import {
  getCrawlerList,
  setPayloadGetCrawlerList,
} from "../../store/crawlerSlice";
import { formatDate } from "../../Utilities";
import TableToolBar from "./TableToolBar";

const columns = [
  {
    title: "Tracking name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => (
      <Space direction="vertical">
        <Link to={`/crawlers/${record.item.id}`}>{text}</Link>
      </Space>
    ),
    align: "left",
    width: "35%",
  },
  {
    title: "Create Date",
    dataIndex: "createDate",
    key: "createDate",
    render: (text, record) => (
      <Typography.Text>{formatDate(record.item.createDate)}</Typography.Text>
    ),
    align: "center",
    width: "20%",
  },
  {
    title: "Download",
    dataIndex: "createDate",
    key: "createDate",
    render: (text, record) => <DownloadButton type="link" data={record.item} />,
    align: "center",
    width: "15%",
  },
  // {
  //   title: "Schedule",
  //   key: "schedule",
  //   render: (_, record) => <ScheduleSetting data={record.item} />,
  //   align: "center",
  //   width: "10%",
  // },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Space>
        <Tooltip title="Edit">
          <Link to={`/crawlers/brower/${record.item.id}`}>
            <Button type="primary" icon={<EditFilled />}></Button>
          </Link>
        </Tooltip>
        <CloneCrawler crawler={record.item} />
        <DeleteData type={DELETE_TYPE.CRAWLER} data={record.item} isReload />
      </Space>
    ),
    align: "right",
  },
];

const CrawlerList = () => {
  const dispatch = useDispatch();

  const { list: crawlers, total } = useSelector(
    (states) => states.crawler?.crawlersObj
  );

  const [isLoading, setIsLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [isGetShared, setIsGetShared] = useState(false);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    handleGetCrawlerList();
  }, [dispatch, page]);

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    } else {
      handleGetCrawlerList();
    }
  }, [isGetShared, keyword]);

  useEffect(() => {
    setDataSource(crawlers);
  }, [crawlers]);

  const handleGetCrawlerList = useCallback(() => {
    setIsLoading(true);
    const payload = {
      isOwn: !isGetShared,
      keyword: keyword,
      page_size: 10,
      page,
    };
    dispatch(setPayloadGetCrawlerList(payload));
    dispatch(getCrawlerList(payload)).then(() => {
      setIsLoading(false);
    });
  }, [isGetShared, keyword, page]);

  const handleGetShared = useCallback((e) => {
    setIsGetShared(e.target.checked);
  });

  const handleOnSearch = useCallback((e) => {
    setKeyword(e);
  });
  const handleClearFilter = useCallback((e) => {
    setKeyword("");
    setIsGetShared(false);
  });

  return (
    <>
      <Table
        className="custom-ant-table"
        bordered
        scroll={{
          x: "70vw",
          y: "65vh",
        }}
        loading={isLoading}
        size="middle"
        dataSource={dataSource}
        columns={columns}
        title={() => (
          <TableToolBar
            onChangeShared={handleGetShared}
            onSearch={handleOnSearch}
            onReload={handleGetCrawlerList}
            onClearFilter={handleClearFilter}
            keyword={keyword}
            isShared={isGetShared}
          />
        )}
        pagination={{
          total,
          current: page + 1,
          onChange: (e) => setPage(e - 1),
          showSizeChanger: false,
        }}
      />
    </>
  );
};
export default CrawlerList;
