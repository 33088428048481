import React, { useState, useEffect } from "react";
import { Checkbox, Form, Input, InputNumber, Tabs } from "antd";

const ApiSetting = ({ component }) => {
  return (
    <>
      <Form.Item
        label="Property Name"
        name="apiProperty"
        rules={[
          {
            required: true,
            message: "Required!",
          },
        ]}
        style={{marginBottom: 5}}
      >
        <Input />
      </Form.Item>
    </>
  );
};
export default ApiSetting;
