import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import clsx from "clsx";
import { Spin } from "antd";

function CustomLoading(props) {
  const [showLoading, setShowLoading] = useState(!props.delay);

  setTimeout(() => {
    setShowLoading(true);
  }, props.delay);

  return (
    <div
      className={clsx(
        "flex flex-1 flex-col items-center justify-center p-24",
        !showLoading && "hidden"
      )}
    >
      <span>Đang tải dữ liệu...</span>
      <Spin tip="Loading" size="large">
        <div className="content" />
      </Spin>
    </div>
  );
}

CustomLoading.propTypes = {
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

CustomLoading.defaultProps = {
  delay: false,
};

export default CustomLoading;
