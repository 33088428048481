import React, { useState, useEffect, useCallback, memo } from "react";
import {
  ReloadOutlined,
  PlusOutlined,
  RollbackOutlined,
  DownOutlined,
  EditOutlined,
  SaveFilled,
} from "@ant-design/icons";
import withErrorHandling from "../../../../HOC/withErrorHandler";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, Modal, Select, Typography, notification } from "antd";
import { getDatasetList, saveDatasetItem } from "../../../../store/datasetSlice";
import { isEmpty } from "lodash";
import { allDataset } from "../../../../store/pipelineSlice";
const CreateDatasetModal = (props) => {
  const { isCreate, dataset } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [author, setAuthor] = useState();

  const { user } = useSelector((states) => states.auth);
  const { payload } = useSelector((states) => states.dataset?.datasetsObj);

  const showModal = (e) => {
    form.setFieldsValue({
      name: dataset?.name,
      columns: dataset?.columns?.split(',').filter(x => !isEmpty(x)),
    });

    if (isCreate) {
      setAuthor(user?.user);
    } else {
      setAuthor(dataset?.createdBy);
    }

    setOpen(true);
  };

  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleSaveDataset = useCallback(
    withErrorHandling((values) => {
      setConfirmLoading(true);
      const params = {
        ...dataset,
        name: values.name,
        columns: values.columns?.filter(x => !isEmpty(x)).join(',')
      };
      dispatch(saveDatasetItem(params)).then(res => {
        notification.success({
          message: `Save dataset ${params.name || 'No name'} successful`
        })
        setConfirmLoading(false);
        setOpen(false);
        dispatch(getDatasetList(payload));
        dispatch(allDataset());
      })
    }),
    [form]
  );

  return (
    <>
      <Button
        type="primary"
        icon={isCreate ? <PlusOutlined /> : <EditOutlined />}
        onClick={showModal}
      >
        {isCreate ? "Create" : "Edit"}
      </Button>
      <Modal
        title={
          isCreate
            ? "Create new dataset"
            : `Edit dataset ${dataset?.name || "No name"}`
        }
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Save"
        okButtonProps={{
          icon: (<SaveFilled />),
          key:"submit"
        }}
        cancelButtonProps={{
          key: "back",
          danger: true
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSaveDataset}
          initialValues={{}}
        >
          <Form.Item
            key="datasetName"
            label="Dataset name"
            name="name"
            rules={[
              {
                required: true,
                message: "Required field",
              },
            ]}
          >
            <Input placeholder="Dataset name" />
          </Form.Item>

          <Form.Item
            label="Columns"
            name="columns"
          >
            <Select placeholder="Columns" mode="tags"></Select>
          </Form.Item>

          <Form.Item key="creator" name="creator">
            <Typography.Text>
              Created by - {`${author?.name} - ${author?.email}`}
            </Typography.Text>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default memo(CreateDatasetModal);
