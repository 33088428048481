import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import * as _ from "lodash";
import { v4 } from "uuid";
import html2canvas from "html2canvas";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Tabs,
  Checkbox,
  Button,
  notification,
} from "antd";

import { setStatus, addProperty, updatePages, setPreview } from "./../slices";
import { default as browseAction } from "./../slices/browse";
import { default as takeScreenshotAction } from "./../slices/takeScreenshot";
import * as utils from "../utils";
import ListValues from "./ListValues";
import TableValues from "./TableValues";

const Iframe = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const propertyName = Form.useWatch("name", form);
  const config = useSelector((states) => states.extraction.config);
  const page = useSelector((states) => states.extraction.currentPage);
  const pages = useSelector((states) => states.extraction.pages);
  const mode = useSelector((states) => states.extraction.mode);
  const frame = useRef(null);
  const configRef = useRef(config);
  const pagesRef = useRef(pages);
  const pageRef = useRef(page);
  const modeRef = useRef(mode);
  const currentNodeRef = useRef(0);
  const propertiesRef = useRef(page.properties);
  const tableValueRef = useRef({});
  const actionsRef = useRef([]);
  const browseIdRef = useRef(null);
  const selectableRef = useRef(false);
  const [state, setState] = useState({
    isModalOpen: false,
    tabs: [],
    property: {},
    tabIndex: 0,
    activeKey: null,
    isImageModalOpen: false,
    imageData: null,
  });

  const getNumberOfRecommendItem = (url) => {
    let recommendItem = 5;
    const config = configRef.current;
    const checkDomain = url.match(/^http(s)?:\/\/(www\.)?(((?!\/).)*)/);
    if (config && config.domain && checkDomain && checkDomain[3]) {
      for (let domain in config.domain) {
        if (checkDomain[3].indexOf(domain !== -1)) {
          recommendItem = config.domain[domain];
          break;
        }
      }
    }
    return recommendItem;
  };

  const browse = (e) => {
    const r = utils.browse(
      e,
      pagesRef.current,
      pageRef.current,
      modeRef.current,
      actionsRef.current,
      browseIdRef.current
    );
    if (r) {
      const { data, currentPage, actions, browseId } = r;
      if (currentPage) {
        dispatch(updatePages(currentPage));
      }
      if (actions) {
        actionsRef.current = actions;
      }
      if (typeof browseId !== "undefined") {
        browseIdRef.current = browseId;
      }
      if (data) {
        const v = utils.validateData(data);
        dispatch(
          browseAction({
            data: v,
            browseId,
          })
        );
      }
    }
  };

  const handleWindowMessage = (e) => {
    if (e && e.data) {
      switch (e.data) {
        case "extraction_scrolling":
          scroll(e);
          break;
        case "extraction_take_screenshot":
          takeScreenshot(e);
          break;
        default:
          break;
      }
    }
  };

  const scroll = () => {
    const jdoc = $(frame.current.contentWindow.document);
    const r = utils.scroll(
      jdoc,
      pagesRef.current,
      pageRef.current,
      actionsRef.current,
      browseIdRef.current
    );
    if (r) {
      const { data, currentPage, actions, browseId } = r;
      dispatch(updatePages(currentPage));
      actionsRef.current = actions;
      browseIdRef.current = browseId;
      const v = utils.validateData(data);
      dispatch(
        browseAction({
          data: v,
          browseId,
        })
      );
    }
  };

  const takeScreenshot = () => {
    const currentPage = pageRef.current;
    const pages = pagesRef.current;
    const parents = utils.findParent(pages, currentPage);
    parents.push({
      id: currentPage.id,
      browseId: currentPage.browseId,
      url: currentPage.url,
      userBrowses: [],
    });
    const data = utils.buildHierarchy(parents);
    dispatch(takeScreenshotAction(utils.validateData(_.cloneDeep(data))));
  };

  const checkEmptyProperty = (properties) => {
    return properties.length && properties[properties.length - 1].empty;
  };

  const getRecommendItems = (
    doc,
    property,
    currentElm,
    selector,
    selectorArr,
    loop,
    maxLoop,
    tryOther
  ) => {
    const recommendItem = getNumberOfRecommendItem(property.url);
    const values = [];
    const found = property.found || selectorArr.length;
    for (let i = found - 1; i >= 0; i--) {
      var cloneArr = [].concat(selectorArr);
      cloneArr[i] = cloneArr[i].split(":")[0];
      const mSelector = cloneArr.join(" > ");
      const elms = doc.find(mSelector);
      if (
        elms.length >= recommendItem &&
        property.values.length + 1 < elms.length
      ) {
        elms.each((_, e) => {
          if (currentElm[0] !== $(e)[0]) {
            values.push(utils.getPropertyValue($(e)));
          }
        });
        selectorArr = [].concat(cloneArr);
        property.pathInfer = mSelector;
        property.found = i;
        property.values = values;
        if (!property.firstFound) property.firstFound = i;
        break;
      } else if (tryOther) {
        selectorArr = selector.split(" > ");
      }
    }
    loop++;
    if (loop < maxLoop) {
      getRecommendItems(
        doc,
        property,
        currentElm,
        selector,
        selectorArr,
        loop,
        maxLoop
      );
    }
    return;
  };

  const detectPropertyValue = (e) => {
    const mode = modeRef.current;
    const properties = propertiesRef.current;
    const page = pageRef.current;
    switch (mode) {
      case 4:
        return;
      case 1:
      case 3:
        browse(e);
        return;
      default:
        break;
    }
    if (!checkEmptyProperty(properties)) {
      notification.warning({
        message: "Warning",
        description: `Please click "+" button to add a property before select data...`,
        duration: 2.5,
      });
      return;
    }
    try {
      const selector = utils.toCssSelector($(e.target)[0]);
      const property = {
        propertyName: null,
        url: page.url,
        path: selector,
        pathInfer: null,
        values: [],
      };
      const jdoc = $(frame.current.contentWindow.document);
      const currentElm = jdoc.find(selector);
      if (!currentElm.length) {
        throw false;
      }
      const currentValue = utils.getPropertyValue(currentElm);
      const table = utils.getTableData(currentElm);
      if (table) {
        property.table = table;
      }

      const selectorArr = selector.split(" > ");
      getRecommendItems(
        jdoc,
        property,
        currentElm,
        selector,
        [...selectorArr],
        0,
        1
      );
      property.values.unshift(currentValue);
      property.values = _.map(property.values, (p) => {
        p.id = v4();
        return p;
      });
      _.remove(property.values, (v) => {
        return !v.text && !v.link && !v.background && !v.image;
      });
      if (!property.values.length) {
        throw false;
      }
      property.url = page.url;
      property.text = property.values[0].text;
      updatePropertyState(property);
    } catch (e) {
      notification.error({
        message: "Error",
        description: `Sorry, we cannot detect any text....`,
        duration: 2.5,
      });
    }
  };

  const handleIframeClick = (e) => {
    if (selectableRef.current) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    detectPropertyValue(e);
  };

  const getCommonParent = _.debounce(() => {
    const jdoc = $(frame.current.contentWindow.document);
    const matches = jdoc
      .find(".ui-selectee.ui-selected")
      // .find(".ui-selected:not(:has(*))")
      .filter(function () {
        return (
          ($(this).text() || $(this).val()) &&
          $(this)[0].tagName !== "STYLE" &&
          $(this)[0].tagName !== "style"
        );
      })
      .filter(function () {
        const children = $(this).children(".ui-selectee.ui-selected");
        return !children.length || !children.text();
      });
    let commonParent;
    if (matches.length === 1) {
      commonParent = matches;
    } else {
      commonParent = matches
        .first()
        .parents()
        .filter(function () {
          return $(this).find(matches).length == matches.length;
        })
        .first();
    }
    var node = commonParent[0];
    detectPropertyValue({
      target: node,
    });
  }, 1000);

  const attachEvents = () => {
    const doc = frame.current.contentWindow.document;
    /* append css for ui-select helper */
    var css =
      ".ui-selectable-helper { position: absolute; z-index: 9999; border: 1px dotted black; background-color: rgba(24,136,222, 0.3);}";
    css +=
      ".scraper-hide {width: 0 !important;height: 0 !important;display: none !important;overflow: hidden !important;}";
    var head = doc.head || doc.getElementsByTagName("head")[0];
    var style = doc.createElement("style");

    head.appendChild(style);
    style.type = "text/css";
    style.appendChild(doc.createTextNode(css));
    /* end */
    doc.querySelectorAll("*").forEach((elm) => {
      elm.addEventListener("click", handleIframeClick);
    });
    var jdoc = $(doc);
    jdoc.find("body").selectable({
      delay: 10,
      appendTo: jdoc.find("body"),
      start: (e) => {
        e.preventDefault();
        selectableRef.current = true;
        jdoc.find("*").each(function (i, e) {
          var t = $(e);
          var css = getComputedStyle(t[0]);
          if (
            css.opacity === 0 ||
            css.visibility === "hidden" ||
            css.display === "none"
          ) {
            t.addClass("scraper-hide");
          }
        });
      },
      selected: () => {
        getCommonParent();
      },
      stop: () => {
        setTimeout(function () {
          selectableRef.current = false;
          jdoc.find("*").each(function (i, e) {
            var t = $(e);
            var css = getComputedStyle(t[0]);
            if (
              css.opacity === 0 ||
              css.visibility === "hidden" ||
              css.display === "none"
            ) {
              t.removeClass("scraper-hide");
            }
          });
        }, 1000);
      },
    });
    jdoc.find("select").change(function (e) {
      var action = utils.createAction(e.target, "listbox");
      var idx = _.findIndex(actionsRef.current, function (a) {
        return a.cssSelector === action.cssSelector;
      });
      if (idx === -1) {
        action.actionOrder = actionsRef.current.length + 1;
        actionsRef.current.push(action);
      } else {
        actionsRef.current[idx].inputValue = action.inputValue;
      }
    });
    jdoc.find("*").keyup((e) => {
      if (modeRef.current === 1) {
        browse(e);
      }
    });
  };

  useEffect(() => {
    if (frame.current) {
      frame.current.addEventListener("load", () => {
        attachEvents();
        if (pageRef.current.content) {
          dispatch(setStatus("idle"));
        }
        dispatch(updatePages(pageRef.current));
        actionsRef.current = [];
        browseIdRef.current = null;
      });
    }

    window.addEventListener("message", handleWindowMessage);
    return () => {
      window.removeEventListener("message", handleWindowMessage);
    };
  }, []);

  useEffect(() => {
    modeRef.current = mode;
  }, [mode]);

  useEffect(() => {
    propertiesRef.current = page.properties;
  }, [page.properties]);

  useEffect(() => {
    if (page.screenshots && page.screenshots.length) {
      const screenshot = page.screenshots[page.screenshots.length - 1];
      setState({
        ...state,
        isImageModalOpen: true,
        imageData: screenshot.data,
      });
    }
  }, [page.screenshots]);

  useEffect(() => {
    if (frame.current && page.content) {
      dispatch(setStatus("loading"));
      frame.current.contentWindow.document.open();
      frame.current.contentWindow.document.write(page.content);
      frame.current.contentWindow.document.close();
    }
  }, [page.content]);

  useEffect(() => {
    pagesRef.current = pages;
  }, [pages]);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  useEffect(() => {
    configRef.current = config;
  }, [config]);

  const handleModalCancel = () => {
    setState({
      ...state,
      isModalOpen: false,
      activeKey: null,
    });
    form.setFieldValue("name", "");
  };

  const handleModalImageCancel = () => {
    setState({
      ...state,
      isImageModalOpen: false,
      imageData: null,
    });
  };

  const handleModalImageOk = () => {
    const validPages = utils.validate(pagesRef.current);
    if (!validPages.length) {
      notification.error({
        message: "Error",
        description: `Please add add property and make sure all property have the name and values.`,
        duration: 3,
      });
      return;
    }
    dispatch(
      setPreview({
        pages: validPages,
        type: "screenshot",
        screenshot: state.imageData,
      })
    );
    handleModalCancel();
    navigate("/extraction/preview");
  };

  const updatePropertyState = (property) => {
    const tabs = [];
    _.forOwn(property.values[0], (value, key) => {
      if (
        key !== "id" &&
        value !== null &&
        key !== "imgType" &&
        key !== "path"
      ) {
        const values = _.filter(
          _.map(property.values, (e, i) => {
            return {
              id: e.id,
              value: e[key],
              imgType: e.imgType,
              path: e.path,
              selected: i === 0,
            };
          }),
          (e) => e && e.value && e.value.trim().length > 0
        );
        if (values.length) {
          tabs.push({
            key,
            label: key,
            all: false,
            values,
            children: <ListValues data={values} type={key} selected={false} />,
          });
        }
      }
    });

    if (property.table) {
      const data = _.cloneDeep(property.table);
      tabs.push({
        key: "table",
        label:
          property.table.type === "description-list"
            ? "description list"
            : "table",
        data,
        path: property.table.path,
        children: (
          <TableValues dataSource={data} onChange={handleTableValuesChange} />
        ),
      });
    }
    setState({
      ...state,
      isModalOpen: true,
      tabs,
      tabIndex: 0,
      property,
      activeKey: tabs[0].key,
    });
  };

  const handleTryOther = () => {
    const { property } = state;
    const page = pageRef.current;
    const newProperty = {
      url: page.url,
      path: property.path,
      pathInfer: null,
      values: [],
    };

    const jdoc = $(frame.current.contentWindow.document);
    const selector = property.path;
    const currentElm = jdoc.find(selector);
    const currentValue = utils.getPropertyValue(currentElm);
    const selectorArr = selector.split(" > ");
    currentNodeRef.current = property.firstFound || 0;
    if (currentNodeRef.current === 0 && property.firstFound) {
      currentNodeRef.current = property.firstFound;
    }
    // currentNodeRef.current--;
    // selectorArr[currentNodeRef.current] =
    //   selectorArr[currentNodeRef.current].split(":")[0];
    newProperty.found = currentNodeRef.current;
    getRecommendItems(
      jdoc,
      newProperty,
      currentElm,
      selector,
      selectorArr,
      0,
      1,
      true
    );
    if (newProperty.values.length) {
      newProperty.values.unshift(currentValue);
      newProperty.values = _.map(newProperty.values, (p) => {
        p.id = v4();
        return p;
      });
      property.values = [].concat(newProperty.values);
      property.pathInfer = newProperty.pathInfer;
      updatePropertyState(property);
    } else {
      notification.info({
        message: "Info",
        description: `No more recommended text found.`,
        duration: 2.5,
      });
    }
  };

  const handleRecommendedText = (e) => {
    const { tabs, tabIndex } = state;
    const { values, key } = tabs[tabIndex];
    tabs[tabIndex].all = e.target.checked;
    tabs[tabIndex].children = (
      <ListValues data={values} type={key} selected={tabs[tabIndex].all} />
    );
    setState({
      ...state,
      tabs,
    });
  };

  const handleTableValuesChange = (e) => {
    const { headers } = e;
    tableValueRef.current = {
      headers,
    };
  };

  const handleTabChange = (key) => {
    const { tabs } = state;
    const tabIndex = tabs.findIndex((tab) => tab.key === key);
    setState({
      ...state,
      tabIndex,
      activeKey: tabs[tabIndex].key,
    });
  };

  const handleAddProperty = () => {
    const { tabs, tabIndex } = state;
    const tab = tabs[tabIndex];
    const name = form
      .getFieldValue("name")
      .trim()
      .replace(/(\s|\W)+/g, "_");
    const existed = _.find(propertiesRef.current, (p) => p.name === name);
    if (existed) {
      notification.error({
        message: "Duplicate property",
        description: `The property with name ${name} was exists, please select another.`,
        duration: 2.5,
      });
      return;
    }

    if (tab.key === "table") {
      const p = {
        id: v4(),
        name,
        values: [],
        multiple: false,
        type: tab.data.type,
        pathInfer: null,
        path: tab.path,
        text: "",
        url: property.url,
        color: utils.randomColor(),
        empty: false,
        headers: tab.data.headers,
      };

      if (tableValueRef.current && tableValueRef.current.headers) {
        p.headers = tableValueRef.current.headers;
      }

      if (tab.screenshot) {
        p.type = "screenshot";
      }

      handleModalCancel();
      dispatch(addProperty(p));
    } else {
      let values = [];
      if (tab.all) {
        values = tab.values.map(({ selected, ...data }) => data);
      } else {
        values = [tab.values[0]].map(({ selected, ...data }) => data);
      }
      const p = {
        id: v4(),
        name,
        values,
        multiple: tab.all,
        type: tab.key,
        pathInfer: tab.all ? property.pathInfer : null,
        path: values[0].path[tab.key],
        text: tab.key === "text" ? values[0].value : "",
        url: property.url,
        color: utils.randomColor(),
        empty: false,
      };
      if (tab.screenshot) {
        p.type = "screenshot";
      }
      handleModalCancel();
      dispatch(addProperty(p));
    }
  };

  const handleScreenshot = (e) => {
    const { tabs, tabIndex } = state;
    tabs[tabIndex].screenshot = e.target.checked;
    setState({
      ...state,
      tabs,
    });

    setState({
      ...state,
      screenshot: e.target.checked,
    });
  };

  const handlePreviewScreenshot = async () => {
    dispatch(setStatus("loading"));
    const { tabs, tabIndex, property } = state;
    const tab = tabs[tabIndex];
    let selector = "";
    const doc = frame.current.contentWindow.document;

    if (tab.key == "table") {
      selector = tab.path;
    } else {
      if (tab.all) {
        const e1 = doc.querySelector(tab.values[0].path[tab.key]);
        const e2 = doc.querySelector(tab.values[2].path[tab.key]);
        const commonParent = $(e1).parents().has(e2).first();
        selector = utils.toCssSelector(commonParent[0]);
      } else {
        selector = property.path;
      }
    }

    const container = doc.querySelector(selector);
    let svgElems = Array.from(container.getElementsByTagName("svg"));
    for (let svgElement of svgElems) {
      this.recurseElementChildren(svgElement);
    }
    const canvas = await html2canvas(container, {
      width: container.clientWidth,
      height: container.clientHeight,
      logging: false,
      timeout: 0,
    });
    dispatch(setStatus("idle"));
    const uri = canvas.toDataURL();
    setState({
      ...state,
      isImageModalOpen: true,
      imageData: uri,
    });
  };

  const {
    isModalOpen,
    isImageModalOpen,
    imageData,
    tabs,
    tabIndex,
    activeKey,
    property,
  } = state;

  return (
    <>
      <iframe ref={frame} src="" />
      <Modal
        title="Extract details"
        open={isModalOpen}
        onCancel={handleModalCancel}
        onOk={handleAddProperty}
        okButtonProps={{
          disabled: !propertyName || !propertyName.replace(/\s+/, "").trim(),
        }}
        width={680}
        keyboard={false}
        maskClosable={false}
        closeIcon={false}
        className="data-modal"
      >
        <Form
          form={form}
          name="extractForm"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          initialValues={{
            remember: true,
          }}
          onFinish={handleAddProperty}
          layout="vertical"
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
            label="Property"
          >
            <Input />
          </Form.Item>
          <Form.Item>
            {tabs.length && tabs[tabIndex].key !== "table" ? (
              <Row>
                <Col span={12}>
                  <Checkbox
                    checked={tabs.length && tabs[tabIndex].all}
                    onChange={handleRecommendedText}
                  >
                    Include recommended text
                  </Checkbox>
                </Col>
                <Col span={12}>
                  {property.pathInfer ? (
                    <Button
                      onClick={handleTryOther}
                      type="primary"
                      style={{ float: "right" }}
                    >
                      Try other
                    </Button>
                  ) : null}
                </Col>
              </Row>
            ) : null}
          </Form.Item>
          <Form.Item>
            {activeKey != null ? (
              <Tabs
                activeKey={activeKey}
                items={tabs}
                onChange={handleTabChange}
              />
            ) : null}
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={tabs.length && tabs[tabIndex].screenshot}
              onChange={handleScreenshot}
            >
              Save as Screenshot
            </Checkbox>
            <span
              className="pointer"
              onClick={handlePreviewScreenshot}
              style={{
                color: "#1677ff",
                textDecoration: "underline",
              }}
            >
              Preview
            </span>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={680}
        title="Screenshot"
        open={isImageModalOpen}
        className="screenshot"
        onCancel={handleModalImageCancel}
        onOk={handleModalImageCancel}
        keyboard={false}
        maskClosable={false}
        closeIcon={false}
      >
        <img src={imageData} style={{ maxWidth: "100%", maxHeight: "100%" }} />
      </Modal>
    </>
  );
};

export default Iframe;
