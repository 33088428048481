import React from "react";
import { Link } from "react-router-dom";
import { Space, Typography } from "antd";
import CronText from "../components/CronText";
import RunPipeline from "../components/RunPipeline/RunPipeline";
import DeleteData, { DELETE_TYPE } from "../components/DeleteData/DeleteData";
import HistoryLog, { HISTORY_TYPE } from "../components/History/HistoryLog";
import ClonePipeline from "./ClonePipeline";
export default ({ handleGetPipelines }) => [
  {
    title: "Pipeline Name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => (
      <Space direction="vertical">
        <Link to={`/pipelines/${record.id}`}>{text}</Link>
        <Typography.Text type="secondary">{record.description}</Typography.Text>
      </Space>
    ),
    align: "left",
    width: "50%",
  },
  {
    title: "Schedules",
    dataIndex: "schedules",
    key: "schedules",
    render: (text, record) => {
      let i = 0;
      return record.schedules.map((schedule) => {
        if (schedule.status === 1 && i < 2) {
          i++;
          return (
            <>
              <CronText cronString={schedule?.crons} /> {i > 0 && <br />}
            </>
          );
        } else if (i === 2) {
          return <Typography.Text strong>and {record.schedules.filter(x => x.status === 1).length - 2}<sup>+</sup></Typography.Text>;
        } else {
          return "";
        }
      });
    },
    align: "left",
    width: "30%",
  },
  {
    title: "",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <RunPipeline btnText="Run" pipeline={record} />
        <ClonePipeline pipeline={record} onSuccess={handleGetPipelines} />
        <HistoryLog type={HISTORY_TYPE.PIPELINE} data={record} />
        <DeleteData type={DELETE_TYPE.PIPELINE} data={record} isReload />
      </Space>
    ),
    align: "right",
  },
];
