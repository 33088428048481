import React, { useState, useEffect } from "react";
import { Checkbox, Form, Input, Tabs } from "antd";

const DisplaySetting = ({ component, showPlaceholder, children }) => {
  return (
    <>
      <Form.Item
        label="Label"
        name="label"
        style={{ marginBottom: 10 }}
        rules={[
          {
            required: true,
            message: "Required!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="isHideLabel"
        style={{ marginBottom: 10 }}
        valuePropName="checked"
      >
        <Checkbox>Hide Label</Checkbox>
      </Form.Item>
      {showPlaceholder && (
        <Form.Item
          label="Placeholder"
          name="placeholder"
          style={{ marginBottom: 10 }}
        >
          <Input />
        </Form.Item>
      )}
      <Form.Item label="Tooltip" name="tooltip" style={{ marginBottom: 10 }}>
        <Input />
      </Form.Item>
      <Form.Item label="Order" name="order" style={{ marginBottom: 10 }}>
        <Input />
      </Form.Item>

      {children}
    </>
  );
};
export default DisplaySetting;
