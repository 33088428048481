import { notification } from "antd";
import { API_URL } from "../services";
import { autoLogin, notLogin, pushReturnAction } from "../store/authSlice";
import { isEmpty } from "lodash";

// Middleware to check login status and stop the action if not logged in
const requireLogin =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (
      action.type.includes(API_URL.LOGIN) ||
      action.type.includes("fulfilled") ||
      action.type === "authSlice/autoLogin" ||
      action.type === "authSlice/pushReturnAction" ||
      action.type.includes("aws/refresh-token")
    ) {
      return next(action);
    }

    const { isLoggedIn } = getState().auth;
    if (!isLoggedIn) {
      let savedUser = {};
      try {
        savedUser = JSON.parse(localStorage.getItem("user"));
      } catch (e) {
        savedUser = {};
      }
      if (!isEmpty(savedUser)) {
        dispatch(autoLogin(savedUser));
        return;
      }
    }
    return next(action);
  };

export default requireLogin;
