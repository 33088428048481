import React, { useCallback, useState, useEffect } from "react";
import { Row, Col, Select, Form, Input } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import forEach from "lodash/forEach";

import "./index.css";
import SettingButton from "../../Builder/SettingButton";
const DatasourceMapping = (props) => {
  const { component, onChange, rowIndex, colIndex, index } = props;

  const [sourceOptions, setSourceOptions] = useState([]);
  const [targetOptions, setTargetOptions] = useState([]);

  useEffect(() => {
    setSourceOptions(component[component.apiProperty]?.columnsMapping || []);

    if (component[component.apiProperty]?.toDataSource) {
      const targetSource = component.toDatasourceValues.find(
        (x) => x.value === component[component.apiProperty]?.toDataSource
      );

      if (targetSource) {
        const columns = targetSource.columns.map((x) => ({
          value: x,
          label: x,
        }));
        setTargetOptions(columns);
      }
    }
  }, [component]);

  const handleOnChangeSource = useCallback((selected) => {
    const selectedSource = component.fromDatasourceValues.find(
      (x) => x.value === selected
    );
    const mapped = selectedSource.columns.map((x) => ({
      source: x,
      target: "",
    }));
    setSourceOptions(mapped);

    handleOnChange(null, {
      type: component.type,
      columns: selectedSource.columns,
      isFromSource: true,
    });
  }, []);

  const handleOnChangeTarget = useCallback((selected) => {
    const selectedTarget = component.toDatasourceValues.find(
      (x) => x.value === selected
    );
    const columns = selectedTarget.columns.map((x) => ({
      value: x,
      label: x,
    }));
    setTargetOptions(columns);

    handleOnChange(null, {
      type: component.type,
      columns: selectedTarget.columns,
      isFromSource: false,
    });
  }, []);

  const handleOnChange = (e, option) => {
    if (typeof onChange === "function") {
      onChange(component.id, option);
    }
  };

  return (
    <>
      <Row>
        <Col xs={24} md={10}>
          <Form.Item
            label={
              component.isHideFromDataSourceTypeLabel
                ? ""
                : component.fromDataSourceTypeLabel
            }
            name={[component.apiProperty, "fromDataSource"]}
            rules={[
              {
                required: true,
                message: component.requiredMessage || "Required field",
              },
            ]}
          >
            <Select
              onChange={handleOnChangeSource}
              placeholder={component.placeholder || "Select..."}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={component.fromDatasourceValues}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={4} className="flex-center">
          <ArrowRightOutlined />
        </Col>
        <Col xs={24} md={10}>
          <Form.Item
            label={
              component.isHideToDataSourceTypeLabel
                ? ""
                : component.toDataSourceTypeLabel
            }
            name={[component.apiProperty, "toDataSource"]}
            rules={[
              {
                required: true,
                message: component.requiredMessage || "Required field",
              },
            ]}
          >
            <Select
              onChange={handleOnChangeTarget}
              placeholder={component.placeholder || "Select..."}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={component.toDatasourceValues}
            ></Select>
          </Form.Item>
        </Col>
      </Row>

      {sourceOptions.length > 0 &&
        sourceOptions.map((item, index) => (
          <Row key={item.source}>
            <Col xs={24} md={10}>
              <Form.Item
                name={[
                  component.apiProperty,
                  "columnsMapping",
                  index,
                  "source",
                ]}
                initialValue={item.source}
                style={{ marginBlock: 0 }}
              >
                <p>{item.source}</p>
              </Form.Item>
            </Col>
            <Col xs={12} md={4} className="flex-center">
              <ArrowRightOutlined />
            </Col>
            <Col xs={24} md={10}>
              <Form.Item
                name={[
                  component.apiProperty,
                  "columnsMapping",
                  index,
                  "target",
                ]}
                initialValue={item.target}
                rules={[
                  { required: true, message: "Target value is required" },
                ]}
              >
                <Select
                  placeholder="Select..."
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={targetOptions}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
        ))}
      <SettingButton
        direction="horizontal"
        position="topRight"
        rowIndex={rowIndex}
        colIndex={colIndex}
        component={component}
        index={index}
      />
    </>
  );
};

export default DatasourceMapping;
