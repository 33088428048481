import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  List,
  Progress,
  Radio,
  Row,
  Space,
  Statistic,
  Table,
  Typography,
} from "antd";
import { WalletOutlined, CreditCardFilled } from "@ant-design/icons";
import Chart from "react-apexcharts";
import Marquee from "react-fast-marquee";

import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { getAccountUsage, getStripeCheckoutSession } from "../../services";
import StripePaymentForm from "./StripePayment";
import InvoicePayment from "./InvoicePayment";
import { useSelector } from "react-redux";
import StripeCheckoutPayment from "./StripeCheckoutPayment";
import { buildDateRange, formatDate, generateUUID } from "../../Utilities";
import { orderBy } from "lodash";

const BillingPage = () => {
  const [usageFilter, setUsageFilter] = useState("1M");
  const [usageDateRange, setUsageDateRange] = useState([]);

  const [balance, setBalance] = useState(0);
  const [lstProductUsageSummary, setLstProductUsageSummary] = useState([]);

  useEffect(async () => {
    loadUsageHistory();
  }, [usageFilter]);
  const loadUsageHistory = useCallback(
    async (ranges) => {
      let fromD = "",
        toD = "";
      if (!ranges) {
        const { from, to } = buildDateRange(usageFilter);
        fromD = from;
        toD = to;
      } else if (ranges?.length === 2) {
        fromD = ranges[0];
        toD = ranges[1];
      }
      const { data } = await getAccountUsage(fromD, toD);
      setBalance(data?.balance || 0);
      const lst = data?.lstProductUsageSummary?.map((item) => ({
        ...item,
        key: `parent-${generateUUID()}`,
        children: item.lstProductDailyUsage?.map((x) => ({
          ...x,
          key: `children-${generateUUID()}`,
        })),
      }));
      setLstProductUsageSummary(orderBy(lst || [], ["runDate"], ["desc"]));
    },
    [usageFilter, usageDateRange]
  );

  const onChangeUageRadio = (e) => {
    setUsageFilter(e.target.value);
  };
  const handleDateRangeChange = (dates, str) => {
    console.log(dates, str);
    loadUsageHistory(str);
  };

  const columns = [
    {
      title: "Usage",
      dataIndex: "usage",
      key: "usage",
      width: 100,
      render: (_, record) => {
        return <Typography.Text>{record.usage}</Typography.Text>;
      },
    },
    {
      title: "Action Name",
      dataIndex: "productName",
      key: "productName",
      width: 120,
      render: (_, record) => {
        return <Typography.Text>{record.productName}</Typography.Text>;
      },
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 120,
      render: (_, record) => {
        return <Typography.Text>{record.productName}</Typography.Text>;
      },
    },
    {
      title: "Product Category",
      dataIndex: "productName",
      key: "productName",
      width: 80,
      align: "center",
      render: (_, record) => {
        return <Typography.Text>{record.productCategory}</Typography.Text>;
      },
    },
    {
      title: "Date",
      dataIndex: "runDate",
      key: "runDate",
      width: 80,
      align: "center",
      render: (_, record) => {
        return (
          <Typography.Text>
            {formatDate(record.runDate || record.usageDate)}
          </Typography.Text>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      width: 50,
      render: (_, record) => {
        if (record.id) {
          return (
            <Typography.Text>
              {record.quantity} {record.unit}
            </Typography.Text>
          );
        }
      },
    },
    {
      title: "Cost",
      dataIndex: "price",
      key: "price",
      width: 50,
      align: "right",
      render: (_, record) => {
        return (
          <Typography.Text>
            {record.cost || record.price} {record.currency}
          </Typography.Text>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ width: 450, marginLeft: 5 }} title="Balance">
        <Typography.Title style={{ marginTop: 12 }} className="text-center">
          ${balance}
        </Typography.Title>
        <StripeCheckoutPayment />
      </Card>
      <Space.Compact
        size="large"
        direction="horizontal"
        className="w-full"
        block
      >
        <Card style={{ width: "100%", marginRight: 5 }}>
          <Space>
            <Radio.Group onChange={onChangeUageRadio} value={usageFilter}>
              <Radio.Button value="1M">1M</Radio.Button>
              <Radio.Button value="3M">3M</Radio.Button>
              <Radio.Button value="6M">6M</Radio.Button>
            </Radio.Group>
            <DatePicker.RangePicker onChange={handleDateRangeChange} />
          </Space>
          <Table
            className="mt-2"
            columns={columns}
            dataSource={lstProductUsageSummary}
            size="middle"
            scroll={{
              x: 1200,
              y: 400,
            }}
            // pagination={{
            //   total,
            //   onChange: onPageChange,
            //   showSizeChanger: false,
            //   current: page,
            // }}
          />
        </Card>
      </Space.Compact>
    </>
  );
};
export default BillingPage;
