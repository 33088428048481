import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  useHistory,
  useRoutes,
} from "react-router-dom";

import { Drawer, Layout, theme, Modal, Dropdown, Button, Avatar } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

import { Loading } from "../../Loading";
import Layout1Menu from "./Menu";
import { menuItems } from "../../../../configs/navigatorConfig";
import CustomBreadcrumb from "./Breadcrumb";
import "./Layout1.css";
import { RouteConfigContext } from "../../../../context";
import { flattenArray } from "../../../../Utilities";
import { useContext } from "react";

const { Header, Content, Sider, Footer } = Layout;

const Layout1 = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const { routerConfigs, customCurrentPageName } =
    useContext(RouteConfigContext);
  const flatRoutes = flattenArray(routerConfigs, "children");
  const routes = useRoutes(flatRoutes);
  const {
    match: { route },
  } = routes.props;

  const { isLoggedIn, user, inApp } = useSelector((states) => states.auth);

  const [collapsed, setCollapsed] = useState(false);
  const [needReload, setNeedReload] = useState(!isLoggedIn);

  useEffect(() => {
    console.warn("needReload", isLoggedIn, needReload, inApp);
    if (isLoggedIn && !needReload) return;
    if (!isLoggedIn && !needReload && !inApp) {
      setNeedReload(true);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!needReload) return;
    console.warn("needReload now");
    // navigate(location.pathname);
    setNeedReload(false);
  }, [needReload]);

  const hasPermission = (menu) => {
    const groupPermission = user?.user?.groups || [];
    const valid = menu.auth.some((x) => {
      console.info("check:", groupPermission, groupPermission.includes(x));
      return groupPermission.includes(x);
    });

    return valid || menu.anonymous;
  };

  if (
    ["home", "default", "extract-brower", "extract-preview"].includes(route.key)
  ) {
    return (
      <Layout style={{ width: "100vw", height: "100vh" }}>
        <Routes>
          {menuItems.map((menu) => {
            if (menu.children) {
              return menu.children.map((subMenu) => {
                if (hasPermission(subMenu)) {
                  return (
                    <Route
                      key={subMenu.key}
                      path={subMenu.path}
                      element={subMenu.element}
                    />
                  );
                }
              });
            }

            if (hasPermission(menu)) {
              return (
                <Route key={menu.key} path={menu.path} element={menu.element} />
              );
            }
          })}
        </Routes>
      </Layout>
    );
  }

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo" />
        <Layout1Menu mode="inline" theme="dark" />
      </Sider>

      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Header className="header" theme="dark">
          <div className="logo">Dalavi</div>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
        </Header>
        <Layout>
          <Layout
            style={{
              padding: "0 50px",
            }}
          >
            <CustomBreadcrumb
              style={{
                margin: "16px 0",
              }}
            />
            <Content
              style={{
                minHeight: 280,
                background: colorBgContainer,
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                padding: "15px",
                height: "85vh",
                overflow: "auto",
              }}
            >
              {(isLoggedIn || inApp) && !needReload && (
                <Routes>
                  {menuItems.map((menu) => {
                    if (menu.children) {
                      return menu.children.map((subMenu) => {
                        return (
                          <Route
                            key={subMenu.key}
                            path={subMenu.path}
                            element={subMenu.element}
                          />
                        );
                      });
                    }
                    return (
                      <Route
                        key={menu.key}
                        path={menu.path}
                        element={menu.element}
                      />
                    );
                  })}
                </Routes>
              )}
            </Content>
            {/* <Footer
              style={{
                textAlign: "center",
              }}
            >
              Dalavi ©2023 Version 1.0.0.1
            </Footer> */}
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default Layout1;
