import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Tooltip } from "antd";
import { ClockCircleOutlined, PlayCircleFilled, SaveFilled } from "@ant-design/icons";
import isEmpty from 'lodash/isEmpty';

import CronText from "../CronText";
import Cron, { HEADER } from "react-cron-generator";
import { crawlerCreateSchedule } from "../../../services";
import { getCrawlerList } from "../../../store/crawlerSlice";

const cronOptions = {
  headers: [HEADER.HOURLY, HEADER.DAILY, HEADER.WEEKLY, HEADER.MONTHLY],
};

const ScheduleSetting = (props) => {
  const { type, data } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [cronValue, setCronValue] = useState("0 0 00 1/1 * ? *");

  const { payload: crawlerPayload } = useSelector(
    (states) => states.crawler?.crawlersObj
  );

  useEffect(() => {
    if(!isEmpty(data?.schedules) && data?.schedules.length > 0) {
      setCronValue(data?.schedules[0].crons);
    }
  }, [data])

  const handleSave = () => {
    setConfirmLoading(true);
    crawlerCreateSchedule({
      id: data?.id,
      schedules: [{ crons: cronValue }]
    }).then((res) => {
      dispatch(getCrawlerList(crawlerPayload));
      setConfirmLoading(false);
      setOpen(false);
    });
  }

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      { !data || isEmpty(data?.schedules) && (
        <Tooltip title="No schedule">
          <Button type="text" icon={<PlayCircleFilled />} onClick={() => setOpen(true)}></Button>
        </Tooltip>
      )}

      { data && !isEmpty(data?.schedules) && (
        <Tooltip title={<CronText cronString={data?.schedules[0].crons} />} color="#2db7f5" >
          <Button type="text" icon={<ClockCircleOutlined />}></Button>
        </Tooltip>
      )} 

      <Modal
      width={700}
      title="Schedule"
      open={open}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      cancelText="Close"
      bodyStyle={{
        display: 'flex',
        justifyContent: 'center'
      }}
      footer={[
        <Button key="save" type="primary" icon={<SaveFilled />} onClick={handleSave}>
          Save
        </Button>,
      ]}
      >
      <Cron
        options={cronOptions}
        value={cronValue}
        onChange={(e) => {
          setCronValue(e);
        }}
        showResultText={true}
        showResultCron={false}
      />
      </Modal>
    </>
  );

  // return <Button type="text" icon={<PlayCircleFilled />}></Button>
};

export default memo(ScheduleSetting);
