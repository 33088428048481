import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Pagination, Table, Typography } from "antd";
import {
  ReloadOutlined,
} from "@ant-design/icons";
import { getCrawlerRunHistory } from "../../../store/crawlerSlice";
import { formatDate } from "../../../Utilities";

const columns = [
  {
    title: "Start Time",
    dataIndex: "startTime",
    key: "startTime",
    render: (_, record) => {
      return <Typography.Text>{formatDate(record.startTime)}</Typography.Text>;
    },
  },
  {
    title: "End Time",
    dataIndex: "endTime",
    key: "endTime",
    render: (_, record) => {
      return <Typography.Text>{formatDate(record.endTime)}</Typography.Text>;
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, record) => {
      if (_ !=='Completed')
        return <Typography.Text type="danger">{_}</Typography.Text>;
      if (_ ==='Completed')
        return <Typography.Text type="success">{_}</Typography.Text>;
    },
  },
  {
    title: "No. Of Page",
    dataIndex: "noPage",
    key: "noPage",
  },
];

const CrawlerRunHistory = ({crawlerId}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dataSource, setDataSource] = useState([]);

  const { list: histories, total } = useSelector(
    (states) => states.crawler?.runHistory
  );

  useEffect(() => {
    handleGetHistories();
  }, [pageSize, currentPage]);

  useEffect(() => {
    setDataSource(histories);
  }, [histories]);

  const handleGetHistories = useCallback(() => {
    setIsLoading(true);
    const payload = {
      extract_request_id: crawlerId,
      page: currentPage - 1,
      page_size: pageSize,
    };
    dispatch(getCrawlerRunHistory(payload)).then(() => {
      setIsLoading(false);
    });
  }, [currentPage, pageSize]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      size="small"
      loading={isLoading}
      title={() => (<Button type="default" icon={<ReloadOutlined />} onClick={handleGetHistories}>Refresh</Button>)}
      footer={() => (
        <Pagination
          total={total || 0} // total number of items
          current={currentPage || 1} // current page number
          pageSize={pageSize || 10} // number of items per page
          showSizeChanger // allow user to change page size
          showQuickJumper
          showTotal={(total) => `Total ${total} items`}
          onChange={handlePageChange} // handle page changes
        />
      )}
    />
  );
};
export default CrawlerRunHistory;
