import React, { useEffect } from "react";
import { Carousel, Space, Tooltip, List, Input, Button } from "antd";
import {
  QuestionOutlined,
  LeftOutlined,
  RightOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { chunk } from "lodash";

import { addEmptyProperty, removeProperty } from "./../slices";

const Properties = () => {
  const dispatch = useDispatch();
  const chunkSize = 6;
  const page = useSelector((states) => states.extraction.currentPage);
  const mode = useSelector((states) => states.extraction.mode);
  const c = React.useRef(null);
  const items =
    page && page.properties ? chunk(page.properties, chunkSize) : [];

  useEffect(() => {
    if (checkEmptyProperty()) {
      const items =
        page && page.properties ? chunk(page.properties, chunkSize) : [];
      c.current.goTo(items.length - 1);
    }
  }, [page.properties]);

  const prev = () => {
    if (c && c.current) {
      c.current.prev();
    }
  };

  const next = () => {
    if (c && c.current) {
      c.current.next();
    }
  };

  const handleAddEmptyProperty = () => {
    if (checkEmptyProperty() || mode !== 2) {
      return;
    }
    dispatch(addEmptyProperty());
  };

  const checkEmptyProperty = () => {
    return (
      page.properties.length &&
      page.properties[page.properties.length - 1].empty
    );
  };

  const handleRemoveProperty = (j) => {
    dispatch(removeProperty(j));
  };

  const renderTooltip = (p, j) => {
    const listProps = {
      bordered: false,
      dataSource: p.values ? p.values.map((item) => item.value) : [],
      renderItem: (item) => <List.Item>{item}</List.Item>,
    };
    listProps.header = (
      <div>
        <Input value={p.name} disabled={true} placeholder="Property's name" />
        <Button
          size={"small"}
          onClick={() => handleRemoveProperty(j)}
          icon={<DeleteOutlined />}
        >
          Remove
        </Button>
      </div>
    );

    return <List {...listProps} />;
  };

  return (
    <>
      <Space className="properties">
        <LeftOutlined className="control" onClick={prev} />
        <Carousel ref={c}>
          {items.map((p, i) => (
            <div key={`i=${i}`}>
              <div className="p-container">
                {p.map((i, j) => (
                  <Tooltip
                    color="#fff"
                    title={renderTooltip(i, j)}
                    key={`p-${j}`}
                    overlayClassName={`tooltip-property${
                      i.empty ? " empty" : ""
                    }`}
                  >
                    <div
                      className="p"
                      style={{
                        borderColor: i.empty ? "#e9a632" : i.color,
                        backgroundColor: i.empty ? "#fff" : i.color,
                      }}
                    >
                      {i.empty ? (
                        <QuestionOutlined
                          style={{ fontSize: 18, color: "#e9a632" }}
                        />
                      ) : (
                        <CheckCircleOutlined
                          style={{ fontSize: 18, color: "#fff" }}
                        />
                      )}
                    </div>
                  </Tooltip>
                ))}
              </div>
            </div>
          ))}
        </Carousel>
        <RightOutlined className="control" onClick={next} />
      </Space>
      <Space className="add-property">
        <Tooltip placement="bottom" title="Add new property">
          <PlusOutlined
            style={{ fontWeight: "bold" }}
            onClick={handleAddEmptyProperty}
            disabled={checkEmptyProperty() || mode !== 2}
          />
        </Tooltip>
      </Space>
    </>
  );
};

export default Properties;
