import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Form, Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { getPreSignedUrl } from "../../../../../services/aws";
import SettingButton from "../../Builder/SettingButton";

const FileUploadElement = (props) => {
  const { component, rowIndex, colIndex, index } = props;

  const { user } = useSelector((states) => states.auth);

  const [signnedUrl, setSignnedUrl] = useState("");

  const handleUpload = {
    name: "file",
    beforeUpload: async (file, fileList) => {
      const { data } = await getPreSignedUrl(file.name);
      setSignnedUrl(data.presignUrl);
    },
    customRequest: async ({ file, onError, onSuccess, onProgress }) => {
      const fileType = file.type;

      var options = {
        onUploadProgress: (event) => {
          const { loaded, total } = event;
          onProgress(
            {
              percent: Math.round((loaded / total) * 100),
            },
            file
          );
        },
        headers: {
          "Content-Type": fileType,
        },
      };

      axios
        .put(signnedUrl, file, options)
        .then((result) => {
          onSuccess(result, file);
        })
        .catch((error) => {
          onError(error);
        });
    },
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    multiple: component.isMultiple,
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <>
      <Form.Item
        key={component.id}
        label={component.isHideLabel ? "" : component.label}
        tooltip={component.tooltip}
        name={component.apiProperty}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        //   extra="longgggggggggggggggggggggggggggggggggg"
        rules={[
          {
            required: component.isRequired,
            message: component.requiredMessage || "Required field",
          },
        ]}
      >
        <Upload {...handleUpload}>
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>
      <SettingButton
        direction="horizontal"
        position="topRight"
        rowIndex={rowIndex}
        colIndex={colIndex}
        component={component}
        index={index}
      />
    </>
  );
};
export default FileUploadElement;
