import { Button } from "antd";
import React, { useEffect, useState } from "react";
const ToggleButton = (props) => {
  const {
    activeText = "Active",
    inactiveText = "Inactive",
    value = false,
    activeValue = true,
    asyncFunc
  } = props;
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setActive(activeValue === value)
  }, [value])

  const handleToggle = async () => {
    try{
      if(asyncFunc instanceof Function){
        setLoading(true);
        await asyncFunc(active);
      } else {
        setActive(!active);
      }
    } catch(err) {
      console.error('toggle button:', err)
    } finally {
      setLoading(false);
    }
  }

  if(active){
    return <Button type="primary" onClick={handleToggle} loading={loading}>{inactiveText}</Button>;
  } else {
    return <Button type="primary" onClick={handleToggle} loading={loading}>{activeText}</Button>;
  }
};
export default ToggleButton;
