import axios from "axios";
import React, { useEffect } from "react";

const InvoicePayment = ({ invoiceId }) => {
  useEffect(() => {
    const handlePayment = async () => {
      try {
        // Make a request to your server to initiate the payment for the given invoice ID
        const response = await fetch("http://localhost:4242/pay-invoice", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ invoiceId: invoiceId }),
        });

        const { paymentUrl } = await response.json();

        // Redirect the user to the payment page
        window.location.href = paymentUrl;
      } catch (error) {
        console.error("Error handling payment:", error.message);
      }
    };

    handlePayment();
  }, [invoiceId]);
  return (
    <div>
      <h1>Redirecting to Payment Page...</h1>
      {/* You can add a loading spinner or other UI elements here */}
    </div>
  );
};
export default InvoicePayment;
