import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import { BsFiletypeJson } from "react-icons/bs";
import { Button, notification } from "antd";
import { FilePdfFilled, FileExcelFilled } from "@ant-design/icons";
import withErrorHandling from "../../../../HOC/withErrorHandler";
import { crawlerDownloadFile } from "../../../../services/index";
import { isEmpty } from "lodash";

const DownloadButton = (props) => {
  const { type, data } = props;

  const [csvLoading, setCsvLoading] = useState(false)
  const [jsonLoading, setJsonLoading] = useState(false)

  const downloadCsvFile = withErrorHandling(async () => {
    if(csvLoading) return;
    setCsvLoading(true);
    const response = await crawlerDownloadFile({
      format: "csv",
      crawler_id: data?.id,
    });
    const blob = new Blob([response.data || ""], { type: "text/csv;charset=utf-8;" });
    const filename = ["csv", new Date().getTime()].join("-") + ".csv";
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    setCsvLoading(false);

    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  });

  const downloadJsonFile = withErrorHandling(async () => {
    if(jsonLoading) return;
    setJsonLoading(true);
    const response = await crawlerDownloadFile({
      format: "json",
      crawler_id: data?.id,
    });
    const jsonString = JSON.stringify(response?.data || "{}")
    const blob = new Blob([jsonString], { type: "application/json;charset=utf-8;" });
    const filename = ["json", new Date().getTime()].join("-") + ".json";
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    setJsonLoading(false);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  });

  return (
    <Button.Group>
      <Button
        type={type}
        icon={<BsFiletypeJson className="anticon" />}
        onClick={downloadJsonFile}
        loading={jsonLoading}
      >
        JSON
      </Button>
      <Button
        type={type}
        icon={<FileExcelFilled />}
        onClick={downloadCsvFile}
        loading={csvLoading}
      >
        CSV
      </Button>
    </Button.Group>
  );
};

// DownloadButton.propTypes = {
//   type: PropTypes.oneOf(["crawler", "dataset"]).isRequired,
//   data: PropTypes.object.isRequired,
// };

export default DownloadButton;
