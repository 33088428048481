import axiosCustom from "./base/axiosCustom";

export const API_URL = {
  ACTION_LIST: "action/list",
  ADMIN_ACTION_LIST: "admin/action/list",
  ADMIN_ACTION_CREATE: "admin/action/create",
  ADMIN_ACTION_DELETE: "admin/action/delete", // admin/action/delete?action_id=101
  ACTION_DETAIL: "admin/action/getAction", // admin/action/getAction?action_id=80
  CRAWLER_LIST: "crawler/list",
  DATASET_LIST: "dataset/list",
  ALL_CRAWLER: "crawler/list",
  ALL_CRAWLER_FOR_PIPELINE: "crawler/get-all-crawlers",
  ALL_DATASET: "dataset/getAllDataset",
  DATASET_DETAIL: "data/listData",
  DATASET_DELETE_DATASET: "dataset/delete",
  DATASET_CREATE_DATASET: "dataset/create",
  PIPELINE_CREATE: "pipeline/create",
  PIPELINE_DETAIL: "pipeline/get",
  PIPELINE_HISTORY: "pipeline/get-run-history",
  PIPELINE_LIST: "pipeline/list",
  PIPELINE_ACTIVE_SCHEDULE: "pipeline/active",
  PIPELINE_INACTIVE_SCHEDULE: "pipeline/inactive",
  PIPELINE_DELETE_SCHEDULE: "pipeline/delete-schedule", //pipeline/delete-schedule?pipeline_id
  PIPELINE_DELETE_PIPELINE: "pipeline/delete",
  PIPELINE_RUN_PIPELINE: "pipeline/run",
  PIPELINE_CLONE_PIPELINE: "pipeline/clone",
  PIPELINE_TERMINATE_PIPELINE: "pipeline/terminate-pipeline-run",
  LOGIN: "aws/login",
  REFRESH_TOKEN: "aws/refresh-token",
  SHARING: "sharing/create",
  RUN_ACTION: "data/runAction",
  DATASET_CATEGORY_LIST: "datastoreCategories",
  DELETE_DATA_ROW: "data/deleteData",
  DELETE_CRAWLER: "crawler/delete", // crawler/delete?extract_request_id=1102
  CRAWLER_PREVIEW_JSON: "webapi/exportjson",
  EXPORT_JSON: "webapi/exportjson",
  EXPORT_CSV: "webapi/exportcsv",
  CLONE_CRAWLER: "crawler/createApi",
  DATASTORE_CATEGORY_LIST: "datastoreCategories",
  DATASET_HISTORY_LOG: "dataset/getActionHistory", //getActionHistory?dataset_id=152
  UPDATE_DATA: "data/updateData",
  SEARCH_DATA: "data/searchData",
  CRAWLER_DOWNLOAD_FILE: "webapi/download", // webapi/download?format=csv&crawler_id=1121
  CRAWLER_CREATE_SCHEDULE: "schedule/create", // {"id":1121,"schedules":[{"crons":"0 0 5 1/1 * ? *"}]}
  CRAWLER_INACTIVE_SCHEDULE: "schedule/inactive", // schedule/inactive?extract_request_id=1121&schedule_id=252
  CRAWLER_ACTIVE_SCHEDULE: "schedule/active", // schedule/active?extract_request_id=1121&schedule_id=252
  CRAWLER_DELETE_SCHEDULE: "schedule/delete", // schedule/delete?extract_request_id=1121&schedule_id=252
  CRAWLER_FIND_BY_ID: "crawler/findById", // crawler/findById/1114
  CRAWLER_RUN_HISTORY: "crawler/run-history", // run-history?extract_request_id=918&page=0&page_size=10
  KEEP_ALIVE: "pipeline/keep-alive",
  EXTRACTION_CONFIG: "conf/getConfig",
  EXTRACTION_CONTENT: "webapi/extraction/html",
  CATEGORY_LIST: "category/list",
  SAVE_PIPELINE_SCHEDULE: "pipeline/createSchedule",
  ACTIVE_PIPELINE_SCHEDULE: "/pipeline/activeSchedule",
  INACTIVE_PIPELINE_SCHEDULE: "/pipeline/inactiveSchedule",
  DELETE_PIPELINE_SCHEDULE: "pipeline/deleteSchedule",
  GET_PIPELINE_SCHEDULE: "pipeline/listSchedule",
  POST_STRIPE_CHECKOUT_SESSION: "account-user/create-checkout-session",
  POST_STRIPE_CHECKOUT_SESSION_STATUS: "account-user/get-session-status",
  GET_USAGE: "account-user/get-usage",
  CHANGE_PASSWORD: "account-user/change-password",
  GET_ACCOUNT_TRANSACTIONS: "account-user/get-transactions",
  GET_THRESHOLD_USAGE_LIST: "threshold/listThresholdUsage", //threshold/listThresholdUsage?page=0&page_size=10
  GET_THRESHOLD_LIST: "threshold/listThreshold", //threshold/listThresholdUsage?page=0&page_size=10,
  DELETE_THRESHOLD_USAGE: "threshold/deleteThresholdUsage", //deleteThresholdUsage?thresHoldUsageId=3
  SAVE_THRESHOLD_USAGE: "threshold/createThresholdUsage", //createThresholdUsage
  GET_LIST_PLAN: "register/account/listPlan", //register/account/listPlan
  UPGRADE_PLAN: "register/account/updatePlan", //register/account/updatePlan?planId=
  CREATE_CHILD_USER: "subscription/account/createUser", //subscription/account/createUser?userName=thienhanh02&email=thienhanh123@yopmail.com
  GET_ACCOUNT: "register/account/getAccount", //register/account/getAccount
  GET_ACCOUNT_USERS: "subscription/account/getAccountUsers", //subscription/account/getAccountUsers?accountId=1111111111&page=0&page_size=10
  ENABLE_USER: "subscription/account/enableUser", //subscription/account/enableUser?userId=d41a7d84-9149-4085-b115-8c9c72af71fd
  DISABLE_USER: "subscription/account/disableUser", //subscription/account/disableUser?userId=d41a7d84-9149-4085-b115-8c9c72af71fd
  DELETE_USER: "subscription/account/deleteUser", ///subscription/account/deleteUser?userId=d41a7d84-9149-4085-b115-8c9c72af71fd
  CHANGE_PRIVILEGE_USER: "subscription/account/updatePrivilege", //subscription/account/updateprivilege?userid=d41a7d84-9149-4085-b115-8c9c72af71fd&privilege=3
};

const getarentDomain = () => {
  var parts = window.location.hostname.split(".");
  return `${window.location.protocol}//${parts.slice(-2).join(".")}`;
};

export const getActions = () => {
  return axiosCustom.get(API_URL.ACTION_LIST);
};

export const getAdminActions = (params) => {
  return axiosCustom.get(API_URL.ADMIN_ACTION_LIST, params);
};

export const getCrawlers = (params) => {
  return axiosCustom.get(API_URL.CRAWLER_LIST, params);
};

export const getDatasets = (params) => {
  return axiosCustom.get(API_URL.DATASET_LIST, params);
};

export const getAllCrawlers = (params) => {
  // if (!params) {
  //   params = {};
  // }
  // if (!params.hasOwnProperty("page")) {
  //   params.page = 0;
  // }
  // if (!params.hasOwnProperty("page_size")) {
  //   params.page_size = 1000;
  // }
  return axiosCustom.get(API_URL.ALL_CRAWLER_FOR_PIPELINE, params);
};

export const getAllDataset = () => {
  return axiosCustom.get(API_URL.ALL_DATASET);
};
export const getDatasetDetail = (params) => {
  return axiosCustom.get(API_URL.DATASET_DETAIL, params);
};

export const createPipeline = (data) => {
  return axiosCustom.post(API_URL.PIPELINE_CREATE, data);
};

export const getPipelineDetail = (params) => {
  return axiosCustom.get(API_URL.PIPELINE_DETAIL, params);
};

export const getPipelineHistory = (params) => {
  return axiosCustom.get(API_URL.PIPELINE_HISTORY, params);
};
export const getPipelineList = (params) => {
  return axiosCustom.get(API_URL.PIPELINE_LIST, params);
};
export const activeSchedule = (id) => {
  let params = {
    pipeline_id: id,
  };
  return axiosCustom.get(API_URL.PIPELINE_ACTIVE_SCHEDULE, params);
};

export const inactiveSchedule = (id) => {
  let params = {
    pipeline_id: id,
  };
  return axiosCustom.get(API_URL.PIPELINE_INACTIVE_SCHEDULE, params);
};
export const deleteSchedule = (id) => {
  let params = {
    pipeline_id: id,
  };
  return axiosCustom.get(API_URL.PIPELINE_DELETE_SCHEDULE, params);
};
export const deletePipeline = (id) => {
  let params = {
    pipeline_id: id,
  };
  return axiosCustom.get(API_URL.PIPELINE_DELETE_PIPELINE, params);
};

export const runPipeline = (id) => {
  let params = {
    pipeline_id: id,
  };
  return axiosCustom.get(API_URL.PIPELINE_RUN_PIPELINE, params);
};

export const clonePipeline = ({ id, name, description }) => {
  let params = {
    id,
    name,
    description,
  };
  return axiosCustom.post(API_URL.PIPELINE_CLONE_PIPELINE, params);
};

export const terminatePipelineRun = (id) => {
  let params = {
    pipeline_run_id: id,
  };
  return axiosCustom.get(API_URL.PIPELINE_TERMINATE_PIPELINE, params);
};

export const createShare = (params) => {
  return axiosCustom.post(API_URL.SHARING, params);
};

export const deleteDataset = (id) => {
  let params = {
    dataset_id: id,
  };
  return axiosCustom.get(API_URL.DATASET_DELETE_DATASET, params);
};

export const createDataset = (params) => {
  return axiosCustom.post(API_URL.DATASET_CREATE_DATASET, params);
};

export const runAction = (params) => {
  return axiosCustom.post(API_URL.RUN_ACTION, params);
};

export const getCategories = (params) => {
  return axiosCustom.get(API_URL.DATASET_CATEGORY_LIST, params);
};
export const getDatastoreCategories = (params) => {
  return axiosCustom.get(API_URL.DATASTORE_CATEGORY_LIST, params);
};
export const postToAction = (url, params) => {
  return axiosCustom.post(url, params);
};
export const deleteDatas = (params) => {
  return axiosCustom.post(API_URL.DELETE_DATA_ROW, params);
};
export const deleteCrawler = (params) => {
  return axiosCustom.get(API_URL.DELETE_CRAWLER, params);
};
export const previewCrawlerJson = (params) => {
  return axiosCustom.post(API_URL.CRAWLER_PREVIEW_JSON, params);
};
export const exportJson = (params) => {
  return axiosCustom.post(API_URL.EXPORT_JSON, params);
};
export const exportCsv = (params) => {
  return axiosCustom.post(API_URL.EXPORT_CSV, params);
};
export const cloneCrawler = (params) => {
  return axiosCustom.post(API_URL.CLONE_CRAWLER, params);
};
export const getDatasetHistory = (params) => {
  return axiosCustom.get(API_URL.DATASET_HISTORY_LOG, params);
};
export const updateData = (params) => {
  return axiosCustom.post(API_URL.UPDATE_DATA, params);
};
export const searchData = (params) => {
  return axiosCustom.get(API_URL.SEARCH_DATA, params);
};
export const crawlerDownloadFile = (params) => {
  return axiosCustom.get(API_URL.CRAWLER_DOWNLOAD_FILE, params);
};
export const crawlerCreateSchedule = (params) => {
  return axiosCustom.post(API_URL.CRAWLER_CREATE_SCHEDULE, params);
};
export const getCrawlerById = (id) => {
  return axiosCustom.get(`${API_URL.CRAWLER_FIND_BY_ID}/${id}`);
};
export const crawlerInactiveSchedule = (params) => {
  return axiosCustom.get(API_URL.CRAWLER_INACTIVE_SCHEDULE, params);
};
export const crawlerActiveSchedule = (params) => {
  return axiosCustom.get(API_URL.CRAWLER_ACTIVE_SCHEDULE, params);
};
export const crawlerDeleteSchedule = (params) => {
  return axiosCustom.get(API_URL.CRAWLER_DELETE_SCHEDULE, params);
};
export const crawlerRunHistory = (params) => {
  return axiosCustom.get(API_URL.CRAWLER_RUN_HISTORY, params);
};
export const deleteAdminAction = (params) => {
  return axiosCustom.get(API_URL.ADMIN_ACTION_DELETE, params);
};
export const createAdminAction = (params) => {
  return axiosCustom.post(API_URL.ADMIN_ACTION_CREATE, params);
};
export const keepAlive = () => {
  return axiosCustom.get(API_URL.KEEP_ALIVE);
};
export const getActionById = (params) => {
  return axiosCustom.get(API_URL.ACTION_DETAIL, params);
};
export const getExtractionConfig = (params) => {
  return axiosCustom.get(API_URL.EXTRACTION_CONFIG, params);
};
export const getExtractionContent = (params) => {
  return axiosCustom.get(API_URL.EXTRACTION_CONTENT, params);
};
export const getCategoryList = (params) => {
  return axiosCustom.get(API_URL.CATEGORY_LIST, params);
};
export const savePipelineSchedule = (params) => {
  return axiosCustom.post(API_URL.SAVE_PIPELINE_SCHEDULE, params);
};
export const activePipelineSchedule = (params) => {
  return axiosCustom.get(API_URL.ACTIVE_PIPELINE_SCHEDULE, params);
};
export const inactivePipelineSchedule = (params) => {
  return axiosCustom.get(API_URL.INACTIVE_PIPELINE_SCHEDULE, params);
};
export const deletePipelineSchedule = (params) => {
  return axiosCustom.get(API_URL.DELETE_PIPELINE_SCHEDULE, params);
};
export const getPipelineSchedules = (params) => {
  return axiosCustom.get(API_URL.GET_PIPELINE_SCHEDULE, params);
};
export const getStripeCheckoutSession = (params) => {
  return axiosCustom.post(
    API_URL.POST_STRIPE_CHECKOUT_SESSION,
    JSON.stringify(params),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const getStripeCheckoutSessionStatus = (session_id) => {
  return axiosCustom.post(
    API_URL.POST_STRIPE_CHECKOUT_SESSION_STATUS,
    JSON.stringify({
      sessionId: session_id,
    }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const getAccountUsage = (from, to) => {
  //?from-date=2024-01-01&to-date=2024-01-29
  return axiosCustom.get(
    `${API_URL.GET_USAGE}?from-date=${from}&to-date=`
  );
};
export const postChangePassword = (payload) => {
  console.log('postChangePassword', axiosCustom, payload);
  return axiosCustom.post(
    API_URL.CHANGE_PASSWORD,
    JSON.stringify(payload),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const getAccountTransactions = (params) => {
  //?from-date=2024-01-01&to-date=2024-02-29&page=0&page_size=10
  return axiosCustom.get(API_URL.GET_ACCOUNT_TRANSACTIONS, params);
}

export const getListThresholdUsage = (params) => {
  //?from-date=2024-01-01&to-date=2024-02-29&page=0&page_size=10
  return axiosCustom.get(API_URL.GET_THRESHOLD_USAGE_LIST, params);
}

export const getListThreshold = (params) => {
  //?from-date=2024-01-01&to-date=2024-02-29&page=0&page_size=10
  return axiosCustom.get(API_URL.GET_THRESHOLD_LIST, params);
}
export const deleteThresholdUsaged = (params) => {
  return axiosCustom.get(API_URL.DELETE_THRESHOLD_USAGE, params);
}
export const saveThresholdUsaged = (params) => {
  return axiosCustom.post(API_URL.SAVE_THRESHOLD_USAGE, params);
}
export const getListPlan = (params) => {
  return axiosCustom.get(API_URL.GET_LIST_PLAN, params);
}
export const upgradePlan = (params) => {
  return axiosCustom.get(API_URL.UPGRADE_PLAN, params);
}
export const createUser = (params) => {
  return axiosCustom.get(API_URL.CREATE_CHILD_USER, params);
}
export const getAccount = (params) => {
  return axiosCustom.get(API_URL.GET_ACCOUNT, params);
}
export const getAccountUserList = (params) => {
  return axiosCustom.get(API_URL.GET_ACCOUNT_USERS, params);
}
export const enableUser = (params) => {
  return axiosCustom.get(API_URL.ENABLE_USER, params);
}
export const disableUser = (params) => {
  return axiosCustom.get(API_URL.DISABLE_USER, params);
}
export const deleteUser = (params) => {
  return axiosCustom.get(API_URL.DELETE_USER, params);
}
export const changePrivilege = (params) => {
  return axiosCustom.get(API_URL.CHANGE_PRIVILEGE_USER, params);
}